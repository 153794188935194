import React from 'react'
import { Portal } from 'react-portal'

import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

const A7AModalWrapperWrapper = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
  background-color: #000000b0;
  backdrop-filter: blur(10px);
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: ${(props) => (props.isOpen ? 'flex' : 'none')};
`

const A7AModalWrapperAnimater = styled.div`
  transform: translateY(-100%);
  opacity: 0;
  transition: all 0.3s ease-in-out;
  max-width: 99%;
  height: 100%;
  width: 100%;
  @media only screen and (max-width: 576px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & > div {
    position: absolute;
    width: 100%;
    height: 100%;
    @media only screen and (max-width: 576px) {
      position: relative;
      top: inherit;
      left: inherit;
      display: flex;
      justify-content: center;
    }
  }
`

interface IA7AModalWrapper {
  Modal: JSX.Element
  isOpen: boolean
  onClose: () => void
}

const A7AModalWrapper = React.forwardRef(
  ({ Modal, isOpen, onClose }: IA7AModalWrapper, ref: any): JSX.Element => {
    const [hasWindow, setHasWindow] = useState(false)
    const wrapperRef = useRef<any>()
    const animaterRef = useRef<any>()
    const [isOpenState, setIsOpenState] = useState(isOpen)

    useEffect(() => {
      setHasWindow(true)
    }, [])

    const sleep = (ms: number) =>
      new Promise((resolve) => setTimeout(resolve, ms))

    useEffect(() => {
      ;(async () => {
        if (hasWindow) {
          if (isOpen) {
            setIsOpenState(true)
            await sleep(100)
            animaterRef.current.style.opacity = 1
            animaterRef.current.style.transform = 'translateY(0)'
            document.body.style.overflow = 'hidden'
          } else {
            animaterRef.current.style.transform = 'translateY(-100%)'
            animaterRef.current.style.opacity = 0
            await sleep(200)
            setIsOpenState(false)
            document.body.style.overflow = 'auto'
          }
        } else {
        }
      })()
      // }
    }, [isOpen, hasWindow])

    return !hasWindow ? (
      <></>
    ) : (
      <Portal node={document && document.getElementById('myportal')}>
        <A7AModalWrapperWrapper
          ref={wrapperRef}
          onClick={onClose}
          isOpen={isOpenState}
        >
          <A7AModalWrapperAnimater
            onClick={() => {
              onClose()
            }}
            ref={animaterRef}
          >
            <div
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              {isOpenState && Modal}
            </div>
          </A7AModalWrapperAnimater>
        </A7AModalWrapperWrapper>
      </Portal>
    )
  },
)

A7AModalWrapper.displayName = 'A7AModalWrapper'

export default A7AModalWrapper
