import { SVGProps } from 'react'
import useSelectorTyped from '../../../../../store/useSelectorTyped'

const HTML = (props: SVGProps<SVGSVGElement>) => {
  const theme = useSelectorTyped((state) => state?.fromClient?.theme)

  return (
    <>
      <svg
        width={18}
        height={22}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M15.966 1.756H2.162a1 1 0 0 0-.996 1.093L2.51 17.182a1 1 0 0 0 .577.815l5.558 2.565a1 1 0 0 0 .838 0l5.558-2.565a1 1 0 0 0 .577-.815L16.962 2.85a1 1 0 0 0-.996-1.093Z"
          stroke={theme === 1 ? "#A9B6BD" : "#FFFFFF"}
          strokeWidth={2}
        />
        <path
          d="M13.065 5.256h-8l.5 4.5h7l-.5 5.5-3 1.5-3-1.5-.25-2.5"
          stroke={theme === 1 ? "#A9B6BD" : "#FFFFFF"}
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  )
}

export default HTML;
