import { SVGProps } from "react"

const HeartBreak = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={19}
    height={19}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m2.75 2.935 13.5 13.5m-1.125-6.32L14 11.184m-1.5 1.5-3 3-5.625-5.57m0 0a3.75 3.75 0 0 1-.966-3.802 3.732 3.732 0 0 1 1.34-1.878m2.25-.75a3.739 3.739 0 0 1 3 1.505 3.75 3.75 0 1 1 5.626 4.929"
      stroke="#A9B6BD"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default HeartBreak