import React from "react";
import styled from "styled-components";
import { A7ACenteredRow, SquircleOutlined3 } from "../../../utils/Container";

const TagWrapper = styled(A7ACenteredRow)`
  padding: 5px 10px 5px 8px;
  gap: 5px;
  min-width: 68px;
  height: 30px;
  ${SquircleOutlined3};

  ${(props) => props.theme.mediaQuery.tablet} {
    padding: 5px 15px;
    height: 44px;
    gap: 8px;
  }
  div {
    display: flex;
    align-items: center;
    width: 20px;
  }
  span {
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: ${(props) => props.theme.colors.secondary2};
  }
`;

interface ITechStackTag {
  icon: JSX.Element;
  text: string;
}
const ProjectTechStackTag = ({ icon, text }: ITechStackTag) => {
  return (
    <>
      <TagWrapper>
        <div>{icon}</div>
        <span>{text}</span>
      </TagWrapper>
    </>
  );
};

export default ProjectTechStackTag;
