import { motion } from 'framer-motion'
import { CSSProperties, RefObject } from 'react'
import styled from 'styled-components'
import { variantContainer, variantitem } from '../../../utils/Animations'
import {
  A7ACenteredColumn,
  A7ACenteredRow,
  A7AXSpaceBetweenRow,
  A7AYCenteredColumn
} from '../../../utils/Container'
import { useMediaQuery } from '../../../utils/useMediaQuery'
import { SocialButton } from '../button'
import LeftArrow from '../svg/common/LeftArrow'
import RightArrow from '../svg/common/RightArrow'
import SizedBox from './SizedBox'
import TiltedBar from './TiltedBar'

const Wrapper = styled(A7ACenteredColumn).attrs({
  as: 'section',
})`
  padding: 30px 0px;
  justify-content: flex-start;
  background-color: ${(props) => props.theme.colors.background};
  ${(props) => props.theme.mediaQuery.tablet} {
    padding: 60px 0px;
  }
`

const SectionHeaderWrapper = styled(A7AYCenteredColumn)`
  margin: ${(props) => props.theme.constrainedMargin};
  max-width: ${(props) => props.theme.desktopWidth};
  ${(props) => props.theme.mediaQuery.desktop} {
    width: 100%;
  }
`

const SectionHeaderContainer = styled(A7AXSpaceBetweenRow)`
  width: 100%;
`

export const SectionHeading = styled.h1`
  margin: 0px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.textColors[0]};
  font-size: 24px;
  line-height: 32px;
  ${(props) => props.theme.mediaQuery.tablet} {
    font-size: 36px;
    line-height: 49px;
  }
`

export const SectionDescription = styled.p`
  font-family: 'Lato';
  font-style: normal;
  margin: 0px;
  width: 100%;
  margin-top: 34px;
  font-weight: 300;
  font-size: 14px;

  line-height: 17px;
  ${(props) => props.theme.mediaQuery.tablet} {
    margin-top: 30.5px;
    font-size: 16px;
    line-height: 19px;
  }
  color: ${(props) => props.theme.colors.textColors[1]};
`

interface ISectionWrapperProps {
  children: JSX.Element | JSX.Element[]
  heading: string
  description?: string
  carouselButtons?: boolean
  ref?: RefObject<HTMLDivElement>
  id?: string
  styles?: CSSProperties
  leftButtonOnClick?: () => void
  rightButtonOnClick?: () => void
}
const SectionWrapper = (props: ISectionWrapperProps) => {
  const isMobile = useMediaQuery('(max-width: 500px)')
  return (
    <Wrapper id={props.id} style={{ ...props.styles }}>
      <SectionHeaderWrapper
        as={motion.div}
        variants={variantContainer}
        initial={'hidden'}
        whileInView={'show'}
        viewport={{ once: true }}
      >
        <SectionHeaderContainer>
          <A7ACenteredRow>
            <motion.div variants={variantitem} style={{ marginRight: 20 }}>
              <TiltedBar />
            </motion.div>
            <SectionHeading as={motion.h1} variants={variantitem}>
              {props.heading}
            </SectionHeading>
          </A7ACenteredRow>
          {props.carouselButtons && !isMobile && (
            <>
              <A7ACenteredRow>
                <SocialButton
                  ariaLabel='Left Arrow'
                  onClick={
                    props.leftButtonOnClick ? props.leftButtonOnClick : () => {}
                  }
                >
                  <LeftArrow />
                </SocialButton>
                <SizedBox height={0} width={9} />
                <SocialButton
                  ariaLabel='Right Arrow'
                  onClick={
                    props.rightButtonOnClick
                      ? props.rightButtonOnClick
                      : () => {}
                  }
                >
                  <RightArrow />
                </SocialButton>
              </A7ACenteredRow>
            </>
          )}
        </SectionHeaderContainer>
        {props.description !== undefined && (
          <SectionDescription as={motion.p} variants={variantitem}>
            {props.description}
          </SectionDescription>
        )}
      </SectionHeaderWrapper>
      {props.children}
    </Wrapper>
  )
}
SectionWrapper.displayName = 'SectionWrapper'
export default SectionWrapper
