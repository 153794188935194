import { ISocialIconProps } from "./Github";

const Instagram = ({ props, fill }: ISocialIconProps) => (
  <svg
    width={15}
    height={14}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.305.492c1.143 0 2.15.38 2.858 1.116.708.708 1.09 1.688 1.09 2.832v5.608c0 1.171-.382 2.178-1.117 2.886-.708.681-1.688 1.062-2.859 1.062H4.723c-1.116 0-2.096-.354-2.831-1.062C1.129 12.2.748 11.192.748 10.021V4.44c0-2.37 1.58-3.948 3.948-3.948h5.609Zm1.96 11.544c.49-.463.79-1.144.79-1.988V4.44c0-.79-.273-1.47-.736-1.96-.49-.49-1.17-.736-1.987-.736H4.723c-.817 0-1.525.245-1.987.708C2.246 2.942 2 3.622 2 4.44v5.581c0 .844.246 1.525.736 2.015.49.463 1.17.708 1.987.708h5.554c.817 0 1.498-.245 1.988-.708ZM11.122 4.44a.79.79 0 1 0 0-1.58.79.79 0 0 0 0 1.58ZM7.501 3.73a3.492 3.492 0 0 0-3.485 3.485A3.474 3.474 0 0 0 7.5 10.701a3.492 3.492 0 0 0 3.485-3.485c0-1.905-1.58-3.485-3.485-3.485Zm0 5.718a2.243 2.243 0 0 1-2.233-2.233 2.243 2.243 0 0 1 2.233-2.232 2.243 2.243 0 0 1 2.232 2.232A2.243 2.243 0 0 1 7.501 9.45Z"
      fill={fill ? fill : "#fff"}
    />
  </svg>
);

export default Instagram;
