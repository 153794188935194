import { SVGProps } from "react"

const Moon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={14}
    height={14}
    fill="none"
    viewBox="0 0 16 16 "
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.192 2.767h.236a4.514 4.514 0 0 0 4.767 7.492 5.417 5.417 0 1 1-5.003-7.497v.005Z"
      stroke="#fff"
      strokeWidth={1.204}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Moon
