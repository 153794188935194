import { SVGProps } from "react";

const Signal = (props: SVGProps<SVGSVGElement>) => {
  return (
    <>
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M18.5005 4.76514C19.3305 4.76514 20.0005 5.43514 20.0005 6.26514V19.2651C20.0005 20.0951 19.3305 20.7651 18.5005 20.7651C17.6705 20.7651 17.0005 20.0951 17.0005 19.2651V6.26514C17.0005 5.43514 17.6705 4.76514 18.5005 4.76514ZM6.50049 14.7651C7.33049 14.7651 8.00049 15.4351 8.00049 16.2651V19.2651C8.00049 20.0951 7.33049 20.7651 6.50049 20.7651C5.67049 20.7651 5.00049 20.0951 5.00049 19.2651V16.2651C5.00049 15.4351 5.67049 14.7651 6.50049 14.7651ZM12.5005 9.76514C13.3305 9.76514 14.0005 10.4351 14.0005 11.2651V19.2651C14.0005 20.0951 13.3305 20.7651 12.5005 20.7651C11.6705 20.7651 11.0005 20.0951 11.0005 19.2651V11.2651C11.0005 10.4351 11.6705 9.76514 12.5005 9.76514Z"
          fill="#A9B6BD"
        />
      </svg>
    </>
  );
};

export default Signal;
