import { SVGProps } from "react"

const CrossIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={26}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    style={{cursor:"pointer"}}
  >
    <path
      d="m13.41 13 4.3-4.323a1.016 1.016 0 0 0 0-1.43 1 1 0 0 0-1.42 0L12 11.579 7.71 7.247a1 1 0 0 0-1.42 0 1.016 1.016 0 0 0 0 1.43L10.59 13l-4.3 4.322a1.007 1.007 0 0 0-.219 1.103c.05.123.125.234.219.328a1.001 1.001 0 0 0 .71.298.994.994 0 0 0 .71-.298L12 14.42l4.29 4.333a1.001 1.001 0 0 0 .71.298.994.994 0 0 0 .71-.298 1.006 1.006 0 0 0 .296-.715 1.013 1.013 0 0 0-.296-.716L13.41 13Z"
      fill="#EB5647"
    />
  </svg>
)

export default CrossIcon
