import { SVGProps } from "react";
import useSelectorTyped from "../../../../../../store/useSelectorTyped";

const ARVRSolutions = (props: SVGProps<SVGSVGElement>) => {
  const theme = useSelectorTyped((state) => state?.fromClient?.theme);

  return (
    <>
      <svg
        width="111"
        height="110"
        viewBox="0 0 111 110"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M80.9469 46.2175L81.0675 46.4186C81.2038 46.6438 81.4013 46.8257 81.6371 46.9429L81.8482 47.0468L81.6472 47.1674C81.4219 47.3029 81.24 47.4999 81.1228 47.7353L81.019 47.9463L80.8984 47.7453C80.7626 47.5196 80.5649 47.3376 80.3288 47.221L80.1194 47.1171L80.3187 46.9965C80.5448 46.8612 80.7269 46.6634 80.8431 46.4269L80.9469 46.2175Z"
          fill={theme === 0 ? "#1F2530" : "#F3F8FB"}
        />
        <path
          d="M72.2213 21.4243L72.2899 21.5366C72.366 21.6646 72.4778 21.7677 72.6116 21.8331L72.7305 21.8917L72.6166 21.9604C72.4889 22.0368 72.3859 22.1485 72.3201 22.282L72.2615 22.401L72.1928 22.287C72.1159 22.1597 72.0043 22.0569 71.8711 21.9905L71.7522 21.9319L71.8661 21.8632C71.9938 21.7874 72.0967 21.6763 72.1626 21.5433L72.2213 21.4243Z"
          fill={theme === 0 ? "#1F2530" : "#F3F8FB"}
        />
        <path
          d="M77.6393 28.2761L77.8353 28.6028C78.0562 28.9688 78.377 29.264 78.76 29.4538L79.0951 29.6213L78.76 29.819C78.3934 30.0383 78.0979 30.3588 77.909 30.742L77.7415 31.0771L77.5455 30.742C77.3252 30.3762 77.005 30.0809 76.6225 29.891L76.2874 29.7235L76.6141 29.5275C76.9795 29.3072 77.2742 28.9869 77.4634 28.6045L77.6393 28.2761Z"
          fill={theme === 0 ? "#1F2530" : "#F3F8FB"}
        />
        <path
          d="M22.0932 59.2942L22.2138 59.4952C22.3491 59.7213 22.5469 59.9034 22.7834 60.0196L22.9928 60.1234L22.7918 60.244C22.5665 60.3803 22.3846 60.5778 22.2674 60.8136L22.1636 61.0247L22.0429 60.8236C21.9079 60.598 21.7107 60.416 21.4751 60.2993L21.264 60.1954L21.465 60.0748C21.6907 59.9391 21.8727 59.7414 21.9893 59.5053L22.0932 59.2942Z"
          fill={theme === 0 ? "#1F2530" : "#F3F8FB"}
        />
        <path
          d="M45.222 17.4375L45.3427 17.6385C45.4784 17.8642 45.6761 18.0462 45.9122 18.1629L46.1216 18.2667L45.9206 18.3873C45.6954 18.5232 45.514 18.7209 45.3979 18.9569L45.2941 19.1663L45.1718 18.9653C45.0366 18.7401 44.8395 18.5587 44.6039 18.4426L44.3928 18.3388L44.5938 18.2165C44.8194 18.0813 45.0014 17.8842 45.1182 17.6486L45.222 17.4375Z"
          fill={theme === 0 ? "#1F2530" : "#F3F8FB"}
        />
        <path
          d="M84.7874 29.3247L84.903 29.3699C85.0329 29.4232 85.1756 29.4372 85.3134 29.4101L85.4357 29.3867L85.3888 29.504C85.3371 29.6337 85.3232 29.7754 85.3486 29.9127L85.372 30.035L85.2565 29.9881C85.1268 29.9361 84.9848 29.9227 84.8477 29.9496L84.717 29.968L84.7639 29.8524C84.8164 29.7229 84.8304 29.5809 84.8041 29.4436L84.7874 29.3247Z"
          fill={theme === 0 ? "#1F2530" : "#F3F8FB"}
        />
        <path
          d="M39.2494 18.0942L39.365 18.1411C39.4946 18.1936 39.6365 18.2076 39.7738 18.1813L39.8978 18.1579L39.8508 18.2735C39.7984 18.4036 39.7844 18.5461 39.8106 18.6839L39.8341 18.8062L39.7185 18.7593C39.5882 18.7077 39.446 18.6937 39.3081 18.7191L39.1858 18.7425L39.2327 18.627C39.2844 18.4966 39.2984 18.3544 39.2729 18.2165L39.2494 18.0942Z"
          fill={theme === 0 ? "#1F2530" : "#F3F8FB"}
        />
        <path
          d="M35.1303 72.8333L35.2459 72.8802C35.3762 72.9318 35.5185 72.9457 35.6563 72.9204L35.7786 72.8969L35.7317 73.0125C35.68 73.1428 35.666 73.2851 35.6915 73.4229L35.715 73.5452L35.5994 73.4983C35.4692 73.4459 35.3268 73.4319 35.1889 73.4581L35.0667 73.4816L35.1136 73.366C35.1652 73.2356 35.1791 73.0934 35.1538 72.9555L35.1303 72.8333Z"
          fill={theme === 0 ? "#1F2530" : "#F3F8FB"}
        />
        <path
          d="M21.4997 26.9526L21.6153 26.9995C21.7454 27.052 21.8879 27.066 22.0257 27.0397L22.148 27.0163L22.1011 27.1319C22.0497 27.2641 22.0369 27.4082 22.0643 27.5473L22.0877 27.6696L21.9688 27.6227C21.8396 27.5714 21.6985 27.5574 21.5617 27.5825L21.4377 27.606L21.4847 27.4904C21.5372 27.3608 21.5511 27.2189 21.5249 27.0816L21.4997 26.9526Z"
          fill={theme === 0 ? "#1F2530" : "#F3F8FB"}
        />
        <path
          d="M85.1121 37.803L85.1691 37.8264C85.2338 37.853 85.3049 37.86 85.3735 37.8465L85.4355 37.8348L85.412 37.8934C85.3862 37.9583 85.3792 38.0292 85.3919 38.0978L85.4036 38.1598L85.3467 38.1363C85.2814 38.1097 85.2098 38.1027 85.1406 38.1162L85.0803 38.128L85.1038 38.0693C85.1288 38.0043 85.1357 37.9337 85.1239 37.865L85.1121 37.803Z"
          fill={theme === 0 ? "#1F2530" : "#F3F8FB"}
        />
        <path
          d="M43.4003 21.1279L43.4573 21.1514C43.522 21.178 43.593 21.185 43.6617 21.1715L43.7236 21.1598L43.7002 21.2167C43.6715 21.282 43.6628 21.3542 43.6751 21.4244L43.6868 21.4847L43.6298 21.4613C43.5642 21.4363 43.493 21.4294 43.4238 21.4412L43.3635 21.4529L43.3869 21.396C43.4119 21.3309 43.4188 21.2603 43.407 21.1916L43.4003 21.1279Z"
          fill={theme === 0 ? "#1F2530" : "#F3F8FB"}
        />
        <path
          d="M42.2162 92.1916C45.4628 90.0239 46.2551 85.9197 46.1864 82.2342C46.1747 81.6161 46.2535 80.9678 45.9653 80.4217C45.6772 79.8756 44.9887 79.497 44.4241 79.7516C43.9584 79.9593 43.7541 80.4904 43.481 80.9242C43.1231 81.5086 42.6074 81.9801 41.9934 82.2845C41.593 82.4822 41.0989 82.5994 40.7085 82.38C40.1741 82.0785 40.1222 81.343 40.1356 80.7282L40.211 77.3008C40.2244 76.6876 40.2361 76.0611 40.0519 75.4765C39.8676 74.8918 39.4488 74.344 38.8608 74.1715C38.2728 73.999 37.5357 74.3256 37.4017 74.9237C37.3748 75.1768 37.3647 75.4314 37.3715 75.6859C37.3514 75.9405 37.2258 76.2203 36.9795 76.289C36.7333 76.3576 36.4853 76.1784 36.3094 75.9958C35.6779 75.3676 35.2541 74.5635 34.8604 73.7644C34.4667 72.9654 34.0831 72.1462 33.5202 71.4627C32.9574 70.7792 32.1516 70.2314 31.2637 70.1678C30.3759 70.1041 29.421 70.6335 29.1798 71.4928C28.9386 72.3522 29.4378 73.2384 29.9822 73.947C30.7933 74.9994 31.7525 75.9287 32.8301 76.7061C33.0244 76.8468 33.2338 76.9976 33.3058 77.2271C33.4331 77.6375 33.0344 78.0379 32.624 78.1652C32.1583 78.3109 31.6557 78.2556 31.1716 78.3059C30.6875 78.3562 30.1581 78.5572 29.9671 79.0061C29.7025 79.6293 30.2234 80.2944 30.7394 80.7333C31.7763 81.6114 32.9552 82.3065 34.2255 82.7887C34.6795 82.9563 35.1586 83.1121 35.5221 83.4354C35.8856 83.7587 36.1 84.3115 35.8705 84.7403C35.641 85.1692 35.0664 85.2999 34.589 85.2044C34.1116 85.1089 33.6878 84.8476 33.2338 84.67C32.3845 84.335 31.2822 84.3802 30.721 85.1005C30.3541 85.5746 30.3122 86.2413 30.4848 86.8142C30.6856 87.3806 30.9983 87.9008 31.4045 88.3437C32.7345 89.9672 34.4692 91.2112 36.4334 91.9504C38.4051 92.6574 40.2361 92.8684 42.2162 92.1883"
          fill={theme === 0 ? "#1F2530" : "#F3F8FB"}
        />
        <path
          d="M41.9625 92.2621C41.4399 90.3909 40.0109 85.9868 39.1599 83.9146C38.9433 83.3986 38.7311 82.8883 38.5233 82.3835C38.3123 81.8809 38.1045 81.3783 37.87 80.9093C37.4165 79.9989 36.9132 79.1142 36.3623 78.2591C35.843 77.4383 35.3204 76.6794 34.8262 75.9725C34.332 75.2655 33.8546 74.6156 33.3972 74.0426C32.6772 73.1323 31.8863 72.2803 31.0318 71.4947C30.7303 71.2166 30.4891 71.0072 30.3249 70.8681L30.1356 70.7107C30.0921 70.6755 30.0686 70.6604 30.0686 70.6604L30.1289 70.7174L30.3115 70.8849C30.479 71.0289 30.7102 71.2417 31.0084 71.5248C31.8539 72.3179 32.6379 73.1742 33.3537 74.0862C33.8077 74.6608 34.2784 75.3124 34.7709 76.0194C35.2634 76.7263 35.776 77.4885 36.2987 78.3077C36.8449 79.1613 37.3438 80.0444 37.793 80.9528C38.0258 81.4253 38.2319 81.9194 38.4413 82.4237C38.6507 82.9279 38.8617 83.4372 39.0778 83.9531C39.9272 86.0237 41.3578 90.4228 41.8838 92.2889"
          fill={theme === 0 ? "#1F2530" : "#F3F8FB"}
        />
        <path
          d="M37.8489 80.8942C37.8755 80.8087 37.8957 80.7214 37.9093 80.6329C37.9428 80.4654 37.9846 80.2174 38.0315 79.9126C38.127 79.3028 38.2326 78.4568 38.3448 77.5237C38.457 76.5906 38.576 75.748 38.6798 75.1416C38.7335 74.8384 38.7804 74.5938 38.8139 74.4246C38.8363 74.3379 38.8509 74.2493 38.8574 74.1599C38.8236 74.2428 38.7972 74.3286 38.7787 74.4162C38.7335 74.5837 38.6782 74.8266 38.6112 75.1299C38.4855 75.7346 38.3565 76.5772 38.2443 77.512C38.1321 78.4468 38.0332 79.2743 37.9595 79.9025C37.921 80.1957 37.8892 80.4386 37.8657 80.6262C37.851 80.7147 37.8454 80.8045 37.8489 80.8942V80.8942Z"
          fill={theme === 0 ? "#1F2530" : "#F3F8FB"}
        />
        <path
          d="M29.8812 79.3078C29.9076 79.3169 29.9353 79.322 29.9632 79.3229L30.1995 79.3463C30.4055 79.3648 30.702 79.3933 31.0672 79.4402C31.7976 79.5273 32.8027 79.6831 33.9033 79.9042C35.0039 80.1253 35.9923 80.3749 36.7009 80.5743C37.0561 80.6714 37.3408 80.7569 37.5385 80.8155L37.7663 80.8825C37.7925 80.893 37.8203 80.8992 37.8484 80.9009C37.8251 80.8857 37.7997 80.8739 37.773 80.8658L37.5502 80.782C37.3559 80.7116 37.0728 80.6145 36.7126 80.5106C36.0074 80.2945 35.019 80.0382 33.9151 79.8137C32.8111 79.5893 31.8026 79.4452 31.0672 79.3748C30.7003 79.3363 30.3971 79.3179 30.1961 79.3095H29.9582C29.9327 79.3054 29.9068 79.3048 29.8812 79.3078Z"
          fill={theme === 0 ? "#1F2530" : "#F3F8FB"}
        />
        <path
          d="M41.1917 89.7142C41.2127 89.6843 41.2307 89.6523 41.2453 89.6187C41.2821 89.545 41.3273 89.4512 41.3843 89.3339C41.5049 89.0876 41.6708 88.7258 41.8684 88.2768C42.2655 87.3773 42.7781 86.1209 43.3242 84.7271C43.8703 83.3333 44.3712 82.0719 44.7531 81.1673C44.9424 80.725 45.0965 80.3632 45.2088 80.0985C45.2607 79.9796 45.3009 79.8841 45.3328 79.8087C45.3488 79.7755 45.3612 79.7407 45.3696 79.7048C45.3493 79.7352 45.3314 79.7672 45.316 79.8003L45.177 80.0834C45.0563 80.3314 44.8922 80.6932 44.6928 81.1422C44.2958 82.0401 43.7849 83.2981 43.2371 84.6919C42.6893 86.0857 42.1901 87.3471 41.8081 88.25L41.3525 89.3188L41.2285 89.6053C41.2118 89.6399 41.1995 89.6765 41.1917 89.7142V89.7142Z"
          fill={theme === 0 ? "#1F2530" : "#F3F8FB"}
        />
        <path
          d="M30.6272 86.1794C30.6611 86.1964 30.6964 86.2104 30.7327 86.2213L31.0393 86.3252L32.1717 86.7038C33.1266 87.0237 34.4416 87.4811 35.8924 87.9903C37.3431 88.4996 38.6631 88.9486 39.6247 89.2518C40.1055 89.4042 40.4958 89.5232 40.7672 89.6019L41.0805 89.689C41.1161 89.7022 41.1532 89.7106 41.191 89.7141C41.1573 89.6967 41.122 89.6827 41.0855 89.6722L40.7772 89.5667L39.6532 89.1898C38.6966 88.8698 37.3816 88.4125 35.9309 87.9032C34.4802 87.394 33.1601 86.945 32.1985 86.6418C31.7178 86.4894 31.3274 86.3687 31.0577 86.2917L30.7445 86.2029C30.7064 86.1904 30.6671 86.1826 30.6272 86.1794V86.1794Z"
          fill={theme === 0 ? "#1F2530" : "#F3F8FB"}
        />
        <path
          d="M38.4695 58.2956C36.1243 56.7293 34.496 56.6857 33.2915 56.7879C31.7721 56.9136 30.3046 57.391 29.1035 58.4196C28.033 59.3426 27.2976 60.7481 27.4735 62.1519C27.6494 63.5557 28.9142 64.8322 30.3214 64.7351C31.3097 64.6697 32.3081 64.003 33.2211 64.3833C34.181 64.7837 34.3603 66.0267 34.7925 66.9715C35.1302 67.6853 35.6729 68.2823 36.3514 68.6863C37.0299 69.0903 37.8134 69.2829 38.6019 69.2397C40.1716 69.1174 41.6005 67.8861 41.9121 66.3433C42.1449 65.1824 42.1717 63.5658 41.8417 62.43C41.3794 60.8335 40.5016 59.366 38.4578 58.2973"
          fill={theme === 0 ? "#1F2530" : "#F3F8FB"}
        />
        <path
          d="M92.5098 92.4361C92.5098 92.4613 76.2687 92.4797 56.24 92.4797C36.2112 92.4797 19.9668 92.4613 19.9668 92.4361C19.9668 92.411 36.2045 92.3926 56.24 92.3926C76.2754 92.3926 92.5098 92.4127 92.5098 92.4361Z"
          fill={theme === 1 ? "#14171D" : "#191D24"}
        />
        <path
          d="M43.4348 85.2581C43.7798 85.7422 44.4399 85.5747 44.7464 85.2748C45.053 84.975 45.177 84.5428 45.2909 84.1307C45.5522 83.1892 45.8152 82.2008 45.6041 81.2476C45.5533 80.9579 45.4234 80.6877 45.2289 80.467C45.1303 80.3589 45.0051 80.2785 44.8658 80.2339C44.7265 80.1892 44.5779 80.1818 44.4348 80.2124C44.0998 80.3028 43.8971 80.6329 43.7497 80.9461C43.3162 81.8694 43.0942 82.8778 43.0997 83.8978C43.0997 84.3719 43.1634 84.8694 43.4348 85.2581"
          fill="url(#paint0_linear_46_21817)"
        />
        <path
          d="M44.7379 87.7121C45.3071 87.4391 45.9486 87.3551 46.5689 87.4726C46.9078 87.4989 47.2281 87.6381 47.4785 87.8679C47.708 88.1158 47.755 88.5548 47.497 88.7725C47.3304 88.8875 47.129 88.9409 46.9274 88.9233C46.4433 88.9233 45.9039 88.8412 45.5202 89.1377C45.3008 89.3052 45.1567 89.5833 44.892 89.6671C44.7602 89.699 44.6216 89.6872 44.4971 89.6334C44.3726 89.5796 44.2689 89.4868 44.2018 89.3689C44.074 89.1269 44.044 88.845 44.1181 88.5816C44.1474 88.3987 44.2186 88.225 44.3261 88.0742C44.4336 87.9234 44.5746 87.7994 44.7379 87.7121Z"
          fill="url(#paint1_linear_46_21817)"
        />
        <path
          d="M42.5013 85.9667C42.6745 85.7363 42.7887 85.467 42.834 85.1823C42.8792 84.8976 42.8542 84.6062 42.761 84.3334C42.5693 83.7881 42.2308 83.3064 41.7827 82.9413C41.2738 82.4956 40.6825 82.1542 40.0421 81.9362C39.7944 81.8391 39.5288 81.7963 39.2632 81.8105C39.1305 81.8198 39.0022 81.8612 38.8892 81.9313C38.7763 82.0014 38.6821 82.0979 38.6148 82.2126C38.4356 82.561 38.6148 82.9798 38.8058 83.3216C39.1448 83.924 39.5302 84.4992 39.9584 85.042C40.3035 85.4775 40.6988 85.9064 41.2181 86.1007C41.7374 86.295 42.2785 86.2682 42.5499 85.903"
          fill="url(#paint2_linear_46_21817)"
        />
        <path
          d="M43.4838 92.6072C43.4997 92.5339 43.5092 92.4594 43.5123 92.3844C43.5257 92.2169 43.5441 92.0209 43.5642 91.7797C43.5843 91.5385 43.6245 91.2235 43.6915 90.8935C43.7678 90.5361 43.8726 90.1855 44.0048 89.8448C44.1338 89.5045 44.3103 89.1841 44.5291 88.8933C44.7069 88.656 44.9359 88.4618 45.1992 88.3254C45.3772 88.2351 45.5683 88.1735 45.7654 88.1428C45.8394 88.1378 45.9128 88.1266 45.9849 88.1093C45.9103 88.1009 45.835 88.1009 45.7604 88.1093C45.5553 88.1263 45.355 88.1802 45.1691 88.2685C44.8933 88.4031 44.6521 88.5991 44.4638 88.8414C44.2352 89.1365 44.0517 89.4639 43.9194 89.813C43.785 90.1583 43.6813 90.5149 43.6095 90.8784C43.5561 91.1775 43.5214 91.4796 43.5056 91.783C43.4888 92.0393 43.4838 92.2471 43.4821 92.3911C43.4742 92.463 43.4747 92.5355 43.4838 92.6072V92.6072Z"
          fill={theme === 1 ? "#14171D" : "#191D24"}
        />
        <path
          d="M43.5203 92.1415C43.5246 92.1076 43.5246 92.0733 43.5203 92.0394C43.5203 91.9606 43.5203 91.8718 43.5203 91.7445C43.5203 91.4899 43.5203 91.1213 43.5203 90.6657C43.5337 89.7544 43.6007 88.4963 43.738 87.1126C43.8754 85.7289 44.0731 84.4842 44.2406 83.5947C44.2825 83.3702 44.3244 83.1692 44.3629 82.9916C44.4014 82.814 44.4333 82.6565 44.4651 82.5393C44.4969 82.422 44.5137 82.3282 44.5304 82.2528C44.541 82.2198 44.5466 82.1853 44.5472 82.1506C44.5321 82.1815 44.5208 82.2142 44.5137 82.2478C44.4919 82.3215 44.4634 82.4153 44.4299 82.5309C44.3964 82.6465 44.3562 82.804 44.3143 82.9799C44.2724 83.1558 44.2222 83.3585 44.177 83.5813C43.9362 84.7468 43.7583 85.9245 43.6442 87.1092C43.5069 88.4946 43.4499 89.7561 43.4533 90.6707C43.4533 91.1264 43.465 91.4966 43.4784 91.7512C43.4868 91.8702 43.4951 91.9673 43.5002 92.0444C43.5027 92.0775 43.5094 92.1102 43.5203 92.1415Z"
          fill={theme === 1 ? "#14171D" : "#191D24"}
        />
        <path
          d="M43.5052 89.7091C43.5141 89.6071 43.5141 89.5045 43.5052 89.4025C43.4864 89.1217 43.4528 88.8422 43.4047 88.5649C43.3327 88.1574 43.2382 87.7541 43.1216 87.3571C42.9825 86.8691 42.8089 86.3916 42.6023 85.9281C42.2428 85.0767 41.732 84.2974 41.0946 83.6281C40.9831 83.5141 40.8651 83.4068 40.7412 83.3064C40.6875 83.2612 40.639 83.216 40.5904 83.1808L40.453 83.0887C40.3712 83.0258 40.2843 82.9698 40.1934 82.9211C40.4939 83.1516 40.7792 83.4012 41.0477 83.6683C41.6658 84.3431 42.1638 85.1188 42.5202 85.9616C42.7258 86.4207 42.9004 86.8931 43.0429 87.3755C43.1802 87.8194 43.2724 88.2282 43.3427 88.5716C43.4131 88.915 43.4483 89.1981 43.4717 89.3941C43.4756 89.4998 43.4868 89.605 43.5052 89.7091V89.7091Z"
          fill={theme === 1 ? "#14171D" : "#191D24"}
        />
        <path
          d="M83.7677 63.4319C83.7677 61.9242 84.1848 60.4165 84.1614 59.4935C84.1379 58.5705 83.5516 57.5955 82.6419 57.4313C82.322 57.3744 81.9886 57.4179 81.6737 57.3392C81.3018 57.2471 80.9935 56.9924 80.6937 56.7529C79.908 56.1196 79.0604 55.5115 78.0804 55.2686C77.1004 55.0257 75.9512 55.2201 75.2928 55.9873C74.8187 56.5401 74.6646 57.2906 74.5574 58.0109C74.5021 58.3845 74.4569 58.7799 74.6127 59.1233C74.7221 59.3484 74.9075 59.5276 75.1361 59.6293C75.3647 59.7311 75.622 59.7488 75.8624 59.6794"
          fill={theme === 1 ? "#14171D" : "#191D24"}
        />
        <path
          d="M82.7321 72.9889L77.0532 72.7276C77.0214 72.1262 77.3279 68.3972 77.3279 68.3972C77.3279 68.3972 75.182 68.0621 75.1502 65.9162C75.1184 63.7703 75.5036 58.8418 75.5036 58.8418C76.7239 58.319 78.0614 58.1309 79.3785 58.2969C80.6956 58.463 81.9446 58.9772 82.9968 59.7867L83.3151 60.0329L82.7321 72.9889Z"
          fill="#B78876"
        />
        <path
          d="M77.3253 68.3653C78.316 68.3843 79.2899 68.1078 80.1229 67.5713C80.1229 67.5713 79.4629 69.1158 77.3203 68.9215L77.3253 68.3653Z"
          fill="#AA6550"
        />
        <path
          d="M83.0511 62.2527C83.088 62.2359 84.5253 61.8154 84.4784 63.2829C84.4315 64.7504 82.9707 64.4019 82.9707 64.3584C82.9707 64.3148 83.0511 62.2527 83.0511 62.2527Z"
          fill="#B78876"
        />
        <path
          d="M83.4331 63.8172C83.4331 63.8172 83.4582 63.8356 83.5001 63.8574C83.5603 63.8845 83.6284 63.888 83.6911 63.8675C83.8485 63.8155 83.9859 63.5726 84.0026 63.3096C84.0105 63.1838 83.9899 63.0578 83.9423 62.9411C83.9299 62.8949 83.9065 62.8523 83.8741 62.817C83.8418 62.7817 83.8014 62.7548 83.7564 62.7384C83.7273 62.7296 83.6961 62.7314 83.6683 62.7433C83.6404 62.7553 83.6176 62.7766 83.6039 62.8037C83.5822 62.8439 83.5905 62.8741 83.5822 62.8758C83.5738 62.8774 83.552 62.8506 83.5654 62.7903C83.5749 62.7543 83.5961 62.7225 83.6257 62.6999C83.6679 62.6711 83.7193 62.6591 83.7698 62.6664C83.8296 62.6805 83.8847 62.7095 83.9302 62.7509C83.9756 62.7922 84.0098 62.8444 84.0294 62.9026C84.0869 63.032 84.1127 63.1733 84.1048 63.3147C84.0847 63.6112 83.9256 63.8826 83.7112 63.9362C83.6714 63.9463 83.6299 63.9474 83.5897 63.9396C83.5494 63.9318 83.5114 63.9152 83.4783 63.8909C83.4347 63.8524 83.428 63.8206 83.4331 63.8172Z"
          fill="#AA6550"
        />
        <path
          d="M75.9746 62.2057C75.9737 62.2856 76.004 62.3627 76.0591 62.4206C76.1141 62.4785 76.1896 62.5126 76.2695 62.5156C76.3085 62.5186 76.3477 62.5138 76.3848 62.5014C76.4219 62.4891 76.4562 62.4695 76.4857 62.4437C76.5151 62.418 76.5392 62.3867 76.5564 62.3516C76.5737 62.3165 76.5837 62.2783 76.5861 62.2392C76.587 62.1591 76.5565 62.0817 76.501 62.0238C76.4456 61.9658 76.3697 61.9319 76.2896 61.9293C76.2507 61.9264 76.2115 61.9312 76.1745 61.9436C76.1375 61.9559 76.1033 61.9755 76.074 62.0013C76.0446 62.027 76.0207 62.0584 76.0037 62.0935C75.9866 62.1286 75.9767 62.1667 75.9746 62.2057V62.2057Z"
          fill={theme === 1 ? "#14171D" : "#191D24"}
        />
        <path
          d="M75.9078 61.8604C75.9446 61.9007 76.1775 61.7298 76.5075 61.7298C76.8375 61.7298 77.0787 61.8973 77.1139 61.8554C77.1491 61.8135 77.0938 61.765 76.9883 61.6879C76.8459 61.5885 76.6761 61.5358 76.5025 61.5371C76.331 61.5367 76.1639 61.5907 76.025 61.6912C75.9228 61.7717 75.8877 61.8437 75.9078 61.8604Z"
          fill={theme === 1 ? "#14171D" : "#191D24"}
        />
        <path
          d="M79.2032 62.2826C79.2018 62.3626 79.232 62.44 79.2871 62.498C79.3423 62.5559 79.418 62.5899 79.498 62.5925C79.537 62.5955 79.5762 62.5907 79.6133 62.5783C79.6504 62.566 79.6847 62.5464 79.7142 62.5207C79.7437 62.4949 79.7677 62.4636 79.785 62.4285C79.8022 62.3934 79.8123 62.3552 79.8146 62.3161C79.8151 62.2364 79.7847 62.1595 79.7297 62.1017C79.6747 62.0439 79.5995 62.0097 79.5198 62.0062C79.4808 62.0033 79.4416 62.0081 79.4045 62.0204C79.3673 62.0328 79.333 62.0524 79.3036 62.0781C79.2741 62.1038 79.2501 62.1352 79.2328 62.1703C79.2156 62.2054 79.2055 62.2436 79.2032 62.2826V62.2826Z"
          fill={theme === 1 ? "#14171D" : "#191D24"}
        />
        <path
          d="M79.1492 61.9225C79.1878 61.9627 79.4206 61.7902 79.7489 61.7919C80.0773 61.7935 80.3219 61.9594 80.357 61.9175C80.3922 61.8756 80.3369 61.827 80.2297 61.75C80.0871 61.6512 79.9174 61.5991 79.7439 61.6009C79.5725 61.6007 79.4054 61.6546 79.2665 61.755C79.166 61.8321 79.1308 61.9041 79.1492 61.9225Z"
          fill={theme === 1 ? "#14171D" : "#191D24"}
        />
        <path
          d="M77.9528 64.3684C77.7786 64.3149 77.5986 64.2828 77.4167 64.2729C77.3313 64.2628 77.2492 64.2461 77.2375 64.1891C77.2318 64.1021 77.2516 64.0154 77.2944 63.9395C77.3731 63.7351 77.4619 63.5224 77.544 63.2979C77.8891 62.3849 78.1404 61.6344 78.1035 61.6227C78.0667 61.611 77.7551 62.338 77.41 63.251C77.3262 63.4755 77.2425 63.6916 77.1705 63.8959C77.1177 63.9993 77.1023 64.1176 77.1269 64.231C77.1397 64.2603 77.1587 64.2865 77.1828 64.3076C77.2068 64.3288 77.2352 64.3444 77.2659 64.3533C77.3124 64.3658 77.3602 64.3725 77.4083 64.3734C77.5892 64.3961 77.7723 64.3944 77.9528 64.3684V64.3684Z"
          fill={theme === 1 ? "#14171D" : "#191D24"}
        />
        <path
          d="M78.9234 64.5977C78.8698 64.5977 78.8698 64.9512 78.5632 65.2058C78.2567 65.4605 77.8747 65.4203 77.873 65.4705C77.873 65.494 77.9568 65.5409 78.1176 65.5442C78.3275 65.548 78.5319 65.4767 78.6939 65.3432C78.8484 65.218 78.9486 65.0379 78.9737 64.8407C78.9871 64.6832 78.9485 64.5944 78.9234 64.5977Z"
          fill={theme === 1 ? "#14171D" : "#191D24"}
        />
        <path
          d="M79.0641 60.6244C79.0976 60.7131 79.4259 60.6713 79.8129 60.7182C80.1999 60.7651 80.5131 60.8689 80.5651 60.7885C80.5868 60.75 80.5332 60.6662 80.4076 60.5791C80.2399 60.4705 80.0486 60.4039 79.8498 60.3848C79.6516 60.3613 79.4508 60.3849 79.2634 60.4535C79.1227 60.5171 79.0507 60.5875 79.0641 60.6244Z"
          fill={theme === 1 ? "#14171D" : "#191D24"}
        />
        <path
          d="M76.0284 60.9073C76.087 60.981 76.3132 60.9073 76.5862 60.9073C76.8593 60.9073 77.0888 60.9576 77.1441 60.8788C77.1675 60.8403 77.1324 60.7649 77.0302 60.6946C76.8943 60.6097 76.7362 60.5671 76.5761 60.5722C76.4159 60.5773 76.2609 60.6298 76.1306 60.723C76.0351 60.7917 76.0066 60.8705 76.0284 60.9073Z"
          fill={theme === 1 ? "#14171D" : "#191D24"}
        />
        <path
          d="M83.4287 59.9308C83.4002 60.5556 83.5392 61.2525 83.4287 61.8673C83.4119 61.9595 83.1707 62.2024 83.1506 62.2995C83.0233 62.844 82.7988 63.0768 82.4805 62.5156C82.3566 62.2995 82.2761 61.6998 82.2276 61.4569C82.0969 60.7969 81.9668 60.1363 81.8372 59.4751C81.3334 59.7363 80.773 59.8692 80.2056 59.8621C79.6411 59.8286 79.0681 59.5421 78.8102 59.0396C78.6756 59.2615 78.4929 59.4503 78.2755 59.5921C78.0582 59.734 77.8118 59.8251 77.5544 59.8589C77.2971 59.8927 77.0355 59.8682 76.7889 59.7873C76.5423 59.7063 76.3171 59.571 76.1298 59.3914C76.0249 59.5389 75.8684 59.6418 75.6913 59.6796C75.5142 59.7175 75.3294 59.6875 75.1733 59.5957C75.0231 59.4947 74.9085 59.3488 74.846 59.1789C74.7834 59.0089 74.776 58.8236 74.8248 58.6493C74.9723 58.1249 75.5335 57.8401 76.0561 57.6877C78.0965 57.0863 80.3547 57.557 82.189 58.6342C82.4629 58.782 82.7131 58.97 82.9312 59.192C83.1406 59.4215 83.4337 59.6946 83.4337 60.0012"
          fill={theme === 1 ? "#14171D" : "#191D24"}
        />
        <path
          d="M75.0938 56.1816C75.2156 56.0634 75.3473 55.9558 75.4874 55.86C75.8847 55.6221 76.3457 55.5127 76.8075 55.5467C77.965 55.6221 79.5766 56.2369 80.8464 57.5084C81.0239 57.6851 81.1916 57.8714 81.349 58.0662L81.3875 58.1115L81.4411 58.0863C81.775 57.9369 82.1497 57.9052 82.504 57.9965C82.8582 58.0878 83.171 58.2967 83.391 58.5889C83.778 59.1484 83.7814 59.8135 83.6959 60.3077C83.6174 60.7057 83.5002 61.0951 83.3458 61.4702C83.2859 61.6064 83.235 61.7463 83.1934 61.889C83.275 61.7641 83.3435 61.6311 83.3977 61.492C83.5758 61.1178 83.7101 60.7243 83.7981 60.3194C83.8558 60.0345 83.8705 59.7426 83.8417 59.4533C83.8093 59.1146 83.6943 58.789 83.5066 58.5051C83.2698 58.1783 82.927 57.9437 82.5366 57.8414C82.1462 57.7391 81.7324 57.7753 81.3657 57.9439L81.4595 57.9657C81.2994 57.7664 81.1289 57.5757 80.9486 57.3945C79.652 56.1012 78.0153 55.4881 76.8108 55.4311C76.3304 55.4066 75.8547 55.537 75.4539 55.803C75.348 55.8767 75.2523 55.964 75.1691 56.0627C75.1361 56.0967 75.1104 56.1372 75.0938 56.1816V56.1816Z"
          fill={theme === 1 ? "#14171D" : "#191D24"}
        />
        <path
          d="M76.2021 58.6426C76.2602 58.3415 76.3812 58.0561 76.5572 57.805C76.7581 57.5419 77.0188 57.3305 77.3178 57.1885C77.6861 57.0251 78.0883 56.9527 78.4904 56.9774C79.3632 57.0243 80.0886 57.4063 80.5911 57.6777C80.8563 57.8447 81.1315 57.9952 81.4153 58.1283C81.4153 58.1199 81.3517 58.0613 81.2193 57.9608C81.087 57.8602 80.8943 57.7313 80.6464 57.5771C80.1539 57.2806 79.4151 56.8669 78.4988 56.8183C78.0689 56.7899 77.6389 56.8712 77.2491 57.0545C76.9332 57.2107 76.6622 57.4447 76.4617 57.7346C76.3279 57.929 76.2381 58.1502 76.1987 58.3829C76.1801 58.4686 76.1812 58.5574 76.2021 58.6426Z"
          fill={theme === 1 ? "#14171D" : "#191D24"}
        />
        <path
          d="M81.4988 63.8942H76.4531C76.0252 63.8942 75.6149 63.7242 75.3123 63.4217C75.0098 63.1192 74.8398 62.7088 74.8398 62.281C74.8403 61.8534 75.0104 61.4435 75.3129 61.1413C75.6154 60.8392 76.0255 60.6694 76.4531 60.6694H81.4988C81.9262 60.6694 82.3361 60.8392 82.6383 61.1414C82.9405 61.4437 83.1103 61.8536 83.1103 62.281C83.1103 62.7085 82.9406 63.1186 82.6384 63.4211C82.3362 63.7236 81.9263 63.8938 81.4988 63.8942Z"
          fill="url(#paint3_linear_46_21817)"
        />
        <g opacity="0.3">
          <path
            d="M81.4988 63.8942H76.4531C76.0252 63.8942 75.6149 63.7242 75.3123 63.4217C75.0098 63.1192 74.8398 62.7088 74.8398 62.281C74.8403 61.8534 75.0104 61.4435 75.3129 61.1413C75.6154 60.8392 76.0255 60.6694 76.4531 60.6694H81.4988C81.9262 60.6694 82.3361 60.8392 82.6383 61.1414C82.9405 61.4437 83.1103 61.8536 83.1103 62.281C83.1103 62.7085 82.9406 63.1186 82.6384 63.4211C82.3362 63.7236 81.9263 63.8938 81.4988 63.8942Z"
            fill={theme === 1 ? "#14171D" : "#191D24"}
          />
        </g>
        <path
          d="M79.3693 63.8942H74.3303C73.9027 63.8938 73.4928 63.7236 73.1907 63.4211C72.8885 63.1186 72.7187 62.7085 72.7188 62.281C72.7188 61.8536 72.8885 61.4437 73.1908 61.1414C73.493 60.8392 73.9029 60.6694 74.3303 60.6694H79.376C79.8036 60.6694 80.2136 60.8392 80.5161 61.1413C80.8186 61.4435 80.9888 61.8534 80.9892 62.281V62.281C80.9892 62.4934 80.9473 62.7037 80.8658 62.8999C80.7843 63.096 80.6649 63.2742 80.5144 63.4241C80.3638 63.574 80.1852 63.6927 79.9887 63.7733C79.7922 63.854 79.5817 63.8951 79.3693 63.8942Z"
          fill="url(#paint4_linear_46_21817)"
        />
        <path
          d="M79.3161 63.7416H74.3776C73.9951 63.7416 73.6282 63.5897 73.3578 63.3192C73.0873 63.0487 72.9353 62.6818 72.9353 62.2993C72.9353 61.9167 73.0873 61.5499 73.3578 61.2794C73.6282 61.0089 73.9951 60.8569 74.3776 60.8569H79.3161C79.6987 60.8569 80.0655 61.0089 80.336 61.2794C80.6065 61.5499 80.7585 61.9167 80.7585 62.2993V62.2993C80.7585 62.6818 80.6065 63.0487 80.336 63.3192C80.0655 63.5897 79.6987 63.7416 79.3161 63.7416V63.7416Z"
          fill={theme === 1 ? "#14171D" : "#191D24"}
        />
        <path
          d="M76.6215 60.8569L74.2812 63.7383H74.943L77.3201 60.8569H76.6215Z"
          fill={theme === 0 ? "#1F2530" : "#F3F8FB"}
        />
        <path
          d="M77.1921 61.78C76.9621 62.1175 76.7085 62.4382 76.4332 62.7399C76.1792 63.0597 75.9032 63.3614 75.6074 63.6429C76.0903 62.9847 76.6199 62.3622 77.1921 61.78V61.78Z"
          fill={theme === 0 ? "#1F2530" : "#F3F8FB"}
        />
        <path
          d="M77.5723 61.2741C77.5908 61.2892 77.5573 61.3629 77.497 61.4416C77.4367 61.5204 77.3713 61.5656 77.3529 61.5505C77.3345 61.5355 77.368 61.4617 77.4283 61.383C77.4886 61.3043 77.5522 61.259 77.5723 61.2741Z"
          fill={theme === 0 ? "#1F2530" : "#F3F8FB"}
        />
        <path
          d="M75.855 61.1267C75.7465 61.3554 75.6035 61.5662 75.4312 61.7516C75.2811 61.9551 75.1015 62.1352 74.8984 62.286C75.0374 62.0771 75.1931 61.8799 75.3641 61.6963C75.5122 61.4935 75.6763 61.3031 75.855 61.1267V61.1267Z"
          fill={theme === 0 ? "#1F2530" : "#F3F8FB"}
        />
        <path
          d="M90.4709 84.1676L91.476 92.428L88.0218 92.4129L86.5409 84.0386L90.4709 84.1676Z"
          fill="#B78876"
        />
        <path
          d="M66.2978 82.5829L72.1693 79.4788L72.9165 92.3912L88.0218 92.4147L86.9128 84.4256L92.3086 84.2866C92.3086 84.2866 91.3035 77.956 90.8964 76.5371C90.4894 75.1182 88.9046 73.51 87.6817 72.8533C86.4588 72.1967 82.8387 70.617 82.8387 70.617C80.7849 71.4797 78.8886 71.4546 77.1748 70.3875C76.1762 70.5284 75.193 70.7628 74.2382 71.0877C72.9885 71.5232 71.7103 72.0476 71.1743 72.3625C70.6382 72.6775 64.9492 76.2875 64.9492 76.2875L66.2978 82.5829Z"
          fill="url(#paint5_linear_46_21817)"
        />
        <path
          d="M86.6027 77.6846C86.6091 77.7115 86.6125 77.739 86.6127 77.7667C86.6127 77.8303 86.6228 77.9074 86.6278 78.0012C86.6378 78.219 86.6513 78.5037 86.668 78.8656C86.6998 79.6077 86.7434 80.6095 86.7903 81.7134C86.8372 82.8174 86.8925 83.8342 86.9394 84.5613C86.9628 84.9147 86.9813 85.2062 86.9963 85.424C86.9963 85.5178 87.0064 85.5915 87.0097 85.6585C87.0138 85.6858 87.0138 85.7134 87.0097 85.7406C87.0003 85.7148 86.9947 85.6877 86.993 85.6602C86.9846 85.5966 86.9762 85.5195 86.9628 85.4274C86.9377 85.223 86.9109 84.9248 86.8791 84.5646C86.8171 83.8376 86.7551 82.8291 86.7116 81.7168C86.668 80.6044 86.6311 79.5943 86.6144 78.8689C86.6144 78.5138 86.6144 78.2223 86.6027 78.0045C86.6027 77.9107 86.6027 77.837 86.6027 77.7683C86.5986 77.7406 86.5986 77.7123 86.6027 77.6846V77.6846Z"
          fill={theme === 1 ? "#14171D" : "#191D24"}
        />
        <path
          d="M72.4646 83.8659C72.4497 83.7876 72.4435 83.7078 72.4462 83.6281C72.4378 83.4605 72.4295 83.2394 72.4177 82.9764C72.3926 82.4102 72.3574 81.6597 72.3189 80.8288C72.2804 79.9979 72.2519 79.229 72.2452 78.6795C72.2452 78.4031 72.2452 78.1769 72.2452 78.0262C72.2418 77.946 72.2468 77.8657 72.2603 77.7866C72.274 77.8657 72.2802 77.9459 72.2787 78.0262C72.2787 78.1937 72.2954 78.4148 72.3055 78.6778C72.3323 79.244 72.3675 79.9945 72.406 80.8254C72.4445 81.6563 72.473 82.4236 72.4797 82.9747C72.4797 83.2495 72.4797 83.4773 72.4797 83.6281C72.4809 83.7076 72.4759 83.7872 72.4646 83.8659V83.8659Z"
          fill={theme === 1 ? "#14171D" : "#191D24"}
        />
        <g opacity="0.3">
          <path
            d="M86.6314 78.3379C87.1149 79.7741 87.5985 81.2086 88.0821 82.6415C88.2178 83.0469 88.3551 83.4791 88.2395 83.8929C88.124 84.3066 87.5427 84.434 86.9128 84.4256C86.9128 84.4256 86.8893 83.8426 86.8659 83.4841C86.7542 81.8525 86.6459 80.2219 86.5409 78.5925"
            fill={theme === 1 ? "#14171D" : "#191D24"}
          />
        </g>
        <path
          d="M89.9706 80.6446C89.9908 80.6075 90.0131 80.5717 90.0376 80.5374L90.2269 80.2543C90.3944 80.013 90.6206 79.6763 90.897 79.2776L90.9054 79.3312L89.5619 78.3814L89.6222 78.3713L89.6138 78.3831L89.597 78.4065L89.5635 78.4518L89.4982 78.5439C89.4547 78.6042 89.4128 78.6645 89.3692 78.7231C89.2855 78.8421 89.2017 78.9577 89.1213 79.0699L88.664 79.7015L88.6539 79.6495C89.0493 79.936 89.3759 80.1806 89.6088 80.3548L89.8802 80.5608C89.9144 80.5853 89.9468 80.6122 89.9773 80.6412C89.9432 80.6265 89.9111 80.6073 89.8818 80.5843L89.6071 80.3983C89.3675 80.2308 89.0225 80.0013 88.6104 79.7115L88.5802 79.6897L88.602 79.6579L89.0526 79.0213L89.2989 78.6729C89.3424 78.6142 89.3843 78.5539 89.4279 78.4936L89.4932 78.4015L89.525 78.3563L89.5418 78.3328L89.5501 78.3211L89.6104 78.311L90.9506 79.2776L90.9824 79.2994L90.959 79.3296C90.6642 79.7383 90.4129 80.0734 90.2403 80.3045L90.0393 80.5675C89.9957 80.6195 89.9723 80.6496 89.9706 80.6446Z"
          fill={theme === 0 ? "#1F2530" : "#F3F8FB"}
        />
        <path
          d="M84.2584 77.3093C84.2775 77.2716 84.2999 77.2357 84.3255 77.2021C84.3757 77.125 84.4394 77.0345 84.5148 76.9189C84.6823 76.6794 84.9084 76.341 85.1848 75.9423L85.1949 75.9959L83.8463 75.0394L83.9067 75.0293L83.8983 75.0394L83.8815 75.0628L83.8497 75.1097L83.7844 75.2002L83.6554 75.3794C83.5699 75.4984 83.4879 75.614 83.4058 75.7262L82.9434 76.3712L82.935 76.3175C83.3304 76.604 83.657 76.8486 83.8899 77.0228L84.1596 77.2289C84.1941 77.2537 84.2271 77.2805 84.2584 77.3093C84.2584 77.3093 84.2266 77.2942 84.163 77.2523L83.8866 77.068C83.6487 76.9005 83.3036 76.6693 82.8915 76.3795L82.8613 76.3578L82.8831 76.3259L83.3321 75.6893L83.58 75.3409L83.709 75.1617L83.7743 75.0695L83.8061 75.0243L83.8229 75.0008V74.9891C83.7676 74.9975 83.8966 74.9757 83.8832 74.9774L85.2234 75.944L85.2535 75.9658L85.2317 75.9976C84.9352 76.4047 84.6856 76.7397 84.5131 76.9709L84.312 77.2339C84.2967 77.2607 84.2787 77.2859 84.2584 77.3093V77.3093Z"
          fill={theme === 0 ? "#1F2530" : "#F3F8FB"}
        />
        <path
          d="M75.8809 78.068C75.9005 78.03 75.9229 77.9936 75.948 77.9591L76.1373 77.6777C76.3048 77.4364 76.5309 77.0997 76.8073 76.701L76.8157 76.753L75.4672 75.7964L75.5275 75.7864L75.5191 75.7981L75.504 75.8216L75.4705 75.8668L75.4052 75.9589C75.3616 76.0192 75.3181 76.0779 75.2762 76.1382C75.1908 76.2554 75.1087 76.3727 75.0266 76.4849L74.5693 77.1165V77.0646C74.9646 77.351 75.2913 77.5956 75.5241 77.7698L75.7938 77.9759C75.8288 78.0001 75.8618 78.027 75.8927 78.0563C75.8592 78.0401 75.8273 78.0211 75.7972 77.9993L75.504 77.8251C75.2645 77.6576 74.9194 77.4281 74.4989 77.1366L74.4688 77.1148L74.4905 77.0847L74.9395 76.4481L75.1874 76.0996L75.3164 75.9187L75.3817 75.8283L75.4136 75.7813L75.4303 75.7596L75.4387 75.7478L75.499 75.7378L76.8392 76.7044L76.8693 76.7262L76.8475 76.7563C76.551 77.1651 76.3014 77.5001 76.1289 77.7296L75.9278 77.9943C75.9141 78.02 75.8984 78.0446 75.8809 78.068V78.068Z"
          fill={theme === 0 ? "#1F2530" : "#F3F8FB"}
        />
        <path
          d="M76.6767 84.1257C76.6968 84.0886 76.7192 84.0528 76.7437 84.0184L76.933 83.7353C77.1005 83.4941 77.3267 83.1574 77.6031 82.7587V82.8123L76.2629 81.8558L76.3232 81.8457L76.3148 81.8574L76.2981 81.8809L76.2646 81.9261L76.1993 82.0183C76.1557 82.0786 76.1138 82.1389 76.0703 82.1975C75.9865 82.3164 75.9027 82.432 75.8223 82.5443L75.365 83.1758L75.355 83.1239C75.7503 83.4103 76.077 83.6549 76.3098 83.8291L76.5812 84.0352C76.6154 84.0597 76.6479 84.0866 76.6784 84.1156C76.6447 84.0999 76.6127 84.0808 76.5829 84.0587L76.3081 83.8727C76.0703 83.7052 75.7235 83.4757 75.3114 83.1859L75.2812 83.1641L75.303 83.1323L75.7537 82.4957L75.9999 82.1472L76.1289 81.968L76.1942 81.8759L76.2277 81.8306L76.2428 81.8072L76.2512 81.7955L76.3132 81.7854L77.6533 82.752L77.6852 82.7738L77.6634 82.8039C77.3669 83.2127 77.1156 83.5477 76.943 83.7789L76.742 84.0419C76.7085 84.1005 76.6851 84.1307 76.6767 84.1257Z"
          fill={theme === 0 ? "#1F2530" : "#F3F8FB"}
        />
        <path
          d="M69.417 79.0917C69.436 79.054 69.4584 79.0181 69.484 78.9845C69.5342 78.9075 69.5979 78.817 69.6733 78.7014C69.8408 78.4618 70.067 78.1235 70.3434 77.7248L70.3534 77.7784L69.0133 76.8218L69.0736 76.8118L69.0652 76.8235L69.0484 76.847L69.0166 76.8922L68.9513 76.9843L68.8223 77.1636C68.7368 77.2825 68.6548 77.3981 68.5727 77.5103C68.4052 77.7348 68.2577 77.9476 68.1153 78.1419L68.107 78.09C68.5023 78.3764 68.829 78.621 69.0618 78.7952L69.3315 79.0013C69.3661 79.0261 69.3991 79.0529 69.4304 79.0817C69.4304 79.0817 69.3969 79.0666 69.3349 79.0247L69.0585 78.8404C68.8206 78.6729 68.4755 78.4417 68.0634 78.1519L68.0333 78.1302L68.055 78.0983L68.504 77.4618L68.7519 77.1133L68.8809 76.9341L68.9446 76.8419L68.9781 76.7967L68.9948 76.7732L69.0032 76.7615L69.0635 76.7515L70.4037 77.7181L70.4338 77.7398L70.412 77.7717C70.1155 78.1787 69.8659 78.5138 69.6917 78.745L69.4924 79.008C69.4706 79.0388 69.4453 79.0669 69.417 79.0917Z"
          fill={theme === 0 ? "#1F2530" : "#F3F8FB"}
        />
        <path
          d="M80.8786 79.8138C80.9196 79.7338 80.9666 79.657 81.0193 79.5843C81.1081 79.4469 81.2337 79.2576 81.3879 79.0348L81.3962 79.0884L80.6374 78.5523L80.696 78.549L80.6859 78.5607L80.6675 78.5875L80.6307 78.6378C80.6072 78.6729 80.5821 78.7064 80.5586 78.74C80.5101 78.8053 80.4631 78.8706 80.4179 78.9343L80.1599 79.2877L80.1516 79.2358C80.3693 79.4033 80.5486 79.5357 80.6776 79.6362C80.7504 79.6874 80.8198 79.7433 80.8853 79.8037C80.812 79.7673 80.742 79.7247 80.6759 79.6764C80.5402 79.5893 80.3409 79.4569 80.1063 79.2944L80.0762 79.2727L80.098 79.2408L80.3492 78.8823L80.4883 78.6863L80.5603 78.5842L80.5972 78.5339L80.6156 78.5071V78.4937L80.6759 78.4836L81.4281 79.0281L81.4599 79.0515L81.4381 79.0817C81.2706 79.3129 81.1232 79.5005 81.0227 79.6278C80.9801 79.6938 80.9319 79.7561 80.8786 79.8138V79.8138Z"
          fill={theme === 0 ? "#1F2530" : "#F3F8FB"}
        />
        <path
          d="M88.082 76.4096C88.1248 76.3312 88.1718 76.2551 88.2227 76.1818C88.3132 76.0444 88.4388 75.8467 88.5929 75.6323L88.6013 75.6842L87.8424 75.1482L87.9027 75.1381V75.1515L87.8843 75.1766L87.8475 75.2286L87.7754 75.3291L87.6347 75.5234L87.3767 75.8786L87.3667 75.825C87.5845 75.9925 87.7654 76.1248 87.8944 76.2253C87.9673 76.2757 88.0362 76.3317 88.1004 76.3928C88.0276 76.3572 87.9581 76.3152 87.8927 76.2672C87.757 76.1784 87.5576 76.0478 87.3231 75.8836L87.293 75.8618L87.3147 75.83L87.566 75.4715L87.7051 75.2755L87.7771 75.175L87.814 75.123L87.8324 75.0979L87.8408 75.0845L87.9011 75.0745L88.6532 75.6189L88.6851 75.6424L88.6616 75.6725C88.4941 75.9037 88.3467 76.0913 88.2478 76.2186C88.199 76.2876 88.1434 76.3516 88.082 76.4096V76.4096Z"
          fill={theme === 0 ? "#1F2530" : "#F3F8FB"}
        />
        <path
          d="M70.8902 75.2974C70.9318 75.2183 70.9789 75.1422 71.0309 75.0695C71.1197 74.9322 71.2453 74.7345 71.3994 74.5201L71.4078 74.572L70.6456 74.0376L70.7059 74.0276L70.6958 74.041L70.6774 74.0678L70.6406 74.118L70.5685 74.2185L70.4278 74.4145L70.1698 74.768L70.1598 74.7144C70.3775 74.8819 70.5585 75.0143 70.6875 75.1164C70.7596 75.1686 70.829 75.2245 70.8952 75.284C70.8214 75.2492 70.7512 75.2071 70.6858 75.1583C70.5501 75.0695 70.3507 74.9389 70.1162 74.7747L70.0861 74.7529L70.1078 74.7228L70.3591 74.3626L70.4982 74.1666L70.5702 74.0661L70.607 74.0142L70.6255 73.989V73.9756L70.6858 73.9656L71.4379 74.5117L71.4698 74.5335L71.4463 74.5636C71.2788 74.7948 71.1331 74.9841 71.0325 75.1114C70.9903 75.1772 70.9427 75.2394 70.8902 75.2974V75.2974Z"
          fill={theme === 0 ? "#1F2530" : "#F3F8FB"}
        />
        <path
          d="M83.0128 83.2161C83.0542 83.2256 83.0951 83.2373 83.1351 83.2513L83.4584 83.3552C83.7349 83.4456 84.1285 83.573 84.5842 83.7321L84.5356 83.7556C84.6947 83.2982 84.8824 82.7638 85.08 82.1943C85.08 82.1825 85.1303 82.2998 85.1068 82.2495H85.0951L85.0532 82.2345L84.9996 82.216L84.8941 82.1775L84.6847 82.1038L84.2826 81.9614L83.5456 81.6934L83.5941 81.6699C83.4266 82.1289 83.2842 82.5075 83.1804 82.7823C83.1318 82.9096 83.0899 83.0151 83.0581 83.1006C83.0437 83.1401 83.027 83.1787 83.0078 83.2161C83.0137 83.1795 83.0232 83.1436 83.0363 83.1089L83.1368 82.7923C83.2273 82.5193 83.3579 82.1222 83.5221 81.6448L83.5355 81.6096L83.5707 81.623L84.3078 81.8777L84.7115 82.02L84.9192 82.0921L85.0248 82.1306L85.0784 82.149L85.1052 82.1574H85.1186C85.0934 82.1072 85.1504 82.2261 85.1454 82.2127C84.941 82.7806 84.7517 83.3133 84.5875 83.7706L84.5758 83.8058L84.5406 83.7924C84.0665 83.6249 83.6745 83.4741 83.4048 83.3719L83.0966 83.2513C83.0676 83.2425 83.0395 83.2307 83.0128 83.2161V83.2161Z"
          fill={theme === 0 ? "#1F2530" : "#F3F8FB"}
        />
        <g opacity="0.2">
          <path
            d="M66.4115 75.4146C66.894 76.2354 67.3781 77.0629 67.6914 77.9625C67.9689 78.7007 68.0687 79.4938 67.9829 80.2777C67.8933 81.0665 67.507 81.7918 66.9024 82.3063C66.7313 82.4572 66.5165 82.5493 66.2893 82.5693C65.8855 82.5811 65.5639 82.2343 65.383 81.8708C64.9441 80.9879 65.0128 79.946 65.1451 78.9693C65.2841 77.9316 65.4816 76.9025 65.7364 75.887"
            fill={theme === 1 ? "#14171D" : "#191D24"}
          />
        </g>
        <path
          d="M67.1541 80.132L65.5392 68.7608V66.8996C65.5392 66.8996 65.293 62.7116 65.3215 62.2995C65.3533 61.8321 65.1338 61.7031 64.9613 61.7031C64.6899 61.7031 64.4772 62.0231 64.437 62.4235C64.4235 62.5625 64.2946 64.7687 64.2946 64.7687C64.1808 64.5528 64.052 64.3452 63.9093 64.1472C63.6111 63.7134 62.8639 63.4269 62.0783 63.4001C61.2926 63.3733 61.0731 63.4353 61.0463 63.7351C61.0443 63.7928 61.0553 63.8501 61.0785 63.9029C61.1017 63.9557 61.1365 64.0026 61.1804 64.04C61.316 64.1704 61.4949 64.2462 61.6829 64.2528C62.9393 64.3131 63.2325 65.0066 63.2325 65.0066V65.2495C63.2325 65.2495 62.5071 64.6548 61.8839 64.8441C61.7924 64.8723 61.7071 64.9178 61.6327 64.9781C61.4613 64.9671 61.2901 65.001 61.1359 65.0767C60.9818 65.1523 60.8502 65.267 60.7541 65.4093C60.6581 65.5516 60.6009 65.7166 60.5884 65.8878C60.5759 66.059 60.6085 66.2306 60.6828 66.3853C60.7114 66.4635 60.763 66.5312 60.8309 66.5794C60.8989 66.6276 60.9798 66.6539 61.0631 66.655C61.149 66.6347 61.2284 66.5932 61.2943 66.5344C61.331 66.5058 61.3703 66.4806 61.4115 66.459L61.507 67.1408C61.507 67.1408 61.8035 68.6619 61.8571 69.3387L61.6611 80.4704C61.6547 80.8369 61.7213 81.201 61.857 81.5414C61.9928 81.8819 62.1949 82.1919 62.4518 82.4534C62.7086 82.7149 63.0149 82.9227 63.3529 83.0645C63.6908 83.2064 64.0537 83.2796 64.4202 83.2797C64.8155 83.2798 65.2062 83.195 65.5658 83.031C65.9255 82.867 66.2458 82.6278 66.505 82.3293C66.7642 82.0309 66.9563 81.6803 67.0683 81.3012C67.1803 80.9221 67.2095 80.5234 67.1541 80.132V80.132Z"
          fill="#B78876"
        />
        <path
          d="M65.1892 68.5864C64.9547 68.6333 64.7285 68.4491 64.618 68.2363C64.5074 68.0236 64.4722 67.7823 64.3767 67.5662C64.092 66.9112 63.3214 66.5611 63.0684 65.891C63.0332 65.7972 63.0198 65.6716 63.1036 65.6163C63.1874 65.561 63.2828 65.6163 63.3599 65.6665L65.3534 66.9498"
          fill="#B78876"
        />
        <path
          d="M65.3527 66.9397C65.2929 66.8757 65.2254 66.8194 65.1517 66.7722C65.016 66.6767 64.8166 66.541 64.5754 66.3768L63.711 65.8173L63.4597 65.6498C63.3681 65.5675 63.2477 65.5248 63.1247 65.5308C63.0859 65.5406 63.0509 65.5618 63.0243 65.5916C62.9977 65.6215 62.9808 65.6587 62.9756 65.6984C62.9679 65.7634 62.9754 65.8293 62.9974 65.891C63.0389 66.0004 63.0923 66.1048 63.1565 66.2026C63.4178 66.5946 63.7713 66.8526 64.0209 67.1424C64.1427 67.2737 64.2438 67.4229 64.3208 67.5846C64.3894 67.7371 64.428 67.8945 64.4799 68.0353C64.5098 68.1535 64.5652 68.2638 64.6422 68.3584C64.7191 68.453 64.8158 68.5297 64.9255 68.5831C64.9663 68.6024 65.011 68.6125 65.0562 68.6125C65.1014 68.6125 65.146 68.6024 65.1868 68.5831C65.1043 68.5783 65.0232 68.559 64.9473 68.5261C64.851 68.4691 64.7676 68.3925 64.7027 68.3014C64.6378 68.2102 64.5927 68.1064 64.5704 67.9967C64.5294 67.839 64.4802 67.6836 64.423 67.531C64.3466 67.3558 64.2425 67.194 64.1147 67.0519C63.8601 66.747 63.51 66.484 63.2771 66.1205C63.2196 66.0325 63.1713 65.9387 63.133 65.8407C63.0995 65.7469 63.1113 65.6565 63.1649 65.6515C63.2185 65.6464 63.3123 65.695 63.3977 65.7503L63.6507 65.9178L64.5302 66.4673C64.7814 66.6214 64.9858 66.7437 65.1299 66.8274C65.1986 66.875 65.2736 66.9128 65.3527 66.9397V66.9397Z"
          fill="#AA6550"
        />
        <path
          d="M63.2627 65.263C63.259 65.1112 63.2193 64.9626 63.1471 64.8291C63.0613 64.6923 62.9339 64.5866 62.7836 64.5275C62.5956 64.4543 62.3971 64.4119 62.1956 64.4019C61.9874 64.3911 61.7808 64.3591 61.5791 64.3064C61.4104 64.2596 61.2568 64.1695 61.1335 64.0451C61.0912 64.0013 61.0599 63.9481 61.0422 63.8898C61.0244 63.8316 61.0207 63.77 61.0313 63.71C61.0371 63.6674 61.0539 63.627 61.0799 63.5928C61.0883 63.5928 61.0799 63.638 61.0648 63.7151C61.0619 63.7682 61.0706 63.8214 61.0902 63.8708C61.1099 63.9203 61.1401 63.9649 61.1787 64.0015C61.2988 64.1113 61.4446 64.189 61.6026 64.2277C61.8002 64.2737 62.0014 64.3023 62.204 64.3131C62.4146 64.3253 62.6218 64.3723 62.8171 64.4522C62.98 64.521 63.115 64.6426 63.2007 64.7972C63.2557 64.9004 63.285 65.0154 63.2862 65.1323C63.2899 65.1771 63.2818 65.2222 63.2627 65.263Z"
          fill="#AA6550"
        />
        <path
          d="M64.4553 62.2693C64.4596 62.4154 64.4518 62.5617 64.4319 62.7065C64.4084 63.0064 64.3816 63.3632 64.3515 63.7569C64.3213 64.1505 64.3029 64.509 64.2844 64.8089C64.2855 64.9555 64.2726 65.1019 64.2459 65.2461C64.2227 65.101 64.2154 64.9539 64.2241 64.8072C64.2241 64.5375 64.2342 64.1623 64.2643 63.7518C64.2945 63.3414 64.333 62.9678 64.3716 62.6998C64.3827 62.5535 64.4108 62.4091 64.4553 62.2693V62.2693Z"
          fill="#AA6550"
        />
        <path
          d="M62.7342 65.7236C62.7342 65.7236 62.6873 65.7052 62.6103 65.6666C62.5605 65.6364 62.5048 65.6174 62.447 65.6107C62.3891 65.6041 62.3306 65.61 62.2752 65.6281C62.2416 65.6428 62.2124 65.666 62.1904 65.6953C62.1684 65.7247 62.1543 65.7592 62.1496 65.7956C62.137 65.8901 62.137 65.9859 62.1496 66.0804C62.1646 66.1975 62.1379 66.3161 62.0742 66.4155C62.0324 66.4661 61.9792 66.5062 61.9189 66.5324C61.8586 66.5586 61.793 66.5702 61.7274 66.5662C61.6631 66.5629 61.6001 66.5463 61.5424 66.5175C61.4847 66.4887 61.4337 66.4482 61.3924 66.3987C61.3151 66.31 61.2655 66.2004 61.25 66.0838C61.238 65.8949 61.2538 65.7052 61.2969 65.5209C61.3214 65.3961 61.3673 65.2765 61.4326 65.1674C61.4829 65.092 61.5264 65.0636 61.5281 65.0669C61.5298 65.0703 61.4996 65.1071 61.4611 65.1842C61.4106 65.2956 61.3757 65.4134 61.3572 65.5343C61.3245 65.7121 61.3155 65.8935 61.3304 66.0737C61.3423 66.1765 61.3878 66.2725 61.4599 66.3468C61.5321 66.421 61.6267 66.4693 61.7291 66.4841C61.7809 66.4872 61.8326 66.4782 61.8803 66.4578C61.9279 66.4375 61.9702 66.4063 62.0039 66.3669C62.0588 66.2834 62.0815 66.1827 62.0675 66.0838C62.0541 65.9826 62.0541 65.8801 62.0675 65.7789C62.0748 65.7316 62.0939 65.6869 62.1233 65.6491C62.1526 65.6113 62.191 65.5816 62.235 65.5628C62.2986 65.5436 62.3658 65.5395 62.4312 65.5509C62.4966 65.5622 62.5585 65.5887 62.6119 65.6281C62.658 65.6525 62.6994 65.6848 62.7342 65.7236V65.7236Z"
          fill="#AA6550"
        />
        <path
          d="M61.4316 66.3433C61.4316 66.3433 61.445 66.3902 61.4115 66.4673C61.3855 66.5203 61.3476 66.5665 61.3008 66.6025C61.2541 66.6386 61.1996 66.6634 61.1418 66.675C61.0541 66.6893 60.9641 66.6801 60.8812 66.6483C60.7982 66.6165 60.7251 66.5633 60.6694 66.4941C60.5376 66.3265 60.4747 66.1151 60.4935 65.9027C60.5072 65.692 60.5869 65.491 60.7213 65.3281C60.8306 65.1971 60.9728 65.0977 61.1334 65.04C61.2406 65.0004 61.3543 64.9817 61.4684 64.9847C61.5102 64.982 61.5521 64.9888 61.5907 65.0048C61.443 65.0145 61.2974 65.0455 61.1585 65.097C61.0126 65.1584 60.8842 65.2551 60.785 65.3784C60.6658 65.5305 60.5952 65.715 60.5823 65.9078C60.564 66.0985 60.6178 66.2891 60.733 66.4422C60.7801 66.5013 60.8413 66.5476 60.911 66.5769C60.9806 66.6061 61.0566 66.6174 61.1317 66.6097C61.1831 66.6013 61.2322 66.582 61.2755 66.5531C61.3188 66.5242 61.3555 66.4864 61.383 66.4422C61.4316 66.3869 61.4316 66.3416 61.4316 66.3433Z"
          fill="#AA6550"
        />
        <path
          d="M62.8516 67.9131C62.8516 67.9047 62.9303 67.8946 63.061 67.9298C63.2375 67.9808 63.392 68.0893 63.4999 68.2381L63.4245 68.2531C63.4406 68.1558 63.4629 68.0595 63.4915 67.965C63.578 67.6274 63.7559 67.3202 64.0058 67.0771C63.8305 67.3655 63.6862 67.6715 63.5753 67.9901C63.5468 68.0856 63.5233 68.1811 63.5066 68.2682L63.4865 68.3671L63.4312 68.285C63.3343 68.1482 63.1999 68.0423 63.0442 67.9801C62.9788 67.9612 62.9145 67.9389 62.8516 67.9131V67.9131Z"
          fill="#AA6550"
        />
        <path
          d="M63.0574 64.1707C63.0775 64.1825 62.982 64.3383 62.7826 64.4053C62.5833 64.4723 62.4158 64.4187 62.4241 64.3969C62.4325 64.3751 62.5816 64.3852 62.7592 64.3215C62.9367 64.2579 63.0373 64.154 63.0574 64.1707Z"
          fill="#AA6550"
        />
        <path
          d="M73.6958 47.251C74.7213 46.7441 75.5949 45.9754 76.228 45.0226C76.8611 44.0698 77.2315 42.9667 77.3015 41.8248C77.3715 40.683 77.1388 39.5429 76.6269 38.5199C76.1149 37.4968 75.3419 36.6271 74.386 35.9987C72.0507 34.4642 68.8578 34.4994 66.7705 32.6349C63.2743 29.5006 65.0685 23.2152 62.0029 19.6604C60.1601 17.5279 56.9773 17.027 54.2182 17.6301C50.7371 18.3923 49.6064 21.7712 47.1555 23.4062C44.9007 24.9139 43.2238 24.6525 40.639 23.9439C35.595 22.5569 33.2681 22.9757 30.0752 24.3728C29.2679 24.7732 28.4848 25.2207 27.7299 25.7129C22.4765 29.0466 19.3941 35.6285 20.3858 41.7949C21.4613 48.4957 27.2541 54.1529 33.9684 55.0792C35.868 55.3422 37.9135 55.2937 39.525 56.3323C42.609 58.3224 42.3728 62.9678 44.5422 65.9295C46.2426 68.253 49.34 69.2799 52.2046 68.9968C55.0692 68.7137 57.716 67.3015 59.9909 65.5392C62.1017 63.9025 64.3314 61.8236 66.9866 62.1034C68.2028 62.2307 69.3017 62.8572 70.4224 63.3464C71.5431 63.8355 72.8263 64.2007 73.9789 63.7869C73.9789 63.7869 73.0542 63.4201 72.9436 62.8338C72.5885 60.9341 73.7343 60.656 75.1616 60.656C72.0088 60.3008 69.166 58.5586 67.6232 55.7979C66.6448 54.049 66.2663 51.7188 67.4791 50.1257C68.8729 48.2813 71.6135 48.2796 73.6958 47.251Z"
          fill="url(#paint6_linear_46_21817)"
        />
        <path
          opacity="0.8"
          d="M73.6958 47.251C74.7213 46.7441 75.5949 45.9754 76.228 45.0226C76.8611 44.0698 77.2315 42.9667 77.3015 41.8248C77.3715 40.683 77.1388 39.5429 76.6269 38.5199C76.1149 37.4968 75.3419 36.6271 74.386 35.9987C72.0507 34.4642 68.8578 34.4994 66.7705 32.6349C63.2743 29.5006 65.0685 23.2152 62.0029 19.6604C60.1601 17.5279 56.9773 17.027 54.2182 17.6301C50.7371 18.3923 49.6064 21.7712 47.1555 23.4062C44.9007 24.9139 43.2238 24.6525 40.639 23.9439C35.595 22.5569 33.2681 22.9757 30.0752 24.3728C29.2679 24.7732 28.4848 25.2207 27.7299 25.7129C22.4765 29.0466 19.3941 35.6285 20.3858 41.7949C21.4613 48.4957 27.2541 54.1529 33.9684 55.0792C35.868 55.3422 37.9135 55.2937 39.525 56.3323C42.609 58.3224 42.3728 62.9678 44.5422 65.9295C46.2426 68.253 49.34 69.2799 52.2046 68.9968C55.0692 68.7137 57.716 67.3015 59.9909 65.5392C62.1017 63.9025 64.3314 61.8236 66.9866 62.1034C68.2028 62.2307 69.3017 62.8572 70.4224 63.3464C71.5431 63.8355 72.8263 64.2007 73.9789 63.7869C73.9789 63.7869 73.0542 63.4201 72.9436 62.8338C72.5885 60.9341 73.7343 60.656 75.1616 60.656C72.0088 60.3008 69.166 58.5586 67.6232 55.7979C66.6448 54.049 66.2663 51.7188 67.4791 50.1257C68.8729 48.2813 71.6135 48.2796 73.6958 47.251Z"
          fill={theme === 0 ? "#13161D" : "#ffffff"}
        />
        <path
          d="M69.2446 52.6719C69.1285 53.1919 69.1905 53.7358 69.4205 54.2164C69.6483 54.6864 70.0142 55.0757 70.4692 55.3321C72.2768 56.3724 74.0022 54.8513 74.1547 53.1661C74.1879 52.7156 74.0865 52.2654 73.8632 51.8728C72.7508 49.8642 69.7405 50.4305 69.2446 52.6719Z"
          fill="url(#paint7_linear_46_21817)"
        />
        <path
          opacity="0.8"
          d="M69.2446 52.6719C69.1285 53.1919 69.1905 53.7358 69.4205 54.2164C69.6483 54.6864 70.0142 55.0757 70.4692 55.3321C72.2768 56.3724 74.0022 54.8513 74.1547 53.1661C74.1879 52.7156 74.0865 52.2654 73.8632 51.8728C72.7508 49.8642 69.7405 50.4305 69.2446 52.6719Z"
          fill={theme === 0 ? "#13161D" : "#ffffff"}
        />
        <path
          d="M45.4859 27.8086C45.4692 27.8292 45.4513 27.8487 45.4323 27.8672L45.2648 28.0347L44.6182 28.6646L42.2193 30.9731L42.2277 30.9496C42.3416 33.4624 42.4974 36.8765 42.6699 40.6741V40.7596L42.5979 40.7127L41.7318 40.1331L36.2371 36.4476L36.2254 36.4392V36.4275C35.4046 33.9784 34.7177 31.9145 34.2252 30.4571L33.6657 28.7618C33.602 28.5675 33.5535 28.4267 33.52 28.3128C33.5049 28.2642 33.4932 28.2257 33.4848 28.1956C33.479 28.1829 33.4756 28.1693 33.4747 28.1554L33.4898 28.1939L33.5317 28.3078C33.5685 28.4133 33.6205 28.5624 33.6892 28.7534C33.8248 29.1488 34.0242 29.72 34.2705 30.442C34.773 31.8961 35.4682 33.9599 36.2991 36.4024L36.284 36.3823L41.7837 40.0526L42.6498 40.6323L42.5828 40.6708C42.4153 36.8731 42.2746 33.4674 42.1657 30.9546V30.9412L42.1757 30.9312L44.5947 28.6361L45.2531 28.018L45.4206 27.8505C45.4642 27.8253 45.4859 27.8086 45.4859 27.8086Z"
          fill="url(#paint8_linear_46_21817)"
        />
        <path
          d="M42.9291 39.5334L42.9123 40.1013L43.3696 40.4364L42.8252 40.5955L42.6459 41.1333L42.3277 40.6642L41.7598 40.6609L42.1082 40.2136L41.9357 39.6725L42.4684 39.8651L42.9291 39.5334Z"
          fill="url(#paint9_linear_46_21817)"
        />
        <path
          d="M36.3099 35.687L36.3216 36.0539L36.6299 36.2516L36.2848 36.3755L36.191 36.7307L35.9665 36.4409L35.5996 36.461L35.8057 36.1577L35.6733 35.816L36.0251 35.9182L36.3099 35.687Z"
          fill="url(#paint10_linear_46_21817)"
        />
        <path
          d="M33.4578 27.4937L33.7358 27.8488L34.1848 27.822L33.9318 28.1956L34.096 28.6144L33.6638 28.4887L33.3154 28.7752L33.3003 28.3246L32.9217 28.0816L33.3438 27.9292L33.4578 27.4937Z"
          fill="url(#paint11_linear_46_21817)"
        />
        <path
          d="M45.2096 27.137L45.486 27.4938L45.9366 27.467L45.6836 27.8389L45.8478 28.2593L45.4139 28.1337L45.0672 28.4202L45.0521 27.9695L44.6718 27.7266L45.0956 27.5742L45.2096 27.137Z"
          fill="url(#paint12_linear_46_21817)"
        />
        <path
          d="M42.1249 30.571L42.3025 30.7972L42.589 30.7804L42.4282 31.0183L42.5337 31.2864L42.2573 31.2059L42.0345 31.3885L42.0244 31.1021L41.7832 30.9463L42.0529 30.8491L42.1249 30.571Z"
          fill="url(#paint13_linear_46_21817)"
        />
        <path
          d="M55.0416 43.1367C55.034 43.1746 55.0245 43.2121 55.0131 43.249C54.9879 43.3294 54.9544 43.4349 54.9142 43.5689L54.534 44.7416L53.224 48.7084L53.2039 48.7721L53.1536 48.7269L52.522 48.1456L50.3443 46.1353H50.3945L48.2335 47.7871L47.6187 48.2494L47.4512 48.3684C47.4333 48.3836 47.4136 48.3965 47.3926 48.4069C47.4079 48.3887 47.4253 48.3724 47.4445 48.3583L47.602 48.2293L48.2034 47.7502L50.3443 46.075L50.3711 46.0549L50.3945 46.0767L52.5723 48.0752L53.224 48.6632L53.1536 48.6816L54.4938 44.7298L54.8975 43.5572L55.0097 43.2423C55.0163 43.206 55.027 43.1706 55.0416 43.1367V43.1367Z"
          fill="url(#paint14_linear_46_21817)"
        />
        <path
          d="M53.0248 48.0317L53.2392 48.2797L53.5642 48.2445L53.395 48.5242L53.529 48.8241L53.2091 48.7487L52.9678 48.9682L52.941 48.6415L52.6562 48.479L52.9578 48.3517L53.0248 48.0317Z"
          fill="url(#paint15_linear_46_21817)"
        />
        <path
          d="M47.2702 48.2292L47.3757 48.3532L47.5382 48.3365L47.4528 48.4755L47.5198 48.6229L47.3607 48.5861L47.2401 48.695L47.2283 48.5325L47.0859 48.4521L47.2367 48.3901L47.2702 48.2292Z"
          fill="url(#paint16_linear_46_21817)"
        />
        <path
          d="M54.999 43.0396L55.1062 43.1635L55.267 43.1451L55.1833 43.2841L55.2503 43.4332L55.0911 43.3964L54.9705 43.5053L54.9571 43.3428L54.8164 43.2624L54.9655 43.1987L54.999 43.0396Z"
          fill="url(#paint17_linear_46_21817)"
        />
        <path
          d="M50.3193 45.9158L50.4265 46.0397L50.589 46.0213L50.5036 46.1604L50.5706 46.3094L50.4115 46.2709L50.2908 46.3815L50.2774 46.219L50.1367 46.1369L50.2875 46.0749L50.3193 45.9158Z"
          fill="url(#paint18_linear_46_21817)"
        />
        <path
          d="M57.0582 61.9426C57.0504 61.9708 57.0403 61.9982 57.0281 62.0247L56.9326 62.2592C56.8421 62.4686 56.7165 62.7618 56.5573 63.1421C56.2223 63.906 55.7599 64.9848 55.2038 66.273V66.2915H55.1837L50.9287 66.995L49.5031 67.2279L49.4377 67.2396L49.4545 67.176L50.3758 63.4805L50.3959 63.5207L47.8965 62.4033L47.2064 62.09L47.0271 62.0046C47.006 61.9959 46.9858 61.9853 46.9668 61.9728C46.9888 61.9775 47.0102 61.9849 47.0305 61.9946L47.2147 62.0699L47.9116 62.3665L50.4244 63.457L50.4512 63.4687V63.4972C50.1849 64.5693 49.8699 65.8291 49.5299 67.1994L49.4796 67.1458L50.9069 66.913L55.1619 66.2194L55.1334 66.2429C55.703 64.9597 56.1787 63.8842 56.5155 63.1236L56.9091 62.2492L57.0147 62.0197C57.0253 61.992 57.04 61.966 57.0582 61.9426V61.9426Z"
          fill="url(#paint19_linear_46_21817)"
        />
        <path
          d="M49.9919 66.7287L49.8763 67.0353L50.0623 67.305L49.7356 67.2882L49.5363 67.5496L49.4508 67.233L49.1426 67.1241L49.4156 66.9448L49.424 66.6182L49.6786 66.8225L49.9919 66.7287Z"
          fill="url(#paint20_linear_46_21817)"
        />
        <path
          d="M47.0723 61.7665L47.0136 61.9189L47.1058 62.0529L46.9433 62.0446L46.8444 62.1735L46.8026 62.0161L46.6484 61.9625L46.7841 61.8737L46.7892 61.7112L46.9148 61.8134L47.0723 61.7665Z"
          fill="url(#paint21_linear_46_21817)"
        />
        <path
          d="M55.3223 66.0819L55.2636 66.2343L55.3574 66.3684L55.1933 66.36L55.0961 66.489L55.0526 66.3315L54.8984 66.2779L55.0358 66.1891L55.0392 66.0266L55.1665 66.1288L55.3223 66.0819Z"
          fill="url(#paint22_linear_46_21817)"
        />
        <path
          d="M50.5625 63.3412L50.5039 63.4936L50.596 63.6276L50.4335 63.6193L50.3347 63.7499L50.2928 63.5924L50.1387 63.5388L50.276 63.4484L50.2794 63.2859L50.4067 63.3881L50.5625 63.3412Z"
          fill="url(#paint23_linear_46_21817)"
        />
        <path
          d="M57.2559 61.7901L57.1972 61.9426L57.2894 62.0766L57.1269 62.0682L57.028 62.1972L56.9862 62.0414L56.832 61.9861L56.9677 61.8974L56.9728 61.7349L57.0984 61.837L57.2559 61.7901Z"
          fill="url(#paint24_linear_46_21817)"
        />
        <path
          d="M53.7564 29.3949C54.7371 29.3949 55.5321 28.5999 55.5321 27.6192C55.5321 26.6385 54.7371 25.8435 53.7564 25.8435C52.7757 25.8435 51.9807 26.6385 51.9807 27.6192C51.9807 28.5999 52.7757 29.3949 53.7564 29.3949Z"
          fill="url(#paint25_linear_46_21817)"
        />
        <path
          d="M33.2999 48.3669C33.8153 45.2966 31.7442 42.3898 28.6739 41.8743C25.6036 41.3589 22.6968 43.43 22.1813 46.5003C21.6659 49.5706 23.737 52.4774 26.8073 52.9929C29.8776 53.5083 32.7844 51.4372 33.2999 48.3669Z"
          fill="url(#paint26_linear_46_21817)"
        />
        <g opacity="0.3">
          <path
            d="M27.0073 49.191C27.0077 49.3729 26.9541 49.5508 26.8533 49.7022C26.7525 49.8536 26.6091 49.9718 26.4412 50.0417C26.2732 50.1116 26.0883 50.1301 25.9099 50.095C25.7314 50.0598 25.5674 49.9725 25.4385 49.8441C25.3097 49.7157 25.2218 49.552 25.186 49.3737C25.1502 49.1954 25.168 49.0104 25.2373 48.8422C25.3066 48.6741 25.4243 48.5302 25.5753 48.4289C25.7264 48.3275 25.9041 48.2733 26.086 48.273C26.2068 48.2727 26.3264 48.2963 26.4381 48.3423C26.5497 48.3883 26.6512 48.4559 26.7368 48.5411C26.8223 48.6264 26.8903 48.7276 26.9367 48.8391C26.9831 48.9506 27.0071 49.0702 27.0073 49.191V49.191Z"
            fill={theme === 0 ? "#13161D" : "#ffffff"}
          />
        </g>
        <g opacity="0.3">
          <path
            d="M25.7013 46.539C25.701 46.6446 25.6694 46.7478 25.6105 46.8354C25.5516 46.9231 25.468 46.9913 25.3703 47.0315C25.2726 47.0717 25.1652 47.082 25.0617 47.0612C24.9581 47.0404 24.8631 46.9894 24.7885 46.9146C24.7139 46.8398 24.6632 46.7446 24.6427 46.641C24.6222 46.5373 24.6329 46.43 24.6734 46.3324C24.7139 46.2349 24.7824 46.1515 24.8702 46.0929C24.9581 46.0342 25.0613 46.0029 25.167 46.0029C25.2373 46.0029 25.3069 46.0168 25.3718 46.0438C25.4368 46.0707 25.4958 46.1102 25.5454 46.16C25.5951 46.2098 25.6344 46.2689 25.6611 46.334C25.6879 46.399 25.7016 46.4687 25.7013 46.539V46.539Z"
            fill={theme === 0 ? "#13161D" : "#ffffff"}
          />
        </g>
        <path
          d="M22.271 48.8023C22.2961 48.9011 17.4464 53.5532 18.6961 54.5449C19.9458 55.5366 25.2042 52.9937 29.6871 49.3719C34.1699 45.7501 38.2658 41.3594 37.1417 40.1867C36.0177 39.0141 30.4192 42.4684 30.4192 42.4684L31.0892 42.8955C31.0892 42.8955 34.4765 40.7178 35.5486 41.5654C36.6208 42.4131 33.5669 45.7149 29.2566 48.8559C24.9463 51.9969 20.7817 54.2166 20.1351 53.3488C19.4885 52.4811 22.539 49.6114 22.539 49.6114L22.271 48.8023Z"
          fill="url(#paint27_linear_46_21817)"
        />
        <g opacity="0.3">
          <path
            d="M22.271 48.8023C22.2961 48.9011 17.4464 53.5532 18.6961 54.5449C19.9458 55.5366 25.2042 52.9937 29.6871 49.3719C34.1699 45.7501 38.2658 41.3594 37.1417 40.1867C36.0177 39.0141 30.4192 42.4684 30.4192 42.4684L31.0892 42.8955C31.0892 42.8955 34.4765 40.7178 35.5486 41.5654C36.6208 42.4131 33.5669 45.7149 29.2566 48.8559C24.9463 51.9969 20.7817 54.2166 20.1351 53.3488C19.4885 52.4811 22.539 49.6114 22.539 49.6114L22.271 48.8023Z"
            fill={theme === 1 ? "#14171D" : "#191D24"}
          />
        </g>
        <g opacity="0.3">
          <path
            d="M24.634 43.4618C24.6792 43.5154 24.2989 43.8404 23.8651 44.4903C23.2931 45.3253 22.9776 46.3093 22.9574 47.3212C22.9372 48.3331 23.2131 49.3288 23.7512 50.186C24.1582 50.8561 24.5268 51.1911 24.4782 51.2464C24.4296 51.3017 23.9974 51.0236 23.4948 50.3586C23.2045 49.9678 22.9742 49.5358 22.8114 49.077C22.3967 47.9364 22.4218 46.6822 22.8817 45.5591C23.0624 45.1067 23.3097 44.6837 23.6155 44.3044C24.1431 43.6645 24.6072 43.4216 24.634 43.4618Z"
            fill={theme === 0 ? "#13161D" : "#ffffff"}
          />
        </g>
        <path
          d="M32.9803 59.6492L33.054 59.7849C33.1366 59.9363 33.2611 60.0607 33.4125 60.1434L33.5465 60.2171L33.4125 60.2908C33.2609 60.3724 33.1363 60.4964 33.054 60.6476L32.9803 60.7833L32.9066 60.6476C32.8234 60.497 32.699 60.3733 32.5481 60.2908L32.4141 60.2171L32.5481 60.1434C32.6987 60.0597 32.823 59.9355 32.9066 59.7849L32.9803 59.6492Z"
          fill="url(#paint28_linear_46_21817)"
        />
        <path
          d="M57.2302 21.4512L57.2888 21.5617C57.3565 21.6835 57.4569 21.7839 57.5787 21.8515L57.6892 21.9119L57.5787 21.9722C57.4567 22.0395 57.3562 22.14 57.2888 22.262L57.2302 22.3709L57.1699 22.262C57.1033 22.1384 57.002 22.0371 56.8784 21.9705L56.7695 21.9102L56.8784 21.8499C57.0016 21.7835 57.1028 21.6828 57.1699 21.5601L57.2302 21.4512Z"
          fill="url(#paint29_linear_46_21817)"
        />
        <path
          d="M27.7031 34.2446L27.8237 34.444C27.959 34.6701 28.1568 34.8522 28.3933 34.9683L28.6027 35.0722L28.4017 35.1928C28.1764 35.329 27.9945 35.5266 27.8773 35.7624L27.7735 35.9734L27.6529 35.7724C27.5186 35.5479 27.3234 35.3661 27.09 35.2481L26.8789 35.1442L27.0799 35.0236C27.3056 34.8879 27.4876 34.6902 27.6043 34.454L27.7031 34.2446Z"
          fill="url(#paint30_linear_46_21817)"
        />
        <path
          d="M55.9171 37.9805L56.0377 38.1815C56.1728 38.4071 56.3699 38.5892 56.6056 38.7058L56.8167 38.8097L56.6157 38.9303C56.39 39.066 56.208 39.2637 56.0913 39.4999L55.9875 39.711L55.8669 39.5099C55.7316 39.2839 55.5338 39.1018 55.2973 38.9856L55.0879 38.8817L55.2889 38.7611C55.5141 38.6253 55.6955 38.4276 55.8116 38.1915L55.9171 37.9805Z"
          fill="url(#paint31_linear_46_21817)"
        />
        <path
          d="M64.906 58.4714L65.0299 58.7763C65.1657 59.002 65.3633 59.184 65.5995 59.3007L65.8089 59.4045L65.6079 59.5251C65.3822 59.6609 65.2002 59.8586 65.0835 60.0947L64.9797 60.3828L64.859 60.1031C64.7228 59.8778 64.5253 59.6959 64.2895 59.5787L64.0801 59.4749L64.2811 59.3543C64.5059 59.2182 64.6877 59.0213 64.8054 58.7864L64.906 58.4714Z"
          fill="url(#paint32_linear_46_21817)"
        />
        <path
          d="M62.6509 55.4695L62.7045 55.6035C62.764 55.7019 62.8507 55.781 62.9541 55.8313L63.0446 55.8782L62.9575 55.9302C62.859 55.9901 62.7794 56.0766 62.728 56.1798L62.6827 56.3054L62.6308 56.1831C62.5709 56.0846 62.4844 56.0051 62.3812 55.9536L62.2891 55.9084L62.3779 55.8565C62.4763 55.797 62.5554 55.7103 62.6057 55.6069L62.6509 55.4695Z"
          fill="url(#paint33_linear_46_21817)"
        />
        <path
          d="M41.8937 51.1106L42.0093 51.1575C42.1394 51.21 42.2819 51.224 42.4198 51.1977L42.542 51.1743L42.4951 51.2898C42.4427 51.42 42.4287 51.5624 42.4549 51.7003L42.4784 51.8226L42.3628 51.7756C42.2325 51.724 42.0902 51.7101 41.9524 51.7354L41.8301 51.7589L41.877 51.6433C41.9287 51.5136 41.9426 51.3719 41.9172 51.2346L41.8937 51.1106Z"
          fill="url(#paint34_linear_46_21817)"
        />
        <path
          d="M74.1909 43.2021L74.2478 43.2256C74.3125 43.2522 74.3836 43.2592 74.4522 43.2457L74.5142 43.234L74.4907 43.2926C74.4641 43.3573 74.4572 43.4284 74.4706 43.497L74.4823 43.559L74.4237 43.5355C74.3591 43.5088 74.288 43.5018 74.2193 43.5154L74.1641 43.5372L74.1875 43.4786C74.2128 43.4135 74.2192 43.3427 74.2059 43.2742L74.1909 43.2021Z"
          fill="url(#paint35_linear_46_21817)"
        />
        <path
          d="M52.6256 59.8184L52.6842 59.8418C52.7491 59.8676 52.8199 59.8746 52.8886 59.8619L52.9506 59.8502L52.9271 59.9071C52.9005 59.9724 52.8935 60.044 52.907 60.1132L52.9187 60.1735L52.8601 60.1501C52.795 60.1251 52.7244 60.1181 52.6557 60.1299L52.5938 60.1417L52.6172 60.0847C52.6438 60.02 52.6508 59.949 52.6373 59.8803L52.6256 59.8184Z"
          fill="url(#paint36_linear_46_21817)"
        />
        <path
          d="M34.8072 49.1089L34.8659 49.1307C34.9305 49.1573 35.0016 49.1643 35.0702 49.1508L35.1305 49.139L35.1088 49.1977C35.0822 49.2624 35.0752 49.3334 35.0887 49.402L35.1004 49.464L35.0417 49.4406C34.9771 49.414 34.906 49.407 34.8374 49.4205L34.7754 49.4322L34.7988 49.3736C34.8255 49.3089 34.8325 49.2378 34.8189 49.1692L34.8072 49.1089Z"
          fill="url(#paint37_linear_46_21817)"
        />
        <path
          d="M38.876 63.6228L38.9463 63.6513C39.0267 63.683 39.1143 63.6917 39.1993 63.6764L39.2747 63.6613L39.2462 63.7334C39.2129 63.8127 39.2041 63.9003 39.2211 63.9846L39.2345 64.06L39.1641 64.0316C39.0837 63.9999 38.9962 63.9912 38.9111 64.0064L38.8358 64.0215L38.8659 63.9495C38.8979 63.8697 38.906 63.7825 38.8894 63.6982L38.876 63.6228Z"
          fill="url(#paint38_linear_46_21817)"
        />
        <path
          d="M48.817 36.699L48.8756 36.7224C48.9403 36.749 49.0114 36.756 49.08 36.7425L49.142 36.7308L49.1185 36.7894C49.0919 36.8541 49.0849 36.9252 49.0984 36.9938L49.1101 37.0558L49.0515 37.0323C48.9868 37.0058 48.9158 36.9988 48.8471 37.0122L48.7852 37.024L48.8086 36.9653C48.8352 36.9007 48.8422 36.8296 48.8287 36.761L48.817 36.699Z"
          fill="url(#paint39_linear_46_21817)"
        />
        <path
          d="M60.5279 24.3291L60.5849 24.3526C60.6505 24.3778 60.7219 24.3842 60.7909 24.371L60.8512 24.3609L60.8278 24.4179C60.8028 24.483 60.7959 24.5536 60.8077 24.6223L60.8194 24.6842L60.7625 24.6608C60.6972 24.6341 60.6256 24.6271 60.5564 24.6407L60.4961 24.6524L60.5195 24.5938C60.5455 24.5289 60.5524 24.4581 60.5397 24.3894L60.5279 24.3291Z"
          fill="url(#paint40_linear_46_21817)"
        />
        <path
          d="M24.9322 30.2375L24.9909 30.261C25.0555 30.2876 25.1266 30.2946 25.1952 30.2811L25.2572 30.2694L25.2338 30.328C25.2071 30.3927 25.2001 30.4638 25.2137 30.5324L25.2254 30.5927L25.1667 30.5709C25.1021 30.5443 25.031 30.5373 24.9624 30.5508L24.9004 30.5625L24.9238 30.5039C24.9504 30.4392 24.9574 30.3682 24.9439 30.2995L24.9322 30.2375Z"
          fill="url(#paint41_linear_46_21817)"
        />
        <path
          d="M55.5399 52.0505L55.5986 52.074C55.6632 52.1006 55.7343 52.1076 55.8029 52.0941L55.8649 52.0824L55.8415 52.141C55.8148 52.2057 55.8078 52.2768 55.8214 52.3454L55.8331 52.4057L55.7744 52.3839C55.7098 52.3572 55.6387 52.3502 55.5701 52.3638L55.5098 52.3738L55.5315 52.3169C55.5581 52.2522 55.5651 52.1811 55.5516 52.1125L55.5399 52.0505Z"
          fill="url(#paint42_linear_46_21817)"
        />
        <path
          d="M65.0442 45.359C67.3778 45.3044 69.2253 43.3684 69.1707 41.0348C69.1161 38.7012 67.1801 36.8537 64.8465 36.9082C62.5129 36.9628 60.6653 38.8988 60.7199 41.2325C60.7745 43.5661 62.7105 45.4136 65.0442 45.359Z"
          fill="url(#paint43_linear_46_21817)"
        />
        <g opacity="0.4">
          <path
            d="M66.3224 39.5166C66.3255 39.3799 66.369 39.2473 66.4476 39.1354C66.5261 39.0235 66.6361 38.9375 66.7637 38.8882C66.8912 38.8389 67.0304 38.8286 67.1638 38.8586C67.2972 38.8886 67.4186 38.9575 67.5128 39.0566C67.607 39.1557 67.6696 39.2805 67.6927 39.4152C67.7158 39.55 67.6984 39.6885 67.6427 39.8134C67.5869 39.9382 67.4954 40.0436 67.3796 40.1164C67.2639 40.1891 67.1292 40.2258 66.9925 40.2219C66.8103 40.2166 66.6376 40.1395 66.5121 40.0074C66.3866 39.8752 66.3184 39.6988 66.3224 39.5166Z"
            fill={theme === 1 ? "#14171D" : "#191D24"}
          />
        </g>
        <g opacity="0.4">
          <path
            d="M67.7013 41.1248C67.7033 41.0457 67.7287 40.9689 67.7744 40.9042C67.82 40.8395 67.8838 40.7898 67.9577 40.7613C68.0316 40.7329 68.1123 40.727 68.1895 40.7444C68.2668 40.7618 68.3371 40.8017 68.3917 40.8591C68.4462 40.9165 68.4826 40.9887 68.4961 41.0667C68.5096 41.1448 68.4996 41.225 68.4675 41.2974C68.4354 41.3698 68.3825 41.431 68.3156 41.4733C68.2487 41.5157 68.1708 41.5372 68.0916 41.5352C68.039 41.5339 67.9872 41.5223 67.9392 41.501C67.8911 41.4796 67.8477 41.449 67.8114 41.4109C67.7752 41.3728 67.7468 41.3279 67.7279 41.2788C67.709 41.2297 67.7 41.1774 67.7013 41.1248V41.1248Z"
            fill={theme === 1 ? "#14171D" : "#191D24"}
          />
        </g>
        <g opacity="0.4">
          <path
            d="M67.1622 42.3643C67.1913 42.3653 67.2194 42.3749 67.2431 42.3919C67.2667 42.4088 67.2848 42.4323 67.2951 42.4595C67.3054 42.4868 67.3074 42.5164 67.3009 42.5447C67.2943 42.5731 67.2796 42.5988 67.2584 42.6188C67.2373 42.6388 67.2107 42.6521 67.1821 42.657C67.1534 42.6619 67.1239 42.6582 67.0974 42.6464C67.0708 42.6346 67.0483 42.6152 67.0327 42.5907C67.0171 42.5661 67.0092 42.5375 67.0098 42.5084C67.0102 42.4889 67.0145 42.4697 67.0225 42.4518C67.0304 42.434 67.0418 42.4179 67.0559 42.4045C67.0701 42.3911 67.0868 42.3807 67.1051 42.3738C67.1233 42.3669 67.1427 42.3637 67.1622 42.3643V42.3643Z"
            fill={theme === 1 ? "#14171D" : "#191D24"}
          />
        </g>
        <path
          d="M58.1876 32.0938H68.6694C68.6694 32.0938 67.7413 30.4068 65.0442 30.6514C62.4678 30.886 62.3371 29.8138 61.0237 29.6915C59.7104 29.5692 58.6248 30.5643 58.1876 32.0938Z"
          fill={theme === 0 ? "#13161D" : "#ffffff"}
        />
        <path
          d="M44.6406 57.8217H57.8044C57.8044 57.8217 56.6401 55.7025 53.2528 56.0108C50.0163 56.3056 49.8522 54.9688 48.2071 54.8046C46.5621 54.6404 45.1834 55.9019 44.6406 57.8217Z"
          fill={theme === 0 ? "#13161D" : "#ffffff"}
        />
        <path
          d="M56.9296 50.8158H63.0575C63.0575 50.8158 62.5164 49.8307 60.9384 49.9782C59.4307 50.1155 59.3553 49.494 58.5931 49.417C57.8309 49.3399 57.1859 49.9229 56.9296 50.8158Z"
          fill={theme === 0 ? "#13161D" : "#ffffff"}
        />
        <defs>
          <linearGradient
            id="paint0_linear_46_21817"
            x1="43.0996"
            y1="85.5631"
            x2="45.681"
            y2="85.5631"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#26A7CF" />
            <stop offset="1" stopColor="#27AF8F" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_46_21817"
            x1="44.0782"
            y1="89.6847"
            x2="47.6714"
            y2="89.6847"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#26A7CF" />
            <stop offset="1" stopColor="#27AF8F" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_46_21817"
            x1="38.5409"
            y1="86.2203"
            x2="42.8559"
            y2="86.2203"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#26A7CF" />
            <stop offset="1" stopColor="#27AF8F" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_46_21817"
            x1="74.8398"
            y1="63.8942"
            x2="83.1103"
            y2="63.8942"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#26A7CF" />
            <stop offset="1" stopColor="#27AF8F" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_46_21817"
            x1="72.7188"
            y1="63.8942"
            x2="80.9892"
            y2="63.8942"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#26A7CF" />
            <stop offset="1" stopColor="#27AF8F" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_46_21817"
            x1="64.9492"
            y1="92.4147"
            x2="92.3086"
            y2="92.4147"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#26A7CF" />
            <stop offset="1" stopColor="#27AF8F" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_46_21817"
            x1="20.2012"
            y1="69.0427"
            x2="77.3137"
            y2="69.0427"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#26A7CF" />
            <stop offset="1" stopColor="#27AF8F" />
          </linearGradient>
          <linearGradient
            id="paint7_linear_46_21817"
            x1="69.1875"
            y1="55.6726"
            x2="74.1609"
            y2="55.6726"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#26A7CF" />
            <stop offset="1" stopColor="#27AF8F" />
          </linearGradient>
          <linearGradient
            id="paint8_linear_46_21817"
            x1="33.4747"
            y1="40.7596"
            x2="45.4859"
            y2="40.7596"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#26A7CF" />
            <stop offset="1" stopColor="#27AF8F" />
          </linearGradient>
          <linearGradient
            id="paint9_linear_46_21817"
            x1="41.7598"
            y1="41.1333"
            x2="43.3696"
            y2="41.1333"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#26A7CF" />
            <stop offset="1" stopColor="#27AF8F" />
          </linearGradient>
          <linearGradient
            id="paint10_linear_46_21817"
            x1="35.5996"
            y1="36.7307"
            x2="36.6299"
            y2="36.7307"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#26A7CF" />
            <stop offset="1" stopColor="#27AF8F" />
          </linearGradient>
          <linearGradient
            id="paint11_linear_46_21817"
            x1="32.9217"
            y1="28.7752"
            x2="34.1848"
            y2="28.7752"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#26A7CF" />
            <stop offset="1" stopColor="#27AF8F" />
          </linearGradient>
          <linearGradient
            id="paint12_linear_46_21817"
            x1="44.6718"
            y1="28.4202"
            x2="45.9366"
            y2="28.4202"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#26A7CF" />
            <stop offset="1" stopColor="#27AF8F" />
          </linearGradient>
          <linearGradient
            id="paint13_linear_46_21817"
            x1="41.7832"
            y1="31.3885"
            x2="42.589"
            y2="31.3885"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#26A7CF" />
            <stop offset="1" stopColor="#27AF8F" />
          </linearGradient>
          <linearGradient
            id="paint14_linear_46_21817"
            x1="47.3926"
            y1="48.7721"
            x2="55.0416"
            y2="48.7721"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#26A7CF" />
            <stop offset="1" stopColor="#27AF8F" />
          </linearGradient>
          <linearGradient
            id="paint15_linear_46_21817"
            x1="52.6562"
            y1="48.9682"
            x2="53.5642"
            y2="48.9682"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#26A7CF" />
            <stop offset="1" stopColor="#27AF8F" />
          </linearGradient>
          <linearGradient
            id="paint16_linear_46_21817"
            x1="47.0859"
            y1="48.695"
            x2="47.5382"
            y2="48.695"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#26A7CF" />
            <stop offset="1" stopColor="#27AF8F" />
          </linearGradient>
          <linearGradient
            id="paint17_linear_46_21817"
            x1="54.8164"
            y1="43.5053"
            x2="55.267"
            y2="43.5053"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#26A7CF" />
            <stop offset="1" stopColor="#27AF8F" />
          </linearGradient>
          <linearGradient
            id="paint18_linear_46_21817"
            x1="50.1367"
            y1="46.3815"
            x2="50.589"
            y2="46.3815"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#26A7CF" />
            <stop offset="1" stopColor="#27AF8F" />
          </linearGradient>
          <linearGradient
            id="paint19_linear_46_21817"
            x1="46.9668"
            y1="67.2396"
            x2="57.0582"
            y2="67.2396"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#26A7CF" />
            <stop offset="1" stopColor="#27AF8F" />
          </linearGradient>
          <linearGradient
            id="paint20_linear_46_21817"
            x1="49.1426"
            y1="67.5496"
            x2="50.0623"
            y2="67.5496"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#26A7CF" />
            <stop offset="1" stopColor="#27AF8F" />
          </linearGradient>
          <linearGradient
            id="paint21_linear_46_21817"
            x1="46.6484"
            y1="62.1735"
            x2="47.1058"
            y2="62.1735"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#26A7CF" />
            <stop offset="1" stopColor="#27AF8F" />
          </linearGradient>
          <linearGradient
            id="paint22_linear_46_21817"
            x1="54.8984"
            y1="66.489"
            x2="55.3574"
            y2="66.489"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#26A7CF" />
            <stop offset="1" stopColor="#27AF8F" />
          </linearGradient>
          <linearGradient
            id="paint23_linear_46_21817"
            x1="50.1387"
            y1="63.7499"
            x2="50.596"
            y2="63.7499"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#26A7CF" />
            <stop offset="1" stopColor="#27AF8F" />
          </linearGradient>
          <linearGradient
            id="paint24_linear_46_21817"
            x1="56.832"
            y1="62.1972"
            x2="57.2894"
            y2="62.1972"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#26A7CF" />
            <stop offset="1" stopColor="#27AF8F" />
          </linearGradient>
          <linearGradient
            id="paint25_linear_46_21817"
            x1="51.9807"
            y1="29.3949"
            x2="55.5321"
            y2="29.3949"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#26A7CF" />
            <stop offset="1" stopColor="#27AF8F" />
          </linearGradient>
          <linearGradient
            id="paint26_linear_46_21817"
            x1="32.3666"
            y1="53.9262"
            x2="34.2331"
            y2="42.8076"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#26A7CF" />
            <stop offset="1" stopColor="#27AF8F" />
          </linearGradient>
          <linearGradient
            id="paint27_linear_46_21817"
            x1="18.4902"
            y1="54.7609"
            x2="37.3298"
            y2="54.7609"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#26A7CF" />
            <stop offset="1" stopColor="#27AF8F" />
          </linearGradient>
          <linearGradient
            id="paint28_linear_46_21817"
            x1="32.4141"
            y1="60.7833"
            x2="33.5465"
            y2="60.7833"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#26A7CF" />
            <stop offset="1" stopColor="#27AF8F" />
          </linearGradient>
          <linearGradient
            id="paint29_linear_46_21817"
            x1="56.7695"
            y1="22.3709"
            x2="57.6892"
            y2="22.3709"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#26A7CF" />
            <stop offset="1" stopColor="#27AF8F" />
          </linearGradient>
          <linearGradient
            id="paint30_linear_46_21817"
            x1="26.8789"
            y1="35.9734"
            x2="28.6027"
            y2="35.9734"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#26A7CF" />
            <stop offset="1" stopColor="#27AF8F" />
          </linearGradient>
          <linearGradient
            id="paint31_linear_46_21817"
            x1="55.0879"
            y1="39.711"
            x2="56.8167"
            y2="39.711"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#26A7CF" />
            <stop offset="1" stopColor="#27AF8F" />
          </linearGradient>
          <linearGradient
            id="paint32_linear_46_21817"
            x1="64.0801"
            y1="60.3828"
            x2="65.8089"
            y2="60.3828"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#26A7CF" />
            <stop offset="1" stopColor="#27AF8F" />
          </linearGradient>
          <linearGradient
            id="paint33_linear_46_21817"
            x1="62.2891"
            y1="56.3054"
            x2="63.0446"
            y2="56.3054"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#26A7CF" />
            <stop offset="1" stopColor="#27AF8F" />
          </linearGradient>
          <linearGradient
            id="paint34_linear_46_21817"
            x1="41.8301"
            y1="51.8226"
            x2="42.542"
            y2="51.8226"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#26A7CF" />
            <stop offset="1" stopColor="#27AF8F" />
          </linearGradient>
          <linearGradient
            id="paint35_linear_46_21817"
            x1="74.1641"
            y1="43.559"
            x2="74.5142"
            y2="43.559"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#26A7CF" />
            <stop offset="1" stopColor="#27AF8F" />
          </linearGradient>
          <linearGradient
            id="paint36_linear_46_21817"
            x1="52.5938"
            y1="60.1735"
            x2="52.9506"
            y2="60.1735"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#26A7CF" />
            <stop offset="1" stopColor="#27AF8F" />
          </linearGradient>
          <linearGradient
            id="paint37_linear_46_21817"
            x1="34.7754"
            y1="49.464"
            x2="35.1305"
            y2="49.464"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#26A7CF" />
            <stop offset="1" stopColor="#27AF8F" />
          </linearGradient>
          <linearGradient
            id="paint38_linear_46_21817"
            x1="38.8358"
            y1="64.06"
            x2="39.2747"
            y2="64.06"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#26A7CF" />
            <stop offset="1" stopColor="#27AF8F" />
          </linearGradient>
          <linearGradient
            id="paint39_linear_46_21817"
            x1="48.7852"
            y1="37.0558"
            x2="49.142"
            y2="37.0558"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#26A7CF" />
            <stop offset="1" stopColor="#27AF8F" />
          </linearGradient>
          <linearGradient
            id="paint40_linear_46_21817"
            x1="60.4961"
            y1="24.6842"
            x2="60.8512"
            y2="24.6842"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#26A7CF" />
            <stop offset="1" stopColor="#27AF8F" />
          </linearGradient>
          <linearGradient
            id="paint41_linear_46_21817"
            x1="24.9004"
            y1="30.5927"
            x2="25.2572"
            y2="30.5927"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#26A7CF" />
            <stop offset="1" stopColor="#27AF8F" />
          </linearGradient>
          <linearGradient
            id="paint42_linear_46_21817"
            x1="55.5098"
            y1="52.4057"
            x2="55.8649"
            y2="52.4057"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#26A7CF" />
            <stop offset="1" stopColor="#27AF8F" />
          </linearGradient>
          <linearGradient
            id="paint43_linear_46_21817"
            x1="60.8188"
            y1="45.4578"
            x2="69.2695"
            y2="45.2602"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#26A7CF" />
            <stop offset="1" stopColor="#27AF8F" />
          </linearGradient>
        </defs>
      </svg>
    </>
  );
};
export default ARVRSolutions;
