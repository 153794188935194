import React from "react";
import styled from "styled-components";
import { SquircleOutlined2 } from "../../../utils/Container";
import RightArrow from "../svg/common/RightArrow";
import { IconContainer, IInputProps, InputContainer } from "./A7AInputField";

const SelectionField = styled.select`
  height: 50px;
  background: rgba(255, 255, 255, 0.01);
  border: 1px solid ${(props) => props.theme.colors.borderColor2} !important;
  color: ${(props) => props.theme.colors.textColors[0]};

  @supports (-webkit-backdrop-filter: blur(1px)) {
    border-radius: 10px;
  }
  ::placeholder {
    font-family: "Lato", sans-serif;
  }
  border-radius: 10px;
  @-moz-document url-prefix() {
    border-radius: 10px;
  }
  border: none;
  width: 100%;
  padding: 13px 15px 13px 41px;
  appearance: none;
`;

const DropDownArrowContainer = styled.div`
  position: absolute;
  right: 22px;
  transition: all 0.3s ease;
  transform: rotate(90deg);
`;

interface ISelectionInputProps extends Omit<IInputProps, "onChange"> {
  option: string[];
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}
const A7ASelectionInput = ({
  id,
  onChange,
  value,
  icon,
  placeholder,
  option,
}: ISelectionInputProps) => {
  return (
    <>
      <InputContainer>
        <IconContainer>{icon}</IconContainer>
        <SelectionField id={id} value={value} onChange={onChange}>
          <option disabled selected value="">
            {placeholder}
          </option>
          {option.map((i, j) => (
            <React.Fragment key={j}>
              <option value={i}>{i}</option>
            </React.Fragment>
          ))}
        </SelectionField>
        <DropDownArrowContainer>
          <RightArrow color="#a9b6bd" />
        </DropDownArrowContainer>
      </InputContainer>
    </>
  );
};

export default A7ASelectionInput;
