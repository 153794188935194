import { useTranslation } from 'next-i18next'
import styled from 'styled-components'
import A7ACarousel from '../../common/carousel'
import Comma from '../../common/svg/landing/faqs/Comma'
import SectionWrapper from '../../common/ui/SectionWrapper'

const CommaContainer = styled.div`
  position: absolute;
  top: 30px;
  right: 10%;
  z-index: -1;
  ${(props) => props.theme.mediaQuery.tablet} {
    top: 60px;
    right: 20%;
  }
`

const FAQ = () => {
  const { t } = useTranslation('landing')

  return (
    <SectionWrapper
      id="faq"
      heading={t('faq.0.faqTitle')}
      description={t('faq.1.faqDescription') as string}
      styles={{ position: 'relative', zIndex: 2 }}
    >
      <CommaContainer>
        <Comma />
      </CommaContainer>
      <A7ACarousel items={t(`faq.2.faqList`, { returnObjects: true })}/>
    </SectionWrapper>
  )
}

export default FAQ
