import { SVGProps } from "react";

const Email = (props: SVGProps<SVGSVGElement>) => {
  return (
    <>
      <svg
        width={19}
        height={19}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M9.5 17.098h3.75v-1.5H9.5c-3.255 0-6-2.745-6-6s2.745-6 6-6 6 2.745 6 6v1.072c0 .593-.533 1.178-1.125 1.178s-1.125-.585-1.125-1.178V9.598c0-2.07-1.68-3.75-3.75-3.75-2.07 0-3.75 1.68-3.75 3.75a3.751 3.751 0 0 0 6.405 2.647c.488.668 1.328 1.103 2.22 1.103 1.477 0 2.625-1.2 2.625-2.678V9.598c0-4.14-3.36-7.5-7.5-7.5-4.14 0-7.5 3.36-7.5 7.5 0 4.14 3.36 7.5 7.5 7.5Zm0-5.25a2.247 2.247 0 0 1-2.25-2.25 2.247 2.247 0 0 1 2.25-2.25 2.247 2.247 0 0 1 2.25 2.25 2.247 2.247 0 0 1-2.25 2.25Z"
          fill="#A9B6BD"
        />
      </svg>
    </>
  );
};

export default Email;
