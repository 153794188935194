import styled from "styled-components";
import { A7ACenteredColumn } from "../../../utils/Container";

const CarouselCardWrapper = styled(A7ACenteredColumn)<{ isSelected: boolean }>`
  width: calc(100vw - 30px);
  h1 {
    margin: ${props => props.theme.constrainedMargin};
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    max-width: 100%;
    height: auto;
    color: ${(props) => props.theme.colors.textColors[0]};
    background: ${(props) =>
      props.isSelected ? props.theme.colors.accent : "inital"};
    -webkit-background-clip: ${(props) =>
      props.isSelected ? "text" : "inital"};
    -webkit-text-fill-color: ${(props) =>
      props.isSelected ? "transparent" : "inital"};
    background-clip: text;
    ${(props) => props.theme.mediaQuery.tablet} {
      margin:0px;
      width: 80%;
      font-size: 24px;
      min-height:48px;
      line-height: 24px;
      text-align:center;
    }
  }
  p {
    margin: 0px;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: ${(props) => props.theme.colors.textColors[0]};
    ${(props) => props.theme.mediaQuery.tablet} {
      font-size: 16px;
      line-height: 19px;
    }
  }
  ${(props) => props.theme.mediaQuery.tablet} {
    display: flex;
    min-width: 120px;
    width: calc(50vw - 30px);
    ${(props) => props.theme.mediaQuery.desktop} {
      width: 544px;
    }
  }
`;

const FAQCarouselDescription = styled.div<{ isSelected: boolean }>`
  background-color: ${(props) =>
    props.isSelected ? props.theme.colors.secondary3 : "transparent"};
  position: relative;
  padding: 20px;
  margin-top: 34px;
  border-radius: ${(props) => props.theme.borderRadius};
  border: 1px solid ${(props) => props.theme.colors.borderColor2};
  ${(props) => props.theme.mediaQuery.tablet} {
    margin-top: 44px;
    padding: 30px;
  }
`;

const FCardSquarePointerUnselected = styled.div`
  position: absolute;
  z-index: 1;
  top: -13px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  width: 24px;
`;

const OutLinedTriangle = styled.div<{ isSelected: boolean }>`
  position: relative;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 17px;
  border-left: 3px solid transparent;
  border-bottom: 7.2px solid ${(props) => props.theme.colors.background};

  &:before {
    display: block;
    background-color: ${(props) =>
      props.isSelected ? props.theme.colors.secondary3 : "transparent"};
    box-sizing: border-box;
    border-right: 2px solid transparent;
  }
  &:before {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    border-top-left-radius: 5px;
    border-left: 1px solid ${(props) => props.theme.colors.borderColor2};
    border-top: 1px solid ${(props) => props.theme.colors.borderColor2};
    border-bottom: 1px solid transparent;
    transform: rotate(45deg) skew(10deg, 10deg);
    left: -1px;
    bottom: -14px;
  }
`;

interface ICarouselCardProps {
  isSelected: boolean;
  title: string;
  description: string;
}

const CarouselCard = ({ isSelected, title, description }: ICarouselCardProps) => {
  return (
    <>
      <CarouselCardWrapper isSelected={isSelected}>
        <h1>{title}</h1>
        <FAQCarouselDescription isSelected={isSelected}>
          <FCardSquarePointerUnselected>
            <OutLinedTriangle isSelected={isSelected} />
          </FCardSquarePointerUnselected>
          <p>&quot;{description}&quot;</p>
        </FAQCarouselDescription>
      </CarouselCardWrapper>
    </>
  );
};

export default CarouselCard;
