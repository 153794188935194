import React, { useEffect, useRef, useState } from "react";
import Script from "next/script";

// routing libs
import Router, { useRouter } from "next/router";
import NProgress from "nprogress";
import "nprogress/nprogress.css";

// redux store
import { useStore } from "react-redux";
import { Provider } from "react-redux";

import { PersistGate } from "redux-persist/integration/react";
import { ThemeProvider } from "styled-components";
import "../styles/globals.css";
import { wrapper } from "../store";
// theme
import { darkThemeWeb, lightThemeWeb } from "../theme/web";
NProgress.configure({ showSpinner: true });

// internationalization
import { appWithTranslation } from "next-i18next";
import nextI18NextConfig from "../../next-i18next.config.js";

// graphql
import { ApolloProvider } from "@apollo/client";
import gClient from "../lib/apollo";

// font

// If loading a variable font, you don't need to specify the font weight
// const lato = Lato({
//   weight: ["100", "300", "400", "700", "900"],
//   style: ["normal", "italic"],
//   subsets: ["latin"],
// });

// const lemonMilk = localFont({
//   src: [
//     {
//       path: './Lemon Milk/LEMONMILK-Light.otf',
//       weight: '300',
//       style: 'normal',
//     },
//     // {
//     //   path: 'fonts/Lemon Milk/LEMONMILK-LightItalic.otf',
//     //   weight: '300',
//     //   style: 'italic',
//     // },
//     // {
//     //   path: 'fonts/Lemon Milk/LEMONMILK-Regular.otf',
//     //   weight: '400',
//     //   style: 'normal',
//     // },
//     // {
//     //   path: 'fonts/Lemon Milk/LEMONMILK-RegularItalic.otf',
//     //   weight: '400',
//     //   style: 'italic',
//     // },
//     // {
//     //   path: 'fonts/Lemon Milk/LEMONMILK-Medium.otf',
//     //   weight: '500',
//     //   style: 'normal',
//     // },
//     // {
//     //   path: 'fonts/Lemon Milk/LEMONMILK-MediumItalic.otf',
//     //   weight: '500',
//     //   style: 'italic',
//     // },
//     // {
//     //   path: 'fonts/Lemon Milk/LEMONMILK-Bold.otf',
//     //   weight: '700',
//     //   style: 'normal',
//     // },
//     // {
//     //   path: 'fonts/Lemon Milk/LEMONMILK-BoldItalic.otf',
//     //   weight: '700',
//     //   style: 'italic',
//     // },
//   ],
// })

//Binding routing events.
Router.events.on("routeChangeStart", () => {
  NProgress.start();
});
Router.events.on("routeChangeComplete", () => {
  NProgress.done();
});
Router.events.on("routeChangeError", () => NProgress.done());

const ROUTES_TO_RETAIN = ["/", "/articles"];

export default wrapper.withRedux(
  appWithTranslation(({ Component, pageProps }) => {
    const router = useRouter();
    const retainedComponents = useRef({});
    const isRetainableRoute = ROUTES_TO_RETAIN.includes(router.asPath);

    // Add Component to retainedComponents if we haven't got it already
    if (isRetainableRoute && !retainedComponents.current[router.asPath]) {
      const MemoComponent = React.memo(Component);
      retainedComponents.current[router.asPath] = {
        component: <MemoComponent {...pageProps} />,
        scrollPos: 0,
      };
    }

    // Save the scroll position of current page before leaving
    const handleRouteChangeStart = (url) => {
      if (isRetainableRoute) {
        retainedComponents.current[router.asPath].scrollPos = window.scrollY;
      }
    };

    // Save scroll position - requires an up-to-date router.asPath
    useEffect(() => {
      router.events.on("routeChangeStart", handleRouteChangeStart);
      return () => {
        router.events.off("routeChangeStart", handleRouteChangeStart);
      };
    }, [router.asPath]);

    // Scroll to the saved position when we load a retained component
    useEffect(() => {
      if (isRetainableRoute) {
        window.scrollTo(0, retainedComponents.current[router.asPath].scrollPos);
      }
    }, [Component, pageProps]);

    useEffect(() => {
      history.scrollRestoration = "manual";
    }, []);

    const [theme, setTheme] = useState(0);

    const store = useStore();
    store.subscribe((v) => {
      setTheme(store.getState()?.fromClient?.theme);
    });

    const getLayout = Component.getLayout ?? ((page) => page);

    return (
      <ApolloProvider client={gClient}>
        <Provider store={store}>
          <PersistGate persistor={store.__persistor} loading={null}>
            {() => (
              <ThemeProvider
                theme={theme == 0 || !theme ? darkThemeWeb : lightThemeWeb}
              >
                <Script
                  src="https://www.googletagmanager.com/gtag/js?id=G-Y8P95QJG84"
                  strategy="afterInteractive"
                />
                <Script id="google-analytics" strategy="afterInteractive">
                  {`
                      window.dataLayer = window.dataLayer || [];
                      function gtag(){window.dataLayer.push(arguments);}
                      gtag('js', new Date());

                      gtag('config', 'G-Y8P95QJG84');
                  `}
                </Script>
                {getLayout(<Component {...pageProps} />)}
              </ThemeProvider>
            )}
          </PersistGate>
        </Provider>
      </ApolloProvider>
    );
  }, nextI18NextConfig)
);
