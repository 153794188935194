import { motion } from "framer-motion";
import millify from "millify";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { useEffect, useRef } from "react";
import styled from "styled-components";
import Typed from "typed.js";
import useSelectorTyped from "../../../store/useSelectorTyped";
import { variantContainer, variantitem } from "../../../utils/Animations";
import {
  A7ACenteredColumn,
  A7ACenteredRow,
  A7AColumn,
  A7AYCenteredColumn,
} from "../../../utils/Container";
import { useMediaQuery } from "../../../utils/useMediaQuery";
import GenericButton from "../../common/button/GenericButton";
import Mouse from "../../common/svg/common/Mouse";
import RightArrow from "../../common/svg/common/RightArrow";
import SizedBox from "../../common/ui/SizedBox";
import ArrowUpRight from "../../common/svg/common/ArrowUpRight";
const LandingHeroWrapper = styled(A7AColumn).attrs({ as: "header" })<{
  isDarkTheme: number;
}>`
  background-image: url(${(props) =>
    props.isDarkTheme
      ? "/images/TeamsBackground.webp"
      : "/images/HeroDarkBackground.webp"});
  min-height: 900px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0px 0px 20px 20px;
  padding-bottom: 30px;

  ${(props) => props.theme.mediaQuery.tablet} {
    padding-bottom: 0px;
  }
`;

const HeroTextWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  justify-content: flex-start;
  margin: 140px auto 0px auto;
  max-width: ${(props) => props.theme.desktopWidth};

  ${(props) => props.theme.mediaQuery.tablet} {
    margin: 170px auto 0px auto;
    align-items: center;
    justify-content: center;
  }
`;

const HeroTextContainer = styled.div`
  justify-content: flex-start;
  flex-direction: column;
  text-align: center;
  width: 100%;
  margin: ${(props) => props.theme.constrainedMargin};
  ${(props) => props.theme.mediaQuery.desktop} {
    margin: 0px;
  }
`;

const HeroTitle = styled.span`
  font-family: "LEMON MILK";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: ${({ theme }) => theme.colors.textColors[0]};

  ${(props) => props.theme.mediaQuery.tablet} {
    font-size: 40px;
    line-height: 54px;
  }

  .gradient {
    display: inline;
    background: linear-gradient(90deg, #26a7cf 0%, #27af8f 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

// width: 21.5ch;
// white-space: nowrap;
// overflow: hidden;
// border-right: 4px solid #212121;
// animation: cursor 1s step-start infinite,
//   text 5s steps(18) alternate infinite;

const HeroDescription = styled.p`
  /* font-family: "Inter"; */
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  margin: 0 auto;
  max-width: 480px;
  color: ${({ theme }) => theme.colors.textColors[0]};

  ${(props) => props.theme.mediaQuery.tablet} {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 40px; //Button
  }

  margin-bottom: 10px; //Button
`;

const HeroBottomBarContainer = styled(A7AYCenteredColumn)`
  margin: ${(props) => props.theme.constrainedMargin};
`;

const HeroBottomBar = styled(A7AYCenteredColumn)`
  background: ${(props) => props.theme.colors.accent};
  border-radius: 20px;
  height: auto;

  width: 100%;
  ${(props) => props.theme.mediaQuery.tablet} {
    max-width: 1320px;
    flex-direction: row;
    height: 168px;
    justify-content: space-evenly;
  }
`;
const BottomBarItem = styled(A7ACenteredColumn)`
  height: 105px;
`;

const BottomBarItemNumber = styled.span`
  font-family: "LEMON MILK";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 43px;
  text-align: center;
  color: #fff;

  ${(props) => props.theme.mediaQuery.tablet} {
    font-size: 40px;
    line-height: 54px;
  }
`;

const BottomBarItemText = styled.span`
  font-family: "Lato";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: #fff;

  ${(props) => props.theme.mediaQuery.tablet} {
    font-size: 20px;
    line-height: 24px;
  }
`;

const HeroButtonsContainer = styled(A7ACenteredColumn)`
  gap: 10px;
  justify-content: center;
  ${(props) => props.theme.mediaQuery.tablet} {
    flex-direction: row;
  }
`;

const HorizontalBar = styled.div`
  height: 1px;
  width: 100%;
  opacity: 0.1;
  border: 1px solid #000000;
  z-index: 0;
  ${(props) => props.theme.mediaQuery.tablet} {
    height: 88px;
    width: 1px;
  }
`;

const Hero = () => {
  const isMobile = useMediaQuery("(min-width: 767px)");
  const theme = useSelectorTyped((state) => state?.fromClient?.theme);
  const { t } = useTranslation("landing");
  const el = useRef<any>(null);
  const el2 = useRef<any>(null);

  useEffect(() => {
    const typed = new Typed(el.current, {
      strings: [t("hero.1.heroText2")],
      startDelay: 1200,
      typeSpeed: 40,
      backSpeed: 40,
      backDelay: 40,
      cursorChar: "",
    });
    return () => {
      typed.destroy();
    };
  }, []);

  useEffect(() => {
    const typed2 = new Typed(el2.current, {
      strings: [
        `<span class="gradient">CODE</span> - <span class="gradient">C</span>reate <span class="gradient">O</span>ptimized <span class="gradient">D</span>eliverables`,
        `<span class="gradient">CODE</span> - <span class="gradient">C</span>reate <span class="gradient">O</span>ptimized <span class="gradient">D</span>eliverables and <span class="gradient">E</span>ngineer stuff.`,
      ],
      startDelay: 2800,
      typeSpeed: 40,
      backSpeed: 40,
      backDelay: 40,
      cursorChar: "____",
    });
    return () => {
      typed2.destroy();
    };
  });

  return (
    <>
      <LandingHeroWrapper isDarkTheme={theme}>
        <HeroTextWrapper>
          <HeroTextContainer
            // as={motion.div}
            // variants={variantContainer}
            // initial={"hidden"}
            // whileInView={"show"}
            // viewport={{ once: true }}
          >
            <HeroTitle>{t("hero.0.heroText1")}</HeroTitle>
            <br />
            <HeroTitle ref={el} />
            <br />
            <HeroTitle ref={el2} />
            <br />

            {/*<AnimatePresence>
              {letters.map((letter, index) => (
                <HeroTitle
                  as={motion.span}
                  key={index}
                  initial={{ y: -10, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  exit={{ y: 10, opacity: 0 }}
                  transition={{ delay: index * 0.1 + 0.5 }}
                >
                  {letter}
                </HeroTitle>
              ))}
              <motion.span
              initial={{ y: -10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: 10, opacity: 0 }}
              transition={{ delay: cursor * 0.1 + 0.5 }}
            >
              |
            </motion.span>
              </AnimatePresence> */}
            <SizedBox height={24} width={0} />
            <HeroDescription>
              {t("hero.2.heroDescription")}
            </HeroDescription>
            <HeroButtonsContainer>
              <div>
                <Link legacyBehavior scroll={false} href={"/?section=projects"}>
                  <GenericButton
                    text={t("hero.3.projectsButton")}
                    height={50}
                    width={140}
                    fontSize={12}
                    isFullWidth={false}
                    isDisabled={false}
                    isProcessing={false}
                    onClick={() => {}}
                  >
                    <SizedBox width={18} height={0} />
                    <RightArrow />
                  </GenericButton>
                </Link>
              </div>
              <div>
                <Link legacyBehavior scroll={false} href={"/the-labs"}>
                  <GenericButton
                    text={t("hero.3.researchButton")}
                    height={50}
                    width={140}
                    backgroundcolor={theme === 0 ? "#FFFFFF" : "#13181D"}
                    textColor={theme === 0 ? "#13181D" : "#FFFFFF"}
                    fontSize={12}
                    isFullWidth={false}
                    isDisabled={false}
                    isProcessing={false}
                    onClick={() => {}}
                  >
                    <SizedBox width={18} height={0} />
                    <RightArrow color={theme === 0 ? "black" : "white"} />
                  </GenericButton>
                </Link>
              </div>
            </HeroButtonsContainer>
          </HeroTextContainer>
        </HeroTextWrapper>
        <HeroBottomBarContainer
          // as={motion.div}
          // variants={variantContainer}
          // initial={"hidden"}
          // whileInView={"show"}
          // viewport={{ once: true }}
        >
          {isMobile && (
            <>
              <SizedBox height={30} width={0} />
              <Mouse />
            </>
          )}
          <SizedBox height={34} width={0} />
          <HeroBottomBar>
            <BottomBarItem>
              <BottomBarItemNumber style={{ position: "relative" }}>
                {millify(20)}+
              </BottomBarItemNumber>
              <BottomBarItemText>
                <A7ACenteredRow>
                  {t("hero.4.heroBar.0.projects")}
                  <Link
                    legacyBehavior
                    scroll={false}
                    href={"/?section=projects"}
                  >
                    <ArrowUpRight style={{ cursor: "pointer" }} />
                  </Link>
                </A7ACenteredRow>
              </BottomBarItemText>
            </BottomBarItem>
            <HorizontalBar />
            <BottomBarItem>
              <BottomBarItemNumber>{millify(10)}+</BottomBarItemNumber>
              <BottomBarItemText>
                <A7ACenteredRow>
                  {t("hero.4.heroBar.1.happyClients")}
                  <Link
                    legacyBehavior
                    scroll={false}
                    href={"/?section=reviews"}
                  >
                    <ArrowUpRight style={{ cursor: "pointer" }} />
                  </Link>
                </A7ACenteredRow>
              </BottomBarItemText>
            </BottomBarItem>
            <HorizontalBar />
            <BottomBarItem>
              <BottomBarItemNumber>{millify(86)}</BottomBarItemNumber>
              <BottomBarItemText>
                {t("hero.4.heroBar.2.rank")}
              </BottomBarItemText>
            </BottomBarItem>
            <HorizontalBar />
            <BottomBarItem>
              <BottomBarItemNumber>{millify(50)}+</BottomBarItemNumber>
              <BottomBarItemText>
                <A7ACenteredRow>
                  {t("hero.4.heroBar.3.technologies")}
                  <Link
                    legacyBehavior
                    scroll={false}
                    href={"/?section=tech-stack"}
                  >
                    <ArrowUpRight style={{ cursor: "pointer" }} />
                  </Link>
                </A7ACenteredRow>
              </BottomBarItemText>
            </BottomBarItem>
          </HeroBottomBar>
        </HeroBottomBarContainer>
      </LandingHeroWrapper>
    </>
  );
};
export default Hero;
