import { motion } from "framer-motion"
import Image from "next/image"
import React, { useEffect } from "react"
import styled from "styled-components"
import { variantContainer, variantitem } from "../../../utils/Animations"
import {
  A7ACenteredColumn,
  A7ACenteredRow,
  A7AColumn,
  A7ARow,
  A7AXCenteredRow,
  SquircleOutlined3,
} from "../../../utils/Container"
import { GenericButton } from "../../common/button"
import RightArrow from "../../common/svg/common/RightArrow"
import {
  Figma,
  Flutter,
  GraphQl,
  ReactNative,
  VueJS,
  NextJS,
  NuxtJS,
  NestJS,
  ReactJS,
  Firebase,
  AndroidStudio,
  PostGreSQL,
  Javascript,
  AWS,
  Golang,
  HTMl,
  Python,
} from "../../common/svg/landing/projects"
import SizedBox from "../../common/ui/SizedBox"
import CircularProgressBar from "./progress-bar/CircularProgressBar"
import TechStackTag from "./ProjectTechStackTag"

const ProjectListContainer = styled.div<{ reverse?: boolean }>`
  position: relative;
  display: grid;
  grid-template-areas:
    "title title title"
    "image image image"
    "image image image"
    "image image image"
    "stats stats stats "
    "description description description"
    "description description description"
    "tags tags tags";
  row-gap: 20px;
  ${(props) => props.theme.mediaQuery.desktop} {
    column-gap: 50px;
    grid-template-areas: ${(props) =>
      props.reverse
        ? `"image image title title title title"
      "image image stats stats stats stats"
      "image image description description description description"
      "image image description description description description"
      "image image tags tags tags tags"`
        : `"title title title title image image"
      "stats stats stats stats image image"
      "description description description description image image"
      "description description description description image image"
"tags tags tags . image image"`};
  }
`

const ProjectHeadingContainer = styled(A7AColumn)<{ status: boolean }>`
  gap: 15px;
  align-items: flex-start;
  span {
    font-family: "Lato";
    font-size: 12px;
    color: ${(props) => (props.status ? "#fff" : "#a8b6bd")};
    font-weight: 700;
    border-radius: 10px;
    padding: 10px 10px 10px 10px;
    background: ${(props) =>
      props.status ? props.theme.colors.accent : props.theme.colors.secondary};
  }
  ${(props) => props.theme.mediaQuery.tablet} {
    flex-direction: row;
  }
`
const ProjectListHeading = styled.h1`
  grid-area: title;
  white-space: nowrap;
  color: ${(props) => props.theme.colors.textColors[0]};
  ${(props) => props.theme.mediaQuery.desktop} {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
  }
`

const ProjectListImage = styled(A7ACenteredRow)`
  grid-area: image;
  position: relative;
  width: 100%;
  height: 250px;
  aspect-ratio: 1/3;
  z-index: 0;
  img {
    border-radius: 10px;
    object-fit: cover;
  }
  ${(props) => props.theme.mediaQuery.desktop} {
    width: 400px;
    height: 300px;
  }
`

const ProjectStatsWrapper = styled(A7ACenteredColumn)`
  grid-area: stats;
  gap: 10px;
  ${(props) => props.theme.mediaQuery.tablet} {
    gap: 0px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`

const ProjectsStatsContainer = styled(A7AXCenteredRow)`
  align-items: center;
  gap: 8px;
  width: 100%;
  ${SquircleOutlined3};

  padding: 15px 20px;
  ${(props) => props.theme.mediaQuery.fold} {
    gap: 2px;
  }
  ${(props) => props.theme.mediaQuery.tablet} {
    /* max-width: 502px; */
    width: fit-content;
    height: 44px;
    padding: 10px 15px 10px 15px;
  }
`

const LighthouseAnalysisButtonContainer = styled(A7ACenteredRow)`
  outline: none;
  color: #27af8f;
  gap:10px;
`

const ProjectDescription = styled.p`
  margin: 0px;
  grid-area: description;
  font-weight: 300;
  font-family: "Lato";
  font-size: 14px;
  line-height: 17px;
  color: ${(props) => props.theme.colors.textColors[1]};
  ${(props) => props.theme.mediaQuery.desktop} {
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
  }
`

const ProjectTags = styled(A7ARow)`
  grid-area: tags;
  flex-wrap: wrap;
  align-items: flex-end;
  gap: 10px;
`

const HoverButton = styled.div`
  position: absolute;
  height: 50px;
  width: 205px;
  top: 0px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
`

interface IProjectCardProps {
  type: number[]
  title: string
  hasAnalysis?: boolean
  description: string
  image: string
  stats?: {
    name?: string
    value?: number
    grade?: string
  }[]
  techStack: {
    name: string
    icon: string
  }[]
  url?: string
  caseStudyURL?: string
  hoverActive: string
  setHoverActive: (e: string) => void
  counter: number
  activeIndexState: number
  projectStatus?: string
}
const ProjectCard = ({
  type,
  title,
  description,
  image,
  stats,
  techStack,
  url,
  caseStudyURL,
  hoverActive,
  counter,
  hasAnalysis,
  setHoverActive,
  projectStatus,
  activeIndexState,
}: IProjectCardProps) => {
  const renderIcon = (i: string) => {
    switch (i) {
      case "ReactJS":
        return <ReactJS />
      case "GraphQl":
        return <GraphQl />
      case "Figma":
        return <Figma />
      case "Golang":
        return <Golang />
      case "Flutter":
        return <Flutter />
      case "ReactNative":
        return <ReactNative />
      case "VueJS":
        return <VueJS />
      case "NextJS":
        return <NextJS />
      case "NestJS":
        return <NestJS />
      case "NuxtJS":
        return <NuxtJS />
      case "Firebase":
        return <Firebase />
      case "AndroidStudio":
        return <AndroidStudio />
      case "PostGreSQL":
        return <PostGreSQL />
      case "Javascript":
        return <Javascript />
      case "AWS":
        return <AWS />
      case "HTML":
        return <HTMl />
      case "Python":
        return <Python />
      default:
        return <></>
    }
  }

  const generateLighthouseURL = (url: string): string => {
    const encodedURL = encodeURIComponent(url.replace("https://", ""))
    const analysisURL = `https://pagespeed.web.dev/analysis?url=https%3A%2F/${encodedURL}`
    // const screenType = isMobile ? 'mobile' : 'desktop'
    // return `${analysisURL}?form_factor=${screenType}`
    return analysisURL
  }
  useEffect(() => {
    console.log(title , hasAnalysis)
  },[])
  return (
    <>
      <motion.div
        style={{ cursor: hoverActive && url ? "pointer" : "default" }}
        onClick={() => {
          url && window.open(url, "_blank")
        }}
        variants={variantContainer}
        initial={"hidden"}
        whileInView={"show"}
        viewport={{ once: true }}
        key={counter}
      >
        <motion.div variants={variantitem}>
          <ProjectListContainer
            id={counter.toString()}
            reverse={counter % 2 !== 0}
            onMouseOver={(e) => {
              // console.log(hoverActive)
              setHoverActive(e.currentTarget.id)
            }}
            onMouseOut={() => setHoverActive("")}
          >
            <ProjectHeadingContainer
              status={
                projectStatus
                  ? projectStatus.includes("development")
                    ? false
                    : true
                  : false
              }
            >
              <ProjectListHeading as={motion.h1} variants={variantitem}>
                {title}
              </ProjectListHeading>
              {projectStatus && <span>{projectStatus}</span>}
            </ProjectHeadingContainer>
            <ProjectListImage>
              <Image
                loading="lazy"
                src={image}
                alt={image}
                fill
                style={{
                  opacity: counter.toString() === hoverActive ? 0.7 : 1,
                  cursor:
                    counter.toString() === hoverActive && url
                      ? "pointer"
                      : "default",
                }}
              />
              {hoverActive === counter.toString() && (
                  <HoverButton
                    style={{
                      height: caseStudyURL ? "120px" : "50px",
                    }}
                  >
                    {url && (
                      <GenericButton
                        text={"VIEW PROJECT"}
                        height={50}
                        width={200}
                        isFullWidth={false}
                        isDisabled={false}
                        isProcessing={false}
                        onClick={(e) => {
                          e.stopPropagation()
                          window.open(url, "_blank")
                        }}
                      >
                        <SizedBox width={18} height={0} />
                        <RightArrow />
                      </GenericButton>
                    )}
                    <SizedBox height={20} width={0} />
                    {caseStudyURL && (
                      <GenericButton
                        text={"CASE STUDY"}
                        backgroundcolor={
                          "linear-gradient(to right, #36e4da , #00dc82);"
                        }
                        height={50}
                        width={200}
                        isFullWidth={false}
                        isDisabled={false}
                        isProcessing={false}
                        onClick={(e) => {
                          e.stopPropagation()
                          window.open(caseStudyURL, "_blank")
                        }}
                      >
                        <SizedBox width={18} height={0} />
                        <RightArrow />
                      </GenericButton>
                    )}
                  </HoverButton>
              )}
            </ProjectListImage>
            <ProjectStatsWrapper>
              <ProjectsStatsContainer>
                {stats !== undefined ? (
                  stats.map((i, j) => (
                    <React.Fragment key={j}>
                      <CircularProgressBar
                        index={j}
                        type={activeIndexState}
                        name={i.name}
                        grade={i.grade}
                        value={i.value}
                        isLast={j === stats.length - 1}
                      />
                      {/* {j < 3 && <Divider />} */}
                    </React.Fragment>
                  ))
                ) : (
                  <></>
                )}
              </ProjectsStatsContainer>
              {hasAnalysis !== false &&
                <LighthouseAnalysisButtonContainer
                  onClick={(e) => {
                    e.stopPropagation()
                    const lighthouseURL = generateLighthouseURL(url!)
                    window.open(lighthouseURL, "_blank", "noopener,noreferrer")
                  
                  }}
                  title="Open Lighthouse analysis"
                  aria-label="Open Lighthouse analysis"
                >
                  Analysis
                  <RightArrow
                    color="#27AF8F"
                    styles={{ marginTop: 3 }}
                  />
                </LighthouseAnalysisButtonContainer>
              }
            </ProjectStatsWrapper>
            <ProjectDescription
              as={motion.p}
              variants={variantitem}
              dangerouslySetInnerHTML={{
                __html: `${description}`,
              }}
            />
            <ProjectTags
              as={motion.div}
              variants={variantContainer}
              initial={"hidden"}
              whileInView={"show"}
              viewport={{ once: true }}
            >
              {techStack.map((i, j) => (
                <div key={j}>
                  <TechStackTag icon={renderIcon(i.icon)} text={i.name} />
                </div>
              ))}
            </ProjectTags>
          </ProjectListContainer>
        </motion.div>
      </motion.div>
    </>
  )
}

export default ProjectCard
