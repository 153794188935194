import { useTranslation } from "next-i18next";
import Link from "next/link";
import React, { useEffect, useRef, useState } from "react";
import { SimpleCarousel } from "react-whirlpool";
import "react-whirlpool/dist/cjs/component/SimpleCarousel.css";
import styled from "styled-components";
import { getLatestArticles } from "../../../gql/articles";
import client from "../../../lib/apollo";
import { useMediaQuery } from "../../../utils/useMediaQuery";
import { GenericButton } from "../../common/button";
import RightArrow from "../../common/svg/common/RightArrow";
import SectionWrapper from "../../common/ui/SectionWrapper";
import SizedBox from "../../common/ui/SizedBox";
import ArticleCard from "./ArticleCard";

export const ArticleCardWrapper = styled.div`
  margin: ${(props) => props.theme.constrainedMargin};
  margin-top: 30px;
  justify-content: flex-start;
  gap: 30px;
  max-width: 100%;
  margin-bottom: 16px;
  ${(props) => props.theme.mediaQuery.tablet} {
    margin-top: 60px;
    flex-direction: row;
    margin-bottom: 60px;
  }
`;

const LatestArticles = () =>
  // { onView }: ISectionProps
  {
    // const isMobile = useMediaQuery("(max-width: 400px)");
    // const {ref, inView } = useInView({
    //   rootMargin: "0px 2000px 500px 0px",
    //   threshold: 0.4,
    // });

    // useEffect(() => {
    //   if (inView === true) {
    //     onView(6);
    //     console.log(inView);

    //     console.log("Projects In view");
    //   }
    // }, [inView, onView]);

    const isMobile = useMediaQuery("(max-width: 766px)");
    const [activeIndex, setActiveIndex] = useState(0);
    const [latestArticles, setLatestArticles] = useState<any>([]);
    const simpleCarouselRef = useRef<any>();
    const { t } = useTranslation("landing");

    const handleFetchLatestArticles = async () => {
      try {
        const { data } = await client.query({
          query: getLatestArticles(),
        });
        console.log(data);
        setLatestArticles(data.blogs.data);
      } catch (e) {}
    };

    useEffect(() => {
      handleFetchLatestArticles();
    }, []);

    if (!latestArticles || latestArticles.length == 0) return null;

    return (
      <>
        <SectionWrapper
          id="articles"
          carouselButtons={!isMobile}
          heading={t("articles.0.latestArticlesTitle")}
          description={t("articles.1.latestArticlesDescription") as string}
          leftButtonOnClick={() => {
            simpleCarouselRef.current &&
              simpleCarouselRef.current.handlePrevEvent();
          }}
          rightButtonOnClick={() => {
            simpleCarouselRef.current &&
              simpleCarouselRef.current.handleNextEvent();
          }}
        >
          <ArticleCardWrapper>
            {!isMobile ? (
              <>
                <SimpleCarousel
                  isHorizontal={true}
                  gap={30}
                  autoPlay={false}
                  hideInitGap={false}
                  minHeight="154px"
                  ref={simpleCarouselRef}
                  hideArrows={true}
                  onActiveIndexUpdate={(index: number) => setActiveIndex(index)}
                >
                  {latestArticles?.map((article: any, i: number) => (
                    <React.Fragment key={i}>
                      <ArticleCard
                        isActive={i === activeIndex}
                        key={article.id}
                        id={article.attributes.article_uid}
                        image={`${process.env.UPLOAD_URI}${article.attributes.cover_img.data.attributes.url}`}
                        title={article.attributes.title}
                        description={article.attributes.subtitle}
                        tags={article.attributes.tags.data.map((c: any) => {
                          return {
                            name: c.attributes.name,
                            icon: `${process.env.UPLOAD_URI}${c.attributes.icon.data.attributes.url}`,
                          };
                        })}
                        share
                      />
                    </React.Fragment>
                  ))}
                </SimpleCarousel>
              </>
            ) : (
              latestArticles?.map((article: any, i: number) => (
                <React.Fragment key={i}>
                  <ArticleCard
                    isActive={false}
                    id={article.attributes.article_uid}
                    image={`${process.env.UPLOAD_URI}${article.attributes.cover_img.data.attributes.url}`}
                    title={article.attributes.title}
                    description={article.attributes.subtitle}
                    tags={article.attributes.tags.data.map((c: any) => {
                      return {
                        name: c.attributes.name,
                        icon: `${process.env.UPLOAD_URI}${c.attributes.icon.data.attributes.url}`,
                      };
                    })}
                    share
                  />
                  <SizedBox height={24} width={0} />
                </React.Fragment>
              ))
            )}
          </ArticleCardWrapper>

          <Link href="/articles">
            <GenericButton
              text={t("articles.2.latestArticlesButton") as string}
              height={50}
              width={180}
              isFullWidth={false}
              isDisabled={false}
              isProcessing={false}
              onClick={() => {}}
            >
              <SizedBox width={18} height={0} />
              <RightArrow />
            </GenericButton>
          </Link>
        </SectionWrapper>
      </>
    );
  };

// export async function getStaticProps() {
//   try {
//     const { data } = await client.query({
//       query: getLatestArticles(),
//     });
//     console.log(data);
//     return {
//       props: {
//         blogs: data.blogs,
//       },
//     };
//   } catch (e) {
//     return {
//       props: {},
//     };
//   }
// }

export default LatestArticles;
