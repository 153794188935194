import { SVGProps } from "react"

const StackOverflow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={19}
    height={19}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.5 13.435v.75a1.5 1.5 0 0 0 1.5 1.5h9a1.5 1.5 0 0 0 1.5-1.5v-.75m-9-.75h6m-5.758-2.563 5.967.627M7.09 7.56l5.87 1.248m-4.888-3.8 5.706 1.853"
      stroke="#A9B6BD"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default StackOverflow