import { SVGProps } from "react";
import useSelectorTyped from "../../../../store/useSelectorTyped";

const Cross = (props: SVGProps<SVGSVGElement>) => {
  const theme = useSelectorTyped((state) => state?.fromClient?.theme);
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.37695 6.18872L18.6215 18.4333"
        stroke={theme === 1 ? "#14171D" : "white"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.37695 18.1887L18.499 6.06665"
        stroke={theme === 1 ? "#14171D" : "white"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Cross;
