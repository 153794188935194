import { SVGProps } from "react"

const BigTick = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={123}
    height={124}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M61.5 123.426c33.965 0 61.5-27.534 61.5-61.5 0-33.965-27.535-61.5-61.5-61.5C27.534.426 0 27.961 0 61.926c0 33.966 27.534 61.5 61.5 61.5Zm0-5.75c-30.79 0-55.75-24.96-55.75-55.75S30.71 6.176 61.5 6.176s55.749 24.96 55.749 55.75-24.96 55.75-55.75 55.75Zm23.35-68.223a3 3 0 0 0-4.243-4.243L53.54 72.278 42.392 61.132a3 3 0 0 0-4.242 4.242l13.268 13.268a3 3 0 0 0 4.242 0l29.19-29.19Z"
      fill="url(#a)"
    />
    <defs>
      <linearGradient
        id="a"
        x1={0}
        y1={123.426}
        x2={123}
        y2={123.426}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#26A7CF" />
        <stop offset={1} stopColor="#27AF8F" />
      </linearGradient>
    </defs>
  </svg>
)

export default BigTick