import { useRouter } from "next/router";
import Script from "next/script";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import { Portal } from "react-portal";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import useSelectorTyped from "../../store/useSelectorTyped";
import Navbar from "../common/navbar";
import ArticleNavbar from "../common/navbar/ArticleNavbar";
import Footer from "../landing/footer";

export const LandingWrapper = styled.div`
  max-width: 100%;
  overflow-x: hidden;
  background-color: ${(props) => props.theme.colors.background};
`;

interface ILayoutProps {
  children: JSX.Element | JSX.Element[];
}

declare namespace CSS {
  namespace paintWorklet {
    export function addModule(url: string): void;
  }
}

const Layout = ({ children }: ILayoutProps) => {
  const dispatch = useDispatch();
  const theme = useSelectorTyped((store) => store?.fromClient?.theme);
  const router = useRouter();
  const [activeIndexState, setActiveIndexState] = useState(0);
  const sleep = (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms));

  useEffect(() => {
    sleep(10);
    if (theme == 1) {
      dispatch({ type: "TOGGLE_THEME", payload: 1 });
      sleep(10);
      document.body.style.backgroundColor = "#fff";
    } else {
      dispatch({ type: "TOGGLE_THEME", payload: 0 });
      sleep(10);
      document.body.style.backgroundColor = "#13161D";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theme]);

  const handleToggleTheme = () => {
    dispatch({
      type: "TOGGLE_THEME",
      payload: theme == 0 ? 1 : 0,
    });
  };

  // const handleActiveNavbar = (e: number) => {
  //   setActiveIndexState(e);
  // };

  const handleScroll = async () => {
    await sleep(50);
    if (window.location.href.includes("?section")) {
      const section = window.location.href.split("?section=")[1];

      var element = document.getElementById(section);
      var headerOffset = 90;
      var elementPosition = element?.getBoundingClientRect().top || 0;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
      switch (section) {
        case "labs":
          setActiveIndexState(1);
          break;
        case "projects":
          setActiveIndexState(2);
          break;
        case "tech-stack":
          setActiveIndexState(3);
          break;
        // case "team":
        //   setActiveIndexState(4);
        //   break;
        case "reviews":
          setActiveIndexState(4);
          break;
        // case "articles":
        //   setActiveIndexState(5);
        //   break;
        case "career":
          setActiveIndexState(6);
          break;
        case "contact":
          setActiveIndexState(7);
          break;
        default:
          setActiveIndexState(0);
          break;
      }
    }
    // if (window.location.href.includes("team")) {
    //   setActiveIndexState(4);
    // }
    if (window.location.href.includes("articles")) {
      setActiveIndexState(5);
    }
    if (router.asPath == "/") {
      setActiveIndexState(0);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    handleScroll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath]);

  const [hasWindow, setHasWindow] = useState(false);
  useEffect(() => {
    setHasWindow(true);
  }, []);

  return (
    <>
      {hasWindow && (
        <Portal node={document && document.getElementById("toastportal")}>
          <div className="toast-container">
            <ToastContainer theme="dark" />
          </div>
        </Portal>
      )}
      <Script
        id="CSSHOUDINI"
        src="https://www.unpkg.com/css-houdini-squircle@0.2.1/squircle.min.js"
        onLoad={() => {
          if ("paintWorklet" in CSS) {
            console.log("painWorklet in CSS");

            CSS.paintWorklet.addModule(
              "https://www.unpkg.com/css-houdini-squircle@0.2.1/squircle.min.js"
            );
          }
        }}
      />
      <LandingWrapper>
        {router.asPath.split("/articles/")[1] != undefined ||
        router.asPath.split("/article/")[1] != undefined ? (
          <ArticleNavbar />
        ) : (
          <Navbar activeIndex={activeIndexState} isNavbarOpen={false} />
        )}
        {children}
        <Footer activeIndex={activeIndexState} />
      </LandingWrapper>
    </>
  );
};

export default Layout;
