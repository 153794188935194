import React from "react";
import styled from "styled-components";

const HighlightShineWrapper = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 30px;
  margin: 5px;
  position: absolute;
  z-index: 2;
  background: linear-gradient(
      90deg,
      #0000 33%,
      rgba(255, 255, 255, 0.9) 50%,
      #0000 66%
    )
    transparent;
  background-size: 300% 100%;
  &:hover {
    animation: shine 1.5s normal;
  }

  @keyframes shine {
    0% {
      background-position: right;
    }
    100% {
      background-position: left;
    }
  }
`;


const HighlightShine = () => {
  return <HighlightShineWrapper />;
};

export default HighlightShine;
