import { SVGProps } from 'react'
import useSelectorTyped from '../../../../../store/useSelectorTyped'

const Javascript = (props: SVGProps<SVGSVGElement>) => {
  const theme = useSelectorTyped((state) => state?.fromClient?.theme)
  return (
    <>
      <svg
        width={19}
        height={20}
        viewBox="0 0 19 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="m17.814 1.945-2 14.5-6 2-6-2-2-14.5h16Z"
          stroke={theme === 1 ? '#A9B6BD' : '#FFFFFF'}
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.314 5.945h3v8l-2-1m8-7h-2.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h1.424a.5.5 0 0 1 .495.57l-.418 2.93-2 .5"
          stroke={theme === 1 ? '#A9B6BD' : '#FFFFFF'}
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  )
}

export default Javascript
