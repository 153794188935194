import AIMLCoding from "./mobile/AIMLCoding";
import ARVRSolutions from "./mobile/ARVRSolutions";
import Automation from "./mobile/Automation";
import BackendDevelopment from "./mobile/BackendDevelopment";
import BlockChainDevelopment from "./mobile/BlockChainDevelopment";
import CustomSoftwareDevelopment from "./mobile/CustomSoftwareDevelopment";
import DesktopApps from "./mobile/DesktopApps";
import DevOps from "./mobile/DevOps";
import MobileAppsDevelopment from "./mobile/MobileAppsDevelopment";
import WebAppsDevelopment from "./mobile/WebAppsDevelopment";
import AIMLCodingWeb from "./desktop/AIMLCodingWeb";
import ARVRSolutionsWeb from "./desktop/ARVRSolutionsWeb";
import AutomationWeb from "./desktop/AutomationWeb";
import BackendDevelopmentWeb from "./desktop/BackendDevelopmentWeb";
import BlockChainDevelopmentWeb from "./desktop/BlockChainDevelopmentWeb";
import CustomSoftwareDevelopmentWeb from "./desktop/CustomSoftwareDevelopmentWeb";
import DesktopAppsWeb from "./desktop/DesktopAppsWeb";
import DevOpsWeb from "./desktop/DevOpsWeb";
import MobileAppsDevelopmentWeb from "./desktop/MobileAppsDevelopmentWeb";
import WebAppsDevelopmentWeb from "./desktop/WebAppsDevelopmentWeb";

export {
  AIMLCoding,
  ARVRSolutions,
  Automation,
  BackendDevelopment,
  BlockChainDevelopment,
  CustomSoftwareDevelopment,
  DesktopApps,
  DevOps,
  MobileAppsDevelopment,
  WebAppsDevelopment,
  
  AIMLCodingWeb,
  ARVRSolutionsWeb,
  AutomationWeb,
  BackendDevelopmentWeb,
  BlockChainDevelopmentWeb,
  CustomSoftwareDevelopmentWeb,
  DesktopAppsWeb,
  DevOpsWeb,
  MobileAppsDevelopmentWeb,
  WebAppsDevelopmentWeb,
};
