import styled from "styled-components";
import { A7AYCenteredRow } from "../../../utils/Container";

export const InputContainer = styled(A7AYCenteredRow)`
  width: 100%;
  position: relative;
`;

export const Input = styled.input`
  padding: 13px 15px 13px 41px;
  width: 100%;
  height: 50px;
  background: rgba(255, 255, 255, 0.01);
  border: 1px solid ${(props) => props.theme.colors.borderColor2};
  color: ${(props) => props.theme.colors.textColors[0]};
  ::placeholder {
    font-family: "Lato", sans-serif;
  }
  border-radius: 10px;
`;

export const IconContainer = styled.div`
  position: absolute;
  left: 15px;
`;

const RightStuff = styled.div`
  position: absolute;
  right: 15px;
`;

export interface IInputProps {
  placeholder: string;
  id: string;
  type?: React.HTMLInputTypeAttribute;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string | number | readonly string[];
  icon?: JSX.Element;
  children?: JSX.Element | JSX.Element[];
  inputMode?:
    | "text"
    | "none"
    | "search"
    | "tel"
    | "url"
    | "email"
    | "numeric"
    | "decimal";
}
const A7AInputField = ({
  placeholder,
  type,
  id,
  onChange,
  value,
  icon,
  inputMode,
  children,
}: IInputProps) => {
  return (
    <>
      <InputContainer>
        <IconContainer>{icon}</IconContainer>
        <Input
          type={type}
          id={id}
          inputMode={inputMode}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
        />
        <RightStuff>{children}</RightStuff>
      </InputContainer>
    </>
  );
};

export default A7AInputField;
