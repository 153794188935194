import { SVGProps } from "react"
import useSelectorTyped from "../../../../store/useSelectorTyped"

const HamburgerIcon = (props: SVGProps<SVGSVGElement>) => {
  const theme = useSelectorTyped(state => state?.fromClient?.theme);
  
  return (
    <svg
      width={25}
      height={25}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.5 6.25h16M4.5 12.25h10M4.5 18.25h14"
        stroke={theme === 0 ? "#fff" : "#14171D"}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default HamburgerIcon
