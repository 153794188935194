import { AnimatePresence, motion } from 'framer-motion'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { useTranslation } from 'next-i18next'
import styled from 'styled-components'
import {
  A7ACenteredColumn,
  A7ACenteredRow,
  A7AColumn,
  SquircleOutlined,
} from '../../../utils/Container'
import { useMediaQuery } from '../../../utils/useMediaQuery'
import { GenericButton } from '../../common/button'
import RightArrow from '../../common/svg/common/RightArrow'
import SizedBox from '../../common/ui/SizedBox'

const CardWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.background};
  height: auto;

  ${(props) => props.theme.mediaQuery.tablet} {
    height: 487px;
    :hover {
      height: 600px;
      transition: height 0.3s;
    }
  }
`
const CardContainer = styled(A7ACenteredRow)<{
  isActive: boolean
  isHovered: boolean
}>`
  ${SquircleOutlined}
  border-radius: ${(props) => props.theme.borderRadius};
  background-color: ${(props) => props.theme.colors.background};
  padding: 10px 15px;
  & > button {
    display: none;
  }
  ${(props) => props.theme.mediaQuery.tablet} {
    background-color: ${(props) =>
      props.isActive && props.isHovered === false
        ? props.theme.colors.secondary3
        : props.theme.colors.background};
    gap: 0px;
    padding: 30px 30px 40px;
    margin: 0px;
    flex-direction: column;
    min-height: 250px;
  }
  ${(props) => props.theme.mediaQuery.desktop} {
    width: 316px;
  }
`

const CardInnerWrapper = styled(A7ACenteredRow)<{ isActive: boolean }>`
  --gradient-angle: 0deg;
  cursor: pointer;
  width: 100%;
  padding: 2px;
  border-radius: ${(props) => props.theme.borderRadius};
  gap: 20px;
  transition: all 0.3s ease;

  :hover {
    /* & > div > button { */
    display: flex;
    /* } */
    background: linear-gradient(
      var(--gradient-angle),
      hsla(0, 0%, 100%, 0.5),
      hsla(0, 0%, 100%, 0.1),
      #fff,
      hsla(0, 0%, 100%, 0.3)
    );
    animation: gradient-rotate 5s linear 0s infinite reverse;
    @property --gradient-angle {
      syntax: '<angle>';
      inherits: false;
      initial-value: 1turn;
    }
    @keyframes gradient-rotate {
      0% {
        --gradient-angle: 360deg;
      }
      100% {
        --gradient-angle: 0deg;
      }
    }
  }
`

const CardTextContainer = styled(A7AColumn)<{ isActive: boolean }>`
  justify-content: center;
  ${(props) => props.theme.mediaQuery.tablet} {
    margin-top: 20px;
    flex: auto;
    justify-content: flex-start;
    align-items: flex-start;
  }
  h1 {
    margin: 0px;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    background: ${(props) => props.theme.colors.textColors[0]};

    -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    /* white-space: nowrap; */

    ${(props) => props.theme.mediaQuery.tablet} {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 64px;
      font-weight: 500;
      text-align: center;
      font-size: 24px;
      /* min-height: 70px; */
      width: 256px;
      line-height: 32px;
      white-space: pre-wrap;
      background: ${(props) =>
        props.isActive
          ? props.theme.colors.accent
          : props.theme.colors.textColors[0]};
      -webkit-text-fill-color: transparent;
      background-clip: text;
      -webkit-background-clip: text;
    }
  }
  p {
    text-align: justify;
    margin: 10px 0px 0px 0px;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    color: ${(props) => props.theme.colors.textColors[1]};

    ${(props) => props.theme.mediaQuery.tablet} {
      margin-top: 20px;
      font-size: 16px;
      line-height: 19px;
      width: 256px;
      text-align: center;
      min-height: 60px;
      white-space: pre-wrap;
    }
  }
`

const CardImageContainer = styled(A7ACenteredColumn)`
  width: 110px;
  height: 110px;
  ${(props) => props.theme.mediaQuery.tablet} {
    flex: auto;
    width: 256px;
    height: 256px;
  }
`

interface IWhatWeOfferCardProps {
  image: JSX.Element
  title: string
  description: string
  isActive: boolean
  type: string
}
const WhatWeOfferCard = ({
  image,
  title,
  isActive,
  description,
  type,
}: IWhatWeOfferCardProps) => {
  const router = useRouter()
  const isMobile = useMediaQuery('(max-width: 767px)')
  const [isHovered, setIsHovered] = useState(false)
  const { t } = useTranslation('landing')
  return (
    <CardWrapper>
      <CardInnerWrapper
        isActive={isActive}
        onClick={() => (isMobile ? router.push(`/services/${type}`) : null)}
        onMouseOver={() =>
          !isMobile ? setIsHovered(true) : setIsHovered(false)
        }
        onMouseLeave={() => setIsHovered(false)}
      >
        <CardContainer isActive={isActive} isHovered={isHovered}>
          <CardImageContainer>{image}</CardImageContainer>
          <CardTextContainer isActive={isActive}>
            <h1>{title}</h1>
            <p>{description}</p>
          </CardTextContainer>
          {!isMobile && isHovered && (
            <AnimatePresence>
              <motion.div
                transition={{ duration: 0.4 }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <SizedBox height={20} width={0} />
                <GenericButton
                  text={t(`whatWeOffer.3.whatWeOfferButton`)}
                  height={50}
                  width={179}
                  isFullWidth={false}
                  isDisabled={false}
                  isProcessing={false}
                  onClick={() => router.push(`/services/${type}`)}
                >
                  <SizedBox height={0} width={18} />
                  <RightArrow />
                </GenericButton>
              </motion.div>
            </AnimatePresence>
          )}
        </CardContainer>
      </CardInnerWrapper>
    </CardWrapper>
  )
}

export default WhatWeOfferCard
