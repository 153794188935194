import Link from "next/link";
import { useRouter } from "next/router";
import styled from "styled-components";
import { NavbarContainer, NavbarWrapper } from ".";
import useSelectorTyped from "../../../store/useSelectorTyped";
import { A7ACenteredRow, A7AYCenteredRow } from "../../../utils/Container";
import { useMediaQuery } from "../../../utils/useMediaQuery";
import LeftArrow from "../svg/common/LeftArrow";
import A7ALogo from "../svg/common/logo/A7ALogo";
import SizedBox from "../ui/SizedBox";
import { ThemeSlider } from "./ThemeSlider";
import { useEffect, useState } from "react";

const NavbarTopView = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${(props) => props.theme.mediaQuery.desktop} {
    margin: 0px;
  }
`;

const ArticleNavbarContainer = styled(NavbarContainer)`
  padding: 8px 15px;
  ${(props) => props.theme.mediaQuery.tablet} {
    padding: 16px 15px;
  }
`;

const NavbarLeftContainer = styled(A7ACenteredRow).attrs({ as: "button" })`
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
`;

const ArticleNavbarText = styled.p`
  font-family: "Lato";
  font-weight: 800;
  font-size: 17px;
  text-align: left;
  margin-left: 5px;
  color: ${(props) => props.theme.colors.textColors[0]};
  ${(props) => props.theme.mediaQuery.tablet} {
    line-height: 30px;
  }
`;

const ArticleNavbar = () => {
  const isMobile = useMediaQuery("(max-width: 400px)");
  const [isScrolled, setIsScrolled] = useState(false);

  const theme = useSelectorTyped((store) => store?.fromClient?.theme);
  const router = useRouter();

  useEffect(() => {    
    let scrollEvent: any = document.addEventListener("scroll", (e) => {
      if (document.body.getBoundingClientRect().top < -200) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    });
    document.removeEventListener("scroll", scrollEvent);
  }, []);

  return (
    <>
      <NavbarWrapper isNavbarOpen={false} isScrolled={isScrolled}>
        <ArticleNavbarContainer isNavbarOpen={false} isScrolled={isScrolled}>
          <NavbarTopView>
            <A7AYCenteredRow>
              <NavbarLeftContainer onClick={() => router.push("/articles")}>
                <LeftArrow
                  color={theme === 1 ? "#13181d" : "#fff"}
                  styles={{
                    width: 24,
                    height: 24,
                    marginBottom: 2,
                    marginLeft: -5,
                  }}
                  props={{ width: 24, height: 24, viewBox: "-5 -4 20 20" }}
                />
                <ArticleNavbarText>Back to articles</ArticleNavbarText>
              </NavbarLeftContainer>
              {!isMobile && <SizedBox width={16} height={0} />}
              <ThemeSlider />
            </A7AYCenteredRow>
            <Link href={"/"} replace aria-label="Navigate to home page">
              <A7ALogo
                props={{
                  width: isMobile ? "54" : "60",
                  height: isMobile ? "44" : "50",
                }}
              />
            </Link>
          </NavbarTopView>
        </ArticleNavbarContainer>
      </NavbarWrapper>
    </>
  );
};

export default ArticleNavbar;
