import { gql } from "@apollo/client";

export const getCategories = gql`
  query {
    categories {
      data {
        attributes {
          name
        }
      }
    }
  }
`;

export const getLatestArticles = () => gql`
  # Write your query or mutation here
  query {
    blogs(pagination: { start: 0, limit: 10 }, sort: "createdAt:desc") {
      data {
        attributes {
          title
          createdAt
          subtitle
          article_uid
          body
          cover_img {
            data {
              attributes {
                url
              }
            }
          }
          tags {
            data {
              attributes {
                name
                icon {
                  data {
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
          category {
            data {
              attributes {
                name
                icon {
                  data {
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
          author {
            data {
              attributes {
                username
                avatar {
                  data {
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const getArticles = (categories: string[]) => gql`
  query {
    blogs(sort: "createdAt:desc",
    filters: { category: { name: { in: [${categories}] } } }
    ) {
      data {
        attributes {
          title
          createdAt
          subtitle
          article_uid
          body
          cover_img {
            data {
              attributes {
                url
              }
            }
          }
          tags {
            data {
              attributes {
                name
                icon {
                  data {
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
          category {
            data {
              attributes {
                name
                icon {
                  data {
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
          author {
            data {
              attributes {
                username
                avatar {
                  data {
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const getArticleByUid = (uid: string) => gql`
  query {
    blogs(filters: { article_uid: { eq: "${uid.toString()}" } }) {
      data {
        attributes {
          title
          createdAt
          subtitle
          article_uid
          body
          cover_img {
            data {
              attributes {
                url
              }
            }
          }
          tags {
            data {
              attributes {
                name
                icon {
                  data {
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
          category {
            data {
              attributes {
                name
                icon {
                  data {
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
          author {
            data {
              attributes {
                username
                avatar {
                  data {
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const getSimilarArticles = (tags: string[], uid: string) => gql`
  # Write your query or mutation here
  query {
    blogs(
      filters: { tags: { name: { in: [${tags}] } }, article_uid: {ne: "${uid}"} }
      pagination: { start: 0, limit: 6 },
      sort: "createdAt:desc"
    ) {
      data {
        attributes {
          title
          createdAt
          subtitle
          article_uid
          body
          cover_img {
            data {
              attributes {
                url
              }
            }
          }
          tags {
            data {
              attributes {
                name
                icon {
                  data {
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
          category {
            data {
              attributes {
                name
                icon {
                  data {
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
          author {
            data {
              attributes {
                username
                avatar {
                  data {
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
