import { useFormik } from 'formik'
import { useEffect } from 'react'
import styled from 'styled-components'
import {
  A7ACenteredRow,
  A7ARow,
  A7AXCenteredColumn,
  A7AXSpaceBetweenRow,
  A7AYCenteredRow,
} from '../../../utils/Container'
import { useMediaQuery } from '../../../utils/useMediaQuery'
import { GenericButton } from '../../common/button'
import {
  A7AInputField,
  A7ASelectionInput,
  A7ATagsInput,
  A7ATextArea,
} from '../../common/Input'
import BriefCase from '../../common/svg/common/BriefCase'
import Calender from '../../common/svg/common/Calender'
import CrossIcon from '../../common/svg/common/CrossIcon'
import Email from '../../common/svg/common/Email'
import Globe from '../../common/svg/common/Globe'
import HeartBreak from '../../common/svg/common/HeartBreak'
import Person from '../../common/svg/common/Person'
import RightArrow from '../../common/svg/common/RightArrow'
import Signal from '../../common/svg/common/Signal'
import StackOverflow from '../../common/svg/common/StackOverflow'
import Telephone from '../../common/svg/common/Telephone'
import SizedBox from '../../common/ui/SizedBox'
import TiltedBar from '../../common/ui/TiltedBar'

const HiringModalWrapper = styled(A7ACenteredRow)`
  min-height: 100vh;
  overflow-y: scroll;

  @media (max-height: 810px) {
    padding-top: 150px;
  }

  @media (max-height: 700px) {
    padding-top: 300px;
  }

  @media (max-height: 600px) {
    padding-top: 400px;
  }
`

const HiringModalContainer = styled.div`
  max-width: 95vw;
  margin: 0 auto;
  border-radius: 12px;
  z-index: 4;
  background-color: ${(props) => props.theme.colors.background};
  padding: 20px;
  overflow-y: auto;
  ${(props) => props.theme.mediaQuery.tablet} {
    max-width: 577px;
    padding: 40px;
  }
`

const HiringModalHeaderContainer = styled(A7AXSpaceBetweenRow)`
  align-items: center;
`

const HiringModalTitle = styled.h1`
  margin: 0px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.textColors[0]};
  font-size: 24px;
  line-height: 32px;
  ${(props) => props.theme.mediaQuery.tablet} {
    font-size: 36px;
    line-height: 49px;
  }
`

const HiringTitle = styled.h3`
  margin: 0px;
  font-size: 20px;
  line-height: 27px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.textColors[0]};
  ${(props) => props.theme.mediaQuery.tablet} {
    font-size: 24px;
    line-height: 32px;
  }
`

const HiringIconStatsContainer = styled(A7AYCenteredRow)`
  flex-wrap: wrap;
  gap: 10px;
`

const HiringIconTextContainer = styled(A7ARow)`
  gap: 5px;
  color: ${({ theme }) => theme.colors.textColors[0]};
  span {
    margin-top: 2px;
  }
`

const CrossIconContainer = styled(A7ACenteredRow)`
  cursor: pointer;
  padding: 0px;
  border-radius: 50%;
  ${(props) => props.theme.mediaQuery.tablet} {
    width: 50px;
    height: 50px;
    border: 1px solid ${(props) => props.theme.colors.borderColor2};
  }
`

const RightText = styled.span`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  user-select: none;
  /* identical to box height, or 143% */

  letter-spacing: 0.03em;

  /* Gradient */

  background: linear-gradient(90deg, #26a7cf 0%, #27af8f 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
`

const JobForm = styled(A7AXCenteredColumn)`
  gap: 15px;
  width: 100%;
`
interface IHiringModalProps {
  isOpened: boolean
  data: {
    id: number
    title: string
    time: string
    location: string
    jobType: string
    jobDesignation: string
  }
  closeModal: () => void
}
export const HiringModal = ({
  isOpened,
  closeModal,
  data,
}: IHiringModalProps) => {
  const isMobile = useMediaQuery('(max-width: 500px)')

  const formik = useFormik({
    initialValues: {
      name: '',
      intro: '',
      email: '',
      phone: '',
      techStack: '',
      hateStack: '',
      avaliability: '',
      timeZone: '',
    },
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2))
    },
  })

  const validate = (): boolean => {
    if (
      !formik.values.name ||
      !formik.values.intro ||
      !formik.values.phone ||
      !formik.values.techStack ||
      !formik.values.hateStack ||
      !formik.values.avaliability ||
      !formik.values.timeZone ||
      !formik.values.email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    )
      return false
    else return true
  }

  useEffect(() => {
    if (isOpened) {
      document.body.style.overflowY = 'hidden'
    } else {
      document.body.style.overflowY = 'auto'
    }
  }, [isOpened])

  return (
    <>
      <HiringModalWrapper>
        <HiringModalContainer>
          <HiringModalHeaderContainer>
            <A7ACenteredRow>
              <TiltedBar />
              <SizedBox height={0} width={20} />
              <HiringModalTitle>Apply job</HiringModalTitle>
            </A7ACenteredRow>
            <CrossIconContainer onClick={closeModal}>
              <CrossIcon />
            </CrossIconContainer>
          </HiringModalHeaderContainer>
          <SizedBox height={isMobile ? 28 : 32} width={0} />
          <HiringTitle>{data.title}</HiringTitle>
          <SizedBox height={isMobile ? 10 : 15} width={0} />
          <HiringIconStatsContainer>
            <HiringIconTextContainer>
              <Globe />
              <span>{data.location}</span>
            </HiringIconTextContainer>

            <HiringIconTextContainer>
              <BriefCase />
              <span>{data.jobType}</span>
            </HiringIconTextContainer>

            <HiringIconTextContainer>
              <Signal />
              <span>{data.jobDesignation}</span>
            </HiringIconTextContainer>
          </HiringIconStatsContainer>
          <SizedBox height={30} width={0} />
          <JobForm as={'form'} onSubmit={formik.handleSubmit}>
            <A7AInputField
              icon={<Person />}
              placeholder={'Name'}
              id={'name'}
              inputMode="text"
              type={'text'}
              onChange={formik.handleChange}
              value={formik.values.name}
            />
            <A7ATextArea
              placeholder={'Tell us more about you'}
              id={'intro'}
              type={'text'}
              inputMode="text"
              onChange={formik.handleChange}
              value={formik.values.intro}
            />
            <A7AInputField
              icon={<Email />}
              placeholder={'Your email address'}
              id={'email'}
              type={'email'}
              inputMode="email"
              onChange={formik.handleChange}
              value={formik.values.email}
            />
            <A7AInputField
              icon={<Telephone />}
              placeholder={'Phone number'}
              id={'phone'}
              inputMode="tel"
              type={'number'}
              onChange={formik.handleChange}
              value={formik.values.phone}
            />
            <A7ATagsInput
              icon={<StackOverflow />}
              placeholder={'Technical stack'}
              id={'techStack'}
              inputMode="text"
              type={'text'}
              onChange={formik.handleChange}
              value={formik.values.techStack}
            />
            <A7ATagsInput
              icon={<HeartBreak />}
              placeholder={'Technologies you don’t like'}
              id={'hateStack'}
              type={'text'}
              inputMode="text"
              onChange={formik.handleChange}
              value={formik.values.hateStack}
            />
            <A7AInputField
              icon={<BriefCase style={{ paddingRight: 5 }} />}
              placeholder={'Number of available hours per week'}
              id={'avaliability'}
              inputMode="numeric"
              onChange={formik.handleChange}
              value={formik.values.avaliability}
            >
              <RightText>HOURS/WEEK</RightText>
            </A7AInputField>
            <A7ASelectionInput
              icon={<Calender />}
              placeholder={'Time Zone'}
              id={'timeZone'}
              inputMode="numeric"
              type={'text'}
              option={['Volvo', 'Saab', 'Mercedes', 'Audi']}
              onChange={formik.handleChange}
              value={formik.values.timeZone}
            />
            <SizedBox height={isMobile ? 20 : 30} width={0} />
            <A7ACenteredRow>
              <GenericButton
                text={'SUBMIT MESSAGE'}
                height={50}
                type="submit"
                width={221}
                isFullWidth={false}
                isDisabled={!validate()}
                isProcessing={false}
                onClick={() => {}}
              >
                <SizedBox width={18} height={0} />
                <RightArrow color={validate() ? 'white' : '#848DA0'} />
              </GenericButton>
            </A7ACenteredRow>
          </JobForm>
        </HiringModalContainer>
      </HiringModalWrapper>
    </>
  )
}
