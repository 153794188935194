import { SVGProps } from "react"

const Share = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={17}
    height={15}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.152 3.89V1.28c0-.226.086-.444.239-.604.153-.16.36-.25.577-.25.214 0 .42.09.571.247l5.715 5.987a.892.892 0 0 1 0 1.215l-5.715 5.987a.782.782 0 0 1-.886.178.826.826 0 0 1-.364-.313.884.884 0 0 1-.137-.472v-2.652h-.694a8.544 8.544 0 0 0-3.747.804 8.929 8.929 0 0 0-3.078 2.378.818.818 0 0 1-.407.302.781.781 0 0 1-.907-.322.884.884 0 0 1-.148-.51c0-7.8 6.597-9.135 8.98-9.366Zm-.694 4.986c.546-.002 1.091.035 1.632.11.194.032.37.134.497.29a.88.88 0 0 1 .197.558v1.36l3.74-3.926-3.74-3.926v1.36a.876.876 0 0 1-.239.605.798.798 0 0 1-.577.25c-.743 0-6.62.171-7.894 5.5a10.348 10.348 0 0 1 6.384-2.181Z"
      fill="#fff"
    />
  </svg>
)

export default Share
