import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import useSelectorTyped from "../../../store/useSelectorTyped";
import { SliderButton } from './SliderButton';

const SliderContainer = styled.button`
  background-color: transparent;
  width: 50px;
  height: 30px;
  display: flex;
  border-radius: 30px;
  position: relative;
  outline: none;
  cursor: pointer;
  border: none;
  background-color: ${(props) => props.theme.colors.sliderTrackColor};
  outline: 1px solid transparent;
  :focus {
    border-radius: 30px;
    outline: 3px solid ${(props) => `${props.theme.colors.primary}60`};
  }
`;

export const ThemeSlider = () => {
  const theme = useSelectorTyped((state) => state?.fromClient?.theme);
  const dispatch = useDispatch();

  const handleToggleTheme = () => {
    if (theme == 0) {
      dispatch({ type: "TOGGLE_THEME", payload: 1 });
      document.body.classList.add("dark");
      document.body.classList.remove("light");
    } else {
      dispatch({ type: "TOGGLE_THEME", payload: 0 });
      document.body.classList.remove("dark");
      document.body.classList.add("light");
    }
  };

  return (
    <SliderContainer onClick={handleToggleTheme} aria-label="theme-slider">
      <SliderButton />
    </SliderContainer>
  );
};