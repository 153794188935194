import { SVGProps } from 'react'
import useSelectorTyped from '../../../../../store/useSelectorTyped'

const NextJS = (props: SVGProps<SVGSVGElement>) => {
  const theme = useSelectorTyped((state) => state?.fromClient?.theme)
  return (
    <svg
      width={21}
      height={21}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.416 13.762v-6l7.745 10.65a9 9 0 1 1 2.255-1.993m-4-5.657v-3"
        stroke={theme === 1 ? "#A9B6BD" : "#FFFFFF"}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default NextJS
