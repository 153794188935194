import Link from 'next/link'
import { useEffect, useState } from 'react'
import { useTranslation } from 'next-i18next'
import styled from 'styled-components'
import {
  A7ACenteredColumn,
  A7ACenteredRow,
  A7AColumn,
  A7ARow,
} from '../../../utils/Container'
import { useMediaQuery } from '../../../utils/useMediaQuery'
import { SocialButton } from '../../common/button'
import { NavbarProps } from '../../common/navbar'
import A7ALogo from '../../common/svg/common/logo/A7ALogo'
import Github from '../../common/svg/common/social/Github'
import Instagram from '../../common/svg/common/social/Instagram'
import Twitter from '../../common/svg/common/social/Twitter'
import SizedBox from '../../common/ui/SizedBox'
import TiltedBar from '../../common/ui/TiltedBar'
import Discord from '../../common/svg/common/social/Discord'
import LetsTalkForm from './LetsTalkForm'
import Image from 'next/image'

const FooterWrapper = styled(A7ACenteredColumn).attrs({ as: 'footer' })`
  padding-top: 30px;
  justify-content: flex-start;
  border-radius: 20px 20px 0px 0px;
  width: 100%;
  position: relative;
  z-index: 0;
  img {
    object-fit: cover;
  }

  ${(props) => props.theme.mediaQuery.tablet} {
    padding: 60px 0px 36px 0px;
  }
`

const LetsTalkContainer = styled.div`
  z-index: 1;
  margin: ${(props) => props.theme.constrainedMargin};
  max-width: 580px;
`

const LetsTalkHeaderWrapper = styled(A7ACenteredColumn)`
  max-width: ${(props) => props.theme.desktopWidth};
`

const LetsTalkHeaderContainer = styled(A7ACenteredRow)`
  width: 100%;
`

const LetsTalkHeading = styled.h1`
  margin: 0px;
  font-weight: 500;
  color: white;
  font-size: 24px;
  line-height: 32px;
  ${(props) => props.theme.mediaQuery.tablet} {
    font-size: 36px;
    line-height: 49px;
  }
`

const LetsTalkDescription = styled.p`
  font-family: 'Lato';
  font-style: normal;
  margin: 0px;
  margin-top: 34px;
  font-weight: 300;
  font-size: 14px;
  text-align: center;
  line-height: 17px;
  ${(props) => props.theme.mediaQuery.tablet} {
    margin-top: 30.5px;
    font-size: 16px;
    line-height: 19px;
  }
  color: #fff;
`

const FooterInnerWrapper = styled.div`
  margin: 30px;
  width: calc(100% - 60px);
  background: rgba(20, 23, 29, 0.5);
  backdrop-filter: blur(5px);
  background-blend-mode: multiply;
  border-radius: ${(props) => props.theme.borderRadius};
  ${(props) => props.theme.mediaQuery.tablet} {
    margin: 60px 30px 0px 30px;
    max-width: 1280px;
  }
`

const FooterContainer = styled.div`
  display: grid;
  grid-template-areas:
    'logo .  link'
    'social .  link'
    'social .  link '
    'copyright copyright copyright';
  padding: 20px;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: 78px 37.5px auto;
  ${(props) => props.theme.mediaQuery.tablet} {
    margin-top: 60px;
    grid-template-areas:
      ' link link link link link'
      ' logo . . . copyright ';
    padding: 30px;
    width: 100%;
    margin: 0 auto;
    max-width: 1100px;
    align-items: center;
    row-gap: 55px;
    grid-template-columns: auto;
    grid-template-rows: auto;
  }
`

const FooterLogo = styled.div`
  grid-area: logo;
  height: 70px;
  ${(props) => props.theme.mediaQuery.tablet} {
    margin-right: 20px;
    width: 78px;
  }
`

const FooterSocial = styled(A7ARow)`
  grid-area: social;
  gap: 6px;
  ${(props) => props.theme.mediaQuery.fold} {
    flex-wrap: wrap;
  }
  ${(props) => props.theme.mediaQuery.tablet} {
    grid-area: logo;
    margin-left: 100px;
  }
`

const FooterCopyright = styled.div`
  z-index: 1;
  grid-area: copyright;
  text-align: right;
  h1 {
    margin: 0px;
    font-weight: 500;
    font-size: 13.5044px;
    line-height: 167.8%;
  }
  p {
    font-weight: 300;
    font-size: 13.5044px;
    line-height: 16px;
    margin: 0px;
  }
`

const FooterNavLinks = styled(A7AColumn)`
  grid-area: link;
  text-align: right;
  margin: 0px;
  gap: 10px;
  ${(props) => props.theme.mediaQuery.tablet} {
    grid-gap: 15px;
    width: 100%;
    flex-direction: row;
    justify-content: center;
  }
`

const FooterNavLink = styled(A7ACenteredColumn)<{ isActive: boolean }>`
  align-items: flex-end;
  text-align: right;
  cursor: pointer;
  position: relative;
  p {
    padding: 0px;
    font-family: 'LEMON MILK';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    background: ${(props) =>
      props.isActive ? props.theme.colors.accent : '#fff'};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    white-space: nowrap;
    ${(props) => props.theme.mediaQuery.tablet} {
      padding-bottom: 10px;
    }
    ${(props) =>
      props.isActive &&
      `
      border-image: linear-gradient(90deg, #26A7CF 0%, #27AF8F 100%) 1;
      border-width: 4px;
      border-style: solid;
      border-top: none;
      border-right: none;
      border-left: none;
      border-radius: 5px;
      cursor: pointer;
    `};
  }
`

const Footer = ({ activeIndex }: NavbarProps) => {
  const [activIndexState, setActiveIndexState] = useState(1)
  const isTablet = useMediaQuery('(min-width: 767px)')
  const { t } = useTranslation('common')
  useEffect(() => {
    setActiveIndexState(activeIndex)
  }, [activeIndex])

  return (
    <>
      <FooterWrapper id="contact">
        <Image src="/images/LetsTalkBackground.webp" alt="footer background" fill loading='lazy'/>
        <LetsTalkContainer>
          <LetsTalkHeaderWrapper>
            <LetsTalkHeaderContainer>
              <A7ACenteredRow>
                <TiltedBar />
                <SizedBox height={0} width={20} />
                <LetsTalkHeading>{t('footer.0.footerTitle')}</LetsTalkHeading>
              </A7ACenteredRow>
            </LetsTalkHeaderContainer>
            <LetsTalkDescription>
              {t('footer.2.footerDescription')}
            </LetsTalkDescription>
          </LetsTalkHeaderWrapper>
          <SizedBox height={28} width={0} />
          <LetsTalkForm />
        </LetsTalkContainer>
        <FooterInnerWrapper>
          <FooterContainer>
            <FooterLogo>
              <A7ALogo
                props={{ height: '65', width: '78' }}
                changeTheme={false}
              />
            </FooterLogo>
            <FooterSocial>
              <a
                href={'https://github.com/A7ALABS'}
                target="_blank"
                rel="noreferrer"
              >
                <SocialButton
                  ariaLabel="Github"
                  height={37.5}
                  width={37.5}
                  onClick={() => {}}
                  color="#fff"
                >
                  <Github fill="#27AF8F" />
                </SocialButton>
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href={'https://instagram.com/A7ALABS'}
              >
                <SocialButton
                  ariaLabel="Instagram"
                  height={37.5}
                  width={37.5}
                  onClick={() => {}}
                  color="#fff"
                >
                  <Instagram fill="#27AF8F" />
                </SocialButton>
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href={'https://twitter.com/A7ALABS'}
              >
                <SocialButton
                  ariaLabel="Twitter"
                  height={37.5}
                  width={37.5}
                  onClick={() => {}}
                  color="#fff"
                >
                  <Twitter fill="#27AF8F" />
                </SocialButton>
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href={'https://discord.gg/xtDG9CVzgU'}
              >
                <SocialButton
                  ariaLabel="Discord"
                  height={37.5}
                  width={37.5}
                  onClick={() => {}}
                  color="#fff"
                >
                  <Discord fill="#27AF8F" />
                </SocialButton>
              </a>
            </FooterSocial>
            <FooterNavLinks>
              <Link legacyBehavior scroll={false} href={'/?section=labs'}>
                <a>
                  <FooterNavLink isActive={activIndexState === 1}>
                    <p>{t(`navbar.0.Labs`)}</p>
                  </FooterNavLink>
                </a>
              </Link>

              <Link legacyBehavior scroll={false} href={'/?section=projects'}>
                <a>
                  <FooterNavLink isActive={activIndexState === 2}>
                    <p>{t(`navbar.1.Projects`)}</p>
                  </FooterNavLink>
                </a>
              </Link>

              <Link legacyBehavior scroll={false} href={'/?section=tech-stack'}>
                <a>
                  <FooterNavLink isActive={activIndexState === 3}>
                    <p onClick={() => {}}>{t(`navbar.2.Tech-Stack`)}</p>
                  </FooterNavLink>
                </a>
              </Link>

              {/* <Link legacyBehavior href={"/team"}>
                <a>
                  <FooterNavLink isActive={activIndexState === 4}>
                    <p onClick={() => {}}>{t(`navbar.6.Team`)}</p>
                  </FooterNavLink>
                </a>
              </Link> */}

              <Link legacyBehavior scroll={false} href={'/?section=reviews'}>
                <a>
                  <FooterNavLink isActive={activIndexState === 4}>
                    <p onClick={() => {}}>{t(`navbar.3.Reviews`)}</p>
                  </FooterNavLink>
                </a>
              </Link>

              <Link legacyBehavior href={'/articles'}>
                <a>
                  <FooterNavLink isActive={activIndexState === 5}>
                    <p onClick={() => {}}>{t(`navbar.4.Articles`)}</p>
                  </FooterNavLink>
                </a>
              </Link>

              <Link legacyBehavior scroll={false} href={'/?section=career'}>
                <a>
                  <FooterNavLink isActive={activIndexState === 6}>
                    <p onClick={() => {}}>{t(`navbar.5.Career`)}</p>
                  </FooterNavLink>
                </a>
              </Link>
            </FooterNavLinks>
            {isTablet && (
              <FooterCopyright>
                <h1>Copyright {new Date().getFullYear()} A7A Labs</h1>
                <p>All rights reserved!</p>
              </FooterCopyright>
            )}
          </FooterContainer>
        </FooterInnerWrapper>
        {!isTablet && (
          <FooterCopyright style={{ textAlign: 'center' }}>
            <SizedBox height={30} width={0} />
            <h1>Copyright {new Date().getFullYear()} A7A Labs</h1>
            <p>All rights reserved!</p>
            <SizedBox height={30} width={0} />
          </FooterCopyright>
        )}
      </FooterWrapper>
    </>
  )
}

export default Footer
