import styled from 'styled-components'

const ToolTipContainer = styled.div<{
  top: number
  left: number
  right?: number
  bottom?: number
  backgroundColor?: string
  textColor?: string
  textSize?: number
}>`
  display: none;
  visibility: hidden;
  width: 42px;
  border: 1px solid ${(props) => props.theme.colors.borderColor};
  background-color: ${(props) =>
    props.backgroundColor ?? props.theme.colors.background};
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: ${(props) => props.top}%;
  left: ${(props) => props.left}%;
  right: ${(props) => props.right ?? 'inital'}%;
  bottom: ${(props) => props.bottom ?? 'inital'}%;
  &::after {
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: ${(props) => props.theme.colors.borderColor} transparent
      transparent transparent;
  }
  font-family: 'LEMON MILK';
  color: ${(props) => props.theme.colors.accent2};
  font-weight: 400;
  ${(props) => props.theme.mediaQuery.tablet} {
    display: block;
  }
`
interface ToolTipProps {
  children: React.ReactNode
  top: number
  left: number
  right?: number
  bottom?: number
  backgroundColor?: string
  textColor?: string
  textSize?: number
}
/**
 * @description Add this to the parent componet to show the tooltip on hover
 * <br>`:hover {`<br>
 * `  .hover-content {`<br>
 * `    visibility: visible;`<br>
 * `  }`<br>
 * `}`<br>
 **/
export const ToolTip = ({
  children,
  top,
  bottom,
  left,
  right,
  backgroundColor,
  textColor,
  textSize
}: ToolTipProps) => {
  return (
    <>
      <ToolTipContainer
        className="tooltip"
        top={top}
        right={right}
        bottom={bottom}
        left={left}
        backgroundColor={backgroundColor}
        textColor={textColor}
        textSize={textSize}
      >
        {children}
      </ToolTipContainer>
    </>
  )
}
