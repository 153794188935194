import React from "react";
import styled from "styled-components";

const SizedBoxWrapper = styled.div<{
  height: number;
  width: number;
}>`
  height: ${(props) => props.height}px;
  width: ${(props) => props.width}px;
`;

interface SizedBoxPops {
  height: number;
  width: number;
}

const SizedBox = ({ width, height }: SizedBoxPops): JSX.Element => {
  return <SizedBoxWrapper height={height} width={width} />;
};

export default SizedBox;
