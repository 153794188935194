import styled from "styled-components";
import { motion } from "framer-motion";

const SocialButtonWrapper = styled(motion.button)<{
  color?: string;
  width?: number;
  border?: string;
  height?: number;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => (props.width ? props.width + "px" : "45px")};
  height: ${(props) => (props.height ? props.height + "px" : "45px")};
  cursor: pointer;
  border-radius: 50%;
  border: none;
  background-color: ${(props) => (props.color ? props.color : "inital")};
  background-image: ${(props) =>
    props.color ? "inital" : props.theme.colors.accent};
  border: ${props => props.border ? props.border : "none"};
  ${(props) => props.theme.mediaQuery.tablet} {
    width: ${(props) => (props.width ? props.width + "px" : "50px")};
    height: ${(props) => (props.height ? props.height + "px" : "50px")};
  }
`;

interface ISocialButtonProps {
  onClick: () => void;
  color?: string;
  height?: number;
  width?: number;
  border?: string;
  children: JSX.Element[] | JSX.Element;
  ariaLabel?: string;
}
const SocialButton = ({
  onClick,
  children,
  color,
  height,
  border,
  width,
  ariaLabel,
}: ISocialButtonProps) => {
  return (
    <>
      <SocialButtonWrapper
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.9 }}
        onClick={onClick}
        color={color}
        width={width}
        height={height}
        border={border}
        aria-label={ariaLabel}
      >
        {children}
      </SocialButtonWrapper>
    </>
  );
};

export default SocialButton;
