import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { A7ACenteredColumn } from "../../../utils/Container";
import { useMediaQuery } from "../../../utils/useMediaQuery";

interface IA7ACategoryNavigator {
  items: any[];
  onChangeActive: (index: number) => void;
  activeIndexState: number;
}

export const NavbarItemContainer = styled(A7ACenteredColumn)<{
  isActive: boolean;
}>`
  cursor: pointer;
  display: inline-block;
  margin-right: 20px;
  ${(props) => props.theme.mediaQuery.tablet} {
    display: block;
    margin-right: 0;
  }
  position: relative;
  p {
    // margin-bottom: 10px;
    font-weight: normal;
    font-style: normal;
    font-size: 12px;
    line-height: 16px;
    font-family: "LEMON MILK";
    background: ${(props) =>
      props.isActive
        ? props.theme.colors.accent
        : props.theme.colors.textColors[0]};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    white-space: nowrap;
  }
`;

export const NavbarActiveIndicator = styled.div<{
  isActive: boolean;
  width: number;
  x: number;
  y: number;
}>`
  border-image: linear-gradient(90deg, #26a7cf 0%, #27af8f 100%) 1;
  border-radius: 8px;
  cursor: pointer;
  position: absolute;
  height: 4px;
  top: ${(props) => props.y + 16}px;
  width: ${(props) => props.width}px;
  left: ${(props) => props.x}px;
  bottom: ${(props) => props.y}px;
  visibility: ${(props) => (!props.isActive ? "hidden" : "visible")};
  background: ${(props) => props.theme.colors.accent};
  justify-self: center;
  transition: left 0.8s, width 2s;
  z-index: 3;
  ${(props) => props.theme.mediaQuery.tablet} {
    top: ${(props) => props.y + 36}px;
  }
`;

const A7ACategoryNavigator = React.forwardRef(
  (
    { items, activeIndexState, onChangeActive }: IA7ACategoryNavigator,
    ref: any
  ): JSX.Element => {
    const refs = useRef<any>([]);
    const [widthState, setWidthState] = useState(0);
    const [xState, setXState] = useState(0);
    const [yState, setYState] = useState(0);
    const isMobile = useMediaQuery("(max-width: 767px)");

    const handleUpdatePosition = () => {
      setWidthState(
        refs.current[activeIndexState]?.getBoundingClientRect()?.width || 0
      );
      setXState(
        refs.current[activeIndexState]?.getBoundingClientRect()?.left -
          ref?.current?.getBoundingClientRect().x || 0
      );
      setYState(
        isMobile
          ? ref?.current?.getBoundingClientRect().height -
              refs.current[activeIndexState]?.getBoundingClientRect()?.height +
              10
          : refs.current[activeIndexState]?.getBoundingClientRect()?.height -
              10 || 0
      );
    };

    useEffect(() => {
      try {
        let resizeEvent: any;
        let scrollEvent: any;

        handleUpdateNavIndicatorPositionWithDelay(10);
        resizeEvent = window.addEventListener("resize", (v) => {
          handleUpdateNavIndicatorPositionWithDelay(200);
        });
        scrollEvent = window.addEventListener("scroll", (v) => {
          try {
            if (ref && ref.current && getComputedStyle(ref?.current).opacity == "1") {
              window.removeEventListener("scroll", scrollEvent);
              handleUpdateNavIndicatorPositionWithDelay(100);
            } else {
              setWidthState(0);
            }
          } catch (e) {}
        });

        return () => {
          window.removeEventListener("resize", resizeEvent);
          window.removeEventListener("scroll", scrollEvent);
        };
      } catch (e) {}
    }, [activeIndexState, isMobile, ref]);

    const handleUpdateNavIndicatorPositionWithDelay = (ms: number) => {
      setTimeout(() => {
        handleUpdatePosition();
      }, ms);
    };

    return (
      <>
        <NavbarActiveIndicator
          isActive={true}
          width={widthState}
          x={xState}
          y={yState}
        />
        {items.map((item, index) => (
          <NavbarItemContainer
            key={index}
            ref={(r) => refs.current.push(r)}
            onClick={() => onChangeActive(index)}
            isActive={activeIndexState === index}
          >
            <p>{item.name}</p>
          </NavbarItemContainer>
        ))}
      </>
    );
  }
);

A7ACategoryNavigator.displayName = "A7ACategoryNavigator";

export default A7ACategoryNavigator;
