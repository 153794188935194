import { SVGProps } from "react";
import useSelectorTyped from "../../../../../store/useSelectorTyped";
interface IA7ALogoProps {
  props?: SVGProps<SVGSVGElement>;
  changeTheme?: boolean;
}
const A7ALogo = ({ props, changeTheme = true }: IA7ALogoProps) => {
  const theme = useSelectorTyped((state) => state?.fromClient?.theme);
  return (
    <>
      <svg
        viewBox="0 0 79 66"
        width={79}
        height={66}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.136 11.293 1.106 54.226h10.473l13.557-24.957 12.133 24.957h3.383v-7.343h4.183L25.177 11.215l-.041.078Z"
          fill={changeTheme ? (theme === 0 ? "#fff" : "#13161D") : "#fff"}
        />
        <path
          d="m30.533 54.226 24.03-42.933.041-.078 23.706 43.01H66.697L54.564 29.27 41.007 54.226H30.533Z"
          fill={changeTheme ? (theme === 0 ? "#fff" : "#13161D") : "#fff"}
        />
        <path
          d="M36.355 10.985 30.369.438H72.51l-30.76 53.79H29.104l25.65-43.243h-18.4Z"
          fill="url(#a)"
        />
        <path
          d="M2.84 63.543v-6.748H1.144v8.433h5.084v-1.685H2.84Zm26.71 1.685h1.836l-4.033-8.642H27.3l-4.029 8.642h1.831l.54-1.177h3.373l.534 1.177Zm-3.249-2.621 1.03-2.255 1.026 2.255h-2.056Zm26.601-2.13c.204 0 .795-.554.795-1.448 0-1.136-.952-2.234-2.448-2.234h-2.302v8.433h3.186c1.737 0 2.631-1.313 2.631-2.558 0-1.355-1.051-2.192-1.862-2.192Zm-1.548-2.034c.392 0 .763.335.763.81 0 .492-.392.838-.795.838h-.685v-1.648h.717Zm.622 5.132h-1.34v-1.999h1.335c.806 0 1.14.487 1.14.973 0 .649-.586 1.026-1.135 1.026Zm22.98 1.82c1.806 0 3.061-.978 3.061-2.516 0-1.297-.879-2.009-2.275-2.668-.968-.455-1.607-.628-1.607-1.13 0-.434.471-.8 1.104-.8.471 0 .958.204 1.209.706l1.25-.874c-.382-1.02-1.334-1.496-2.485-1.496-1.548 0-2.783.963-2.783 2.422 0 1.345 1.041 1.862 1.988 2.333.884.44 1.894.926 1.894 1.544 0 .45-.534.774-1.33.774-.726 0-1.265-.272-1.636-.869l-1.355.953c.565 1.046 1.585 1.621 2.966 1.621Z"
          fill="url(#b)"
        />
        <defs>
          <linearGradient
            id="a"
            x1={29.104}
            y1={54.228}
            x2={72.511}
            y2={54.228}
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#26A7CF" />
            <stop offset={1} stopColor="#27AF8F" />
          </linearGradient>
          <linearGradient
            id="b"
            x1={0.412}
            y1={68.228}
            x2={79.012}
            y2={68.228}
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#26A7CF" />
            <stop offset={1} stopColor="#27AF8F" />
          </linearGradient>
        </defs>
      </svg>
    </>
  );
};

export default A7ALogo;
