import { SVGProps } from 'react'
import useSelectorTyped from '../../../../../store/useSelectorTyped'
const Golang = (props: SVGProps<SVGSVGElement>) => {
  const theme = useSelectorTyped((state) => state?.fromClient?.theme)
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={30}
      height={12}
      viewBox="0 0 30 12"
      fill="none"
      {...props}
    >
      <path
        fill={theme === 1 ? '#A9B6BD' : '#FFFFFF'}
        d="M18.755 3.15c-.511.13-.933.201-1.364.356-.343.089-.69.182-1.088.286l-.028.004c-.197.057-.216.061-.398-.15-.22-.248-.38-.408-.685-.558-.923-.45-1.814-.318-2.644.22-.993.643-1.504 1.595-1.49 2.776.014 1.172.815 2.133 1.931 2.292 1.031.131 1.866-.216 2.522-.961a7 7 0 0 0 .286-.39c.037-.046.07-.097.108-.154h-2.817c-.305 0-.38-.187-.277-.436.187-.454.539-1.214.745-1.593.042-.085.146-.272.324-.272h4.739c.21-.628.553-1.261 1.012-1.861C20.695 1.307 21.942.576 23.72.27c1.49-.262 2.892-.117 4.167.746 1.153.787 1.866 1.856 2.058 3.257.248 1.974-.324 3.577-1.72 4.95-.924.98-2.105 1.594-3.465 1.87-.262.048-.52.071-.773.094-.136.01-.267.024-.399.038-1.326-.028-2.54-.408-3.562-1.284-.717-.624-1.214-1.388-1.458-2.274a4.471 4.471 0 0 1-.656.99c-1.013 1.387-2.386 2.25-4.12 2.479-1.435.192-2.761-.085-3.933-.961-1.078-.82-1.692-1.898-1.852-3.244-.192-1.594.277-3.065 1.238-4.28 1.04-1.359 2.414-2.221 4.092-2.526 1.373-.29 2.686-.09 3.872.717.773.511 1.326 1.21 1.692 2.058.09.131.028.206-.145.248Zm-16.49.262c-.06 0-.074-.028-.045-.075l.307-.394c.03-.042.102-.07.16-.07h5.216c.056 0 .07.042.042.084l-.248.38a.213.213 0 0 1-.145.089l-5.288-.014ZM.057 4.757c-.058 0-.073-.033-.043-.075l.306-.394c.03-.042.103-.075.16-.075h6.663c.056 0 .084.047.07.09l-.117.351c-.014.056-.07.09-.131.09l-3.454.006-3.454.007ZM3.55 6.013c-.029.043-.014.085.044.085l3.185.014c.042 0 .103-.042.103-.099l.028-.351c0-.061-.028-.103-.089-.103H3.9c-.059 0-.117.042-.146.089l-.205.365Zm23.507-.843c-.01-.122-.014-.225-.033-.328-.262-1.444-1.593-2.264-2.98-1.941-1.36.305-2.237 1.167-2.555 2.54-.263 1.14.29 2.293 1.34 2.762.806.351 1.608.309 2.381-.09 1.154-.637 1.782-1.532 1.857-2.789-.005-.056-.005-.107-.01-.154Z"
      />
    </svg>
  )
}
export default Golang
