import { CSSProperties, SVGProps } from "react";
export interface IArrowSVG {
  props?: SVGProps<SVGSVGElement>;
  color?: string;
  styles?: CSSProperties;
}
const LeftArrow = ({ props, color, styles }: IArrowSVG) => (
  <svg
    width={8}
    height={14}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    style={{...styles}}
  >
    <path
      d="M6.755.26c.258 0 .507.077.706.218.111.08.203.177.271.287a.82.82 0 0 1 .078.722.915.915 0 0 1-.206.325l-4.94 5.072 4.763 5.081c.092.097.16.208.202.328a.818.818 0 0 1-.09.722.999.999 0 0 1-.277.284 1.161 1.161 0 0 1-.392.191 1.277 1.277 0 0 1-.879-.077 1.069 1.069 0 0 1-.338-.256L.328 7.48a.868.868 0 0 1-.251-.6c0-.22.088-.432.25-.602L5.84.601c.11-.114.251-.205.41-.264.159-.06.332-.085.505-.077Z"
      fill={color ? color : "#fff"}
    />
  </svg>
);

export default LeftArrow;
