import { SVGProps } from "react";

const BriefCase = (props: SVGProps<SVGSVGElement>) => {
  return (
    <>
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M19.0005 7.76514H5.00049C3.89592 7.76514 3.00049 8.66057 3.00049 9.76514V17.7651C3.00049 18.8697 3.89592 19.7651 5.00049 19.7651H19.0005C20.1051 19.7651 21.0005 18.8697 21.0005 17.7651V9.76514C21.0005 8.66057 20.1051 7.76514 19.0005 7.76514Z"
          stroke="#A9B6BD"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.0005 12.7651L10.2115 12.8711C10.7669 13.1489 11.3795 13.2935 12.0005 13.2935C12.6215 13.2935 13.234 13.1489 13.7895 12.8711L14.0005 12.7651M9.00049 6.76514C9.00049 6.2347 9.2112 5.726 9.58628 5.35092C9.96135 4.97585 10.4701 4.76514 11.0005 4.76514H13.0005C13.5309 4.76514 14.0396 4.97585 14.4147 5.35092C14.7898 5.726 15.0005 6.2347 15.0005 6.76514V7.76514H9.00049V6.76514Z"
          stroke="#A9B6BD"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default BriefCase;
