const path = require('path');

module.exports = {
  i18n: {
    defaultLocale: "en-US",
    locales: ["en-US", "fr-FR", "zh-CN" , "es-ES" , "hi-IN" , "ro-RO" , "it-IT"],
    localeDetection: true,
  },
  localePath: path.resolve("./public/locales"),
  keySeparator: ".",
  nsSeparator: ":",
};
