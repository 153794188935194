import { SVGProps } from "react"

const Person = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={19}
    height={19}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.5 8.935a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM5 16.435v-1.5a3 3 0 0 1 3-3h3a3 3 0 0 1 3 3v1.5"
      stroke="#A9B6BD"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Person