import { ThemeWeb } from "styled-components";

const lightThemeWeb: ThemeWeb = {
  borderRadius: "20px",

  colors: {
    background: "#FFFFFF",
    primary: "#F3F8FB",
    secondary: "#E4E9F3",
    secondary3: "#F4F8FB",
    secondary2: "#A9B6BD",
    accent: "linear-gradient(90deg, #26A7CF 0%, #27AF8F 100%)",
    accent2: "#5BBF96",
    textColors: ["#13181D", "#14171D", "#737373"],
    sliderTrackColor: "#EFEFEF",
    sliderBallColor: "#1E1E1E",
    heroBackground: "#F8F8F8",
    missionBackground: "#F2FCFA",
    inputBg: "#EDEDED",
    borderColor: "#E4E9F3",
    borderColor2: "#D3DDE3",
    backdropBlur: "rgba(255, 255, 255, 0.5)",
  },

  mediaQuery: {
    fold: "@media (max-width: 311px)",
    tablet: "@media (min-width: 767px)",
    desktop: "@media (min-width: 1200px)",
  },
  tabletWidth: "600px",
  desktopWidth: "1100px",
  constrainedMargin: "0px 30px 0px 30px",
};

const darkThemeWeb: ThemeWeb = {
  borderRadius: "20px",

  colors: {
    background: "#13161D",
    primary: "#191D24",
    secondary: " #333b46",
    secondary3: "#191D24",
    secondary2: "#FFFFFF",
    accent: "linear-gradient(90deg, #26A7CF 0%, #27AF8F 100%)",
    accent2: "#5BBF96",
    textColors: ["#FFFFFF", "#848DA0", "#737373", "#29313C"],
    sliderTrackColor: "#1E1E1E",
    sliderBallColor: "linear-gradient(90deg, #26A7CF 0%, #27AF8F 100%)",
    heroBackground: "#181818",
    missionBackground: "#181d1c",
    inputBg: "#1E1E1E",
    borderColor: "#333B46",
    borderColor2: "#191D24",
    backdropBlur: "rgba(20, 23, 29, 0.7)",
  },

  mediaQuery: {
    fold: "@media (max-width: 350px)",
    tablet: "@media (min-width: 767px)",
    desktop: "@media (min-width: 1200px)",
  },
  tabletWidth: "600px",
  desktopWidth: "1100px",
  constrainedMargin: "0px 30px 0px 30px",
};

export { lightThemeWeb, darkThemeWeb };
