import { SVGProps } from 'react'
import useSelectorTyped from '../../../../../store/useSelectorTyped'

const Comma = (props: SVGProps<SVGSVGElement>) => {
  const theme = useSelectorTyped((state) => state?.fromClient?.theme);
  return (
    <svg
      width={176}
      height={150}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.772 126.496c26.245-12.929 39.367-28.12 39.367-45.573-11.186-1.293-20.436-5.871-27.75-13.736C5.074 59.322 1.417 50.218 1.417 39.876c0-10.99 3.55-20.255 10.649-27.796C19.165 4.537 28.092.766 38.849.766c12.047 0 22.48 4.902 31.3 14.706 8.82 9.804 13.23 21.71 13.23 35.715 0 42.017-23.448 74.877-70.345 98.579L.772 126.496Zm92.62 0c26.461-12.929 39.691-28.12 39.691-45.573-11.402-1.293-20.76-5.871-28.074-13.736S94.038 50.218 94.038 39.876c0-10.99 3.603-20.255 10.81-27.796C112.054 4.537 121.036.766 131.792.766c12.047 0 22.427 4.902 31.139 14.706C171.644 25.277 176 37.183 176 51.188c0 42.017-23.341 74.877-70.023 98.579l-12.585-23.271Z"
        fill={theme === 0 ? '#191D24' : '#F3F8FB'}
      />
    </svg>
  )
}
export default Comma
