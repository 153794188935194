const variantContainer = {
  hidden: { opacity: 0, scale: 0.5},
  show: {
    opacity: 1,
    scale: 1,
    transition: {
      type: "ease-in",
      duration: 0.8,
      staggerChildren: 0.1,
    },
  },
};

const variantitem = {
  hidden: { opacity: 0},
  show: {
    opacity: 1,
    transition: { duration: 1 },
  },
}

export { variantContainer, variantitem };

