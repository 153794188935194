import { ReactElement } from 'react'
import ClientsReview from '../components/landing/clients-review'
import FAQ from '../components/landing/faq'
import Hero from '../components/landing/hero'
import LatestArticles from '../components/landing/latest-articles'
import NUXTTechStack from '../components/landing/nuxt-tech-stack'
import Projects from '../components/landing/projects'
import WhatWeOffer from '../components/landing/what-we-offer'
import MainWrapper from '../components/layouts/Layout'
import A7AMeta from '../components/ssr/A7AMeta'
import { getServerTranslations } from '../lib/i18n/getServerTranslations'
import { Hiring } from '../components/landing/hiring'
import { useTranslation } from 'next-i18next'

const Home = () => {
  const { t } = useTranslation('projectsData')
  return (
    <>
      <A7AMeta
        tabTitle="A7A LABS"
        url="https://a7alabs.com/"
        title="A7A - Create, Inspire"
        description="We design and develop websites, web shops, mobile apps, digital campaigns, system integrations and customized software of the highest level. Designs that are meticulously thought out to the pixel and are of this time. Today's software, ready for tomorrow's technology."
        image="https://a7alabs.com/images/A7AThumbnail.webp"
      />

      <Hero />
      <WhatWeOffer />
      <Projects projectsData={t('projectsData', { returnObjects: true })} />
      <NUXTTechStack />
      <ClientsReview />
      <LatestArticles />
      <Hiring />
      <FAQ />
    </>
  )
}

export default Home

export async function getStaticProps({ locale }: any) {
  return {
    props: {
      ...(await getServerTranslations(locale, [
        'common',
        'landing',
        'projectsData',
        "servicesText",
        "inHouseProjects"
      ])),
      // Will be passed to the page component as props
    },
  }
}

Home.getLayout = function getLayout(page: ReactElement) {
  return (
    <>
      <MainWrapper>
        <main>{page}</main>
      </MainWrapper>
    </>
  )
}
