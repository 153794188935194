import styled from "styled-components";
import Moon from "../../../../public/svgs/Moon";
import Sun from "../../../../public/svgs/Sun";
import useSelectorTyped from "../../../store/useSelectorTyped";

interface SliderProps {
  darkMode: boolean;
}
const Slider = styled.div<SliderProps>`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  background: ${(props) => props.theme.colors.sliderBallColor};
  margin: 5px 5.9px 5px 5.9px;
  left: ${(props) => (props.darkMode ? "20px" : "0px")};
  bottom: 0.2px;
  transition: 0.2s ease;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
`;

export const SliderButton = () => {
  const theme = useSelectorTyped((state) => state?.fromClient?.theme);

  return (
    <Slider darkMode={theme === 0 ? true : false}>
      {theme === 0 ? <Sun /> : <Moon />}
    </Slider>
  );
};
