import { t } from 'i18next'
import { useState, useRef, useEffect } from 'react'
import { SimpleCarousel } from 'react-whirlpool'
import 'react-whirlpool/dist/cjs/component/SimpleCarousel.css'
import styled from 'styled-components'
import { A7ACenteredRow } from '../../../utils/Container'
import { useMediaQuery } from '../../../utils/useMediaQuery'
import CarouselCard from './CarouselCard'
import { SocialButton } from '../button'
import LeftArrow from '../svg/common/LeftArrow'
import RightArrow from '../svg/common/RightArrow'
import SizedBox from '../ui/SizedBox'

const FAQCarouselWrapper = styled.div`
  margin-top: 30px;
  width: 100%;
  gap: 12px;
  max-width: 100%;
  min-height: 190px;
  ${(props) => props.theme.mediaQuery.tablet} {
    max-width: 1112px;
    margin-top: 60px;
  }
`

interface ICarouselCard {
  index: number;
  question: string;
  answer: string;
}


interface IA7ACarouselProps {
  items: ICarouselCard[];
}

const A7ACarousel = ({items}: IA7ACarouselProps) => {
  const [activeIndex, setActiveIndex] = useState(1)
  const simpleCarouselRef = useRef<any>()
  const isMobile = useMediaQuery('(max-width: 767px)')

  return (
    <>
      <FAQCarouselWrapper>
        <SimpleCarousel
          isHorizontal={true}
          gap={12}
          autoPlay={false}
          minHeight="190px"
          ref={simpleCarouselRef}
          hideInitGap={isMobile ? false : true}
          hideArrows={true}
          onActiveIndexUpdate={(index: number) => setActiveIndex(index)}
        >
          {items.map((i, j) => (
               <CarouselCard
                key={i.index}
                isSelected={activeIndex === j}
                title={i.question}
                description={i.answer}
              />
            ))}
        </SimpleCarousel>
      </FAQCarouselWrapper>

      <SizedBox height={30} width={0} />
      <A7ACenteredRow>
        <SocialButton
          ariaLabel="Previous"
          onClick={() => {
            simpleCarouselRef.current &&
              simpleCarouselRef.current.handlePrevEvent()
          }}
        >
          <LeftArrow />
        </SocialButton>
        <SizedBox height={0} width={9} />
        <SocialButton
          ariaLabel="Next"
          onClick={() => {
            simpleCarouselRef.current &&
              simpleCarouselRef.current.handleNextEvent()
          }}
        >
          <RightArrow />
        </SocialButton>
      </A7ACenteredRow>
    </>
  )
}

export default A7ACarousel
