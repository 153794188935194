import { SVGProps } from "react"
import useSelectorTyped from "../../../../../store/useSelectorTyped"

const VueJS = (props: SVGProps<SVGSVGElement>) => {
  const theme = useSelectorTyped((state) => state?.fromClient?.theme)
  return (
    <svg
      width={23}
      height={20}
      viewBox="0 0 23 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.932.63a1 1 0 0 0-.866-.502l-4.97-.004h-3.652a1.002 1.002 0 0 0-.817.425l-.56.796-.563-.798a.998.998 0 0 0-.816-.423H6.033L1.06.15a1 1 0 0 0-.858 1.506l10.02 17.106a1 1 0 0 0 1.727-.002l9.98-17.128A1.003 1.003 0 0 0 21.932.63ZM9.17 2.124l1.085 1.533a.999.999 0 0 0 .816.423 1.003 1.003 0 0 0 .818-.425l1.076-1.529 1.363-.002-3.244 5.454-3.275-5.454h1.36Zm1.912 14.15L2.805 2.141l2.67-.015 4.754 7.918a1 1 0 0 0 .857.485h.006a1 1 0 0 0 .857-.489l4.708-7.916 2.67.003-8.245 14.147Z"
        fill={theme === 1 ? "#A9B6BD" : "#FFFFFF"}
      />
    </svg>
  )
}
export default VueJS