import { ISocialIconProps } from "./Github";

const Twitter = ({ props, fill }: ISocialIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    baseProfile="basic"
    viewBox="-6 -5 60 60"
    {...props}
  >
    <path
      d="M34.257 34H27.82L13.829 14h6.437l13.991 20zm-5.67-1.696h2.563L19.499 15.696h-2.563l11.651 16.608z"
      style={{
        fill: fill ? fill : "#fff"
      }}
    />
    <path
      d="m15.866 34 7.203-8.344-.942-1.249L13.823 34zM24.45 21.721l.905 1.289L33.136 14h-2z"
      style={{
        fill: fill ? fill : "#fff"
      }}
    />
  </svg>
);

export default Twitter;
