import { motion } from 'framer-motion'
import Image from 'next/image'
import React, { useRef, useState } from 'react'
import { SimpleCarousel } from 'react-whirlpool'
import 'react-whirlpool/dist/cjs/component/SimpleCarousel.css'
import styled from 'styled-components'
import { variantContainer, variantitem } from '../../../utils/Animations'
import {
  A7ACenteredColumn,
  A7ACenteredRow,
  A7AColumn,
  A7ARow,
  A7AXSpaceBetweenRow,
  A7AYCenteredColumn,
} from '../../../utils/Container'
import { useTranslation } from 'next-i18next'
import { useMediaQuery } from '../../../utils/useMediaQuery'
import { SocialButton } from '../../common/button'
import LeftArrow from '../../common/svg/common/LeftArrow'
import RightArrow from '../../common/svg/common/RightArrow'
import SizedBox from '../../common/ui/SizedBox'

const ClientsReviewWrapper = styled.section`
  align-items: flex-start;
  width: 100%;
  /* min-height: 504px; */
  background-image: ${(props) => props.theme.colors.accent};
  border-radius: ${(props) => props.theme.borderRadius};
  padding: 30px;
  margin-top: 30px;
  ${(props) => props.theme.mediaQuery.tablet} {
    padding: 60px;
    margin-top: 60px;
  }
`

const ClientsReviewContainer = styled(A7ACenteredColumn)`
  max-width: ${(props) => props.theme.desktopWidth};
  margin: 0 auto;
  ${(props) => props.theme.mediaQuery.tablet} {
    gap: 20px;
    display: grid;
    grid-template-areas:
      'heading heading card card buttons'
      'heading heading card card buttons'
      'heading heading card card buttons';
    justify-content: stretch;
    grid-auto-rows: 1fr;
  }
`

const ClientsReviewHeaderWrapper = styled(A7AYCenteredColumn)`
  grid-area: heading;
  width: 100%;
  align-items: flex-start;
  margin-bottom: 30px;
  ${(props) => props.theme.mediaQuery.tablet} {
    margin-bottom: 0px;
  }
`

const ClientsReviewHeaderContainer = styled(A7AXSpaceBetweenRow)`
  width: 100%;
  flex-wrap: wrap;
  ${(props) => props.theme.mediaQuery.desktop} {
    width: 564px;
  }
`

const ClientsReviewHeading = styled.h1`
  margin: 0px;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;
  width: 100%;
  ${(props) => props.theme.mediaQuery.tablet} {
    font-size: 36px;
    line-height: 49px;
  }
`

const ClientsReviewDescription = styled.p`
  margin: 34px 0px 0px 0px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  color: #ffffff;
  text-align: left;
  font-size: 14px;
  line-height: 17px;
  max-width: 380px;
  ${(props) => props.theme.mediaQuery.tablet} {
    margin-top: 30px;
    font-size: 16px;
    line-height: 19px;
  }
`

const TiltedBar = styled.div`
  margin-left: 15px;
  background-color: rgba(29, 43, 87, 0.2);
  width: 14px;
  height: 54px;
  transform: skewX(-30deg);
`

const ClientsReviewCardWrapper = styled.div`
  grid-area: card;
  height: 350px;
  max-height: 350px;
  overflow: hidden;
`

const ClientsReviewCardContainer = styled(A7ARow)<{ isActive: boolean }>`
  align-items: flex-start;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: ${(props) => props.theme.borderRadius};
  padding: 15px;
  gap: 12px;
  min-height: 158px;
  background-color: ${(props) =>
    !props.isActive ? 'transparent' : 'rgba(29, 43, 87, 0.2)'};
  /* max-width: 564px; */
  /* min-height: 181px; */
  width: calc(100vw - 70px);
  ${(props) => props.theme.mediaQuery.tablet} {
    height: auto;
    min-width: 180px;
    width: 100%;
  }
  & img {
    border-radius: 50%;
  }
`
const ClientsReviewTextContainer = styled(A7AColumn)`
  gap: 12px;
  span {
    font-family: 'Lato';
    color: #ffffff;
    font-style: italic;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    ${(props) => props.theme.mediaQuery.tablet} {
      font-size: 16px;
      line-height: 19px;
    }
  }
`

const ClientNameContainer = styled(A7AColumn)`
  gap: 4px;
`

const ClientName = styled.p`
  margin: 0px;
  font-family: 'LEMON MILK';
  font-style: normal;
  font-weight: 500;
  color: #ffffff;
  font-size: 14px;
  ${(props) => props.theme.mediaQuery.tablet} {
    font-size: 16px;
    line-height: 19px;
  }
`

const ClientDesignation = styled.p`
  margin: 0px;
  font-weight: 500;
  font-family: 'LEMON MILK';
  font-style: normal;
  color: #ffffff;
  font-size: 10px;
  line-height: 14px;
`

const CarouselButtonsContainer = styled(A7ACenteredRow)`
  grid-area: buttons;
  width: 100%;
  gap: 10px;
  margin-top: 30px;
  ${(props) => props.theme.mediaQuery.tablet} {
    flex-direction: column;
    margin-top: 0px;
  }
`

const ClientsReview = () =>
  // { onView }: ISectionProps
  {
    const isMobile = useMediaQuery('(max-width: 767px)')
    // const reviewCardData = [
    //   {
    //     id: 0,
    //     name: 'Nicolas Shaba',
    //     designation: 'CEO, Tidal Market Inc.',
    //     image:
    //       'https://cdn.discordapp.com/avatars/384034990029012993/4401774dc77f503b5ed34a9fe2bcc281.webp?size=160',
    //     review:
    //       "I've been working with A7A Labs for a long time and they consistently are able to provide amazing work while always meeting deadlines. When ever I have a project that needs work, I go to them!",
    //   },
    //   {
    //     id: 1,
    //     name: 'Alin Cristian',
    //     designation: 'CEO, Signally.app',
    //     image:
    //       'https://cdn.discordapp.com/avatars/398208358634618883/2150974802fa569d02983652fd21b92e.webp?size=160',
    //     review:
    //       'Whenever I have any development requirements, I always go to A7A Labs. They are always able to provide amazing work while always meeting deadlines.',
    //   },
    //   {
    //     id: 2,
    //     name: 'Mason Price',
    //     designation: 'CEO, Savage Nation',
    //     image:
    //       'https://cdn.discordapp.com/avatars/677173092140843016/831aa13914c988f88abbdfd49d047660.webp?size=160',
    //     review:
    //       "As a business owner who isn't familiar with the development, I can count on them to give me peace of mind and know that the job is done perfectly.",
    //   },
    // ]

    // const [ref, inView] = useInView({
    //   rootMargin: "0px 2000px 500px 0px",
    //   threshold: 0.7,
    // });

    // useEffect(() => {
    //   if (inView === true) {
    //     onView(3);
    //     console.log(inView);

    //     console.log("Clients");
    //   }
    // }, [inView, onView]);
    const [activeIndex, setActiveIndex] = useState(0)
    const simpleCarouselRef = useRef<any>()
    const { t } = useTranslation('landing')

    return (
      <>
        <ClientsReviewWrapper
          // ref={ref}
          id="reviews"
        >
          <ClientsReviewContainer>
            <ClientsReviewHeaderWrapper
              as={motion.div}
              variants={variantContainer}
              initial={'hidden'}
              whileInView={'show'}
              viewport={{ once: true }}
            >
              <ClientsReviewHeaderContainer>
                <A7ACenteredRow>
                  <motion.div variants={variantitem}>
                    <TiltedBar />
                  </motion.div>
                  <SizedBox height={0} width={20} />
                  <ClientsReviewHeading as={motion.h1} variants={variantitem}>
                    {t('review.clientsTitle')}
                  </ClientsReviewHeading>
                </A7ACenteredRow>
              </ClientsReviewHeaderContainer>
              <ClientsReviewDescription as={motion.p} variants={variantitem}>
                {t('review.clientsDescription')}
              </ClientsReviewDescription>
            </ClientsReviewHeaderWrapper>

            {!isMobile ? (
              <ClientsReviewCardWrapper>
                <SimpleCarousel
                  isHorizontal={false}
                  gap={20}
                  autoPlay={false}
                  minHeight="350px"
                  ref={simpleCarouselRef}
                  hideArrows={true}
                  onActiveIndexUpdate={(index: number) => setActiveIndex(index)}
                >
                  {/* @ts-ignore */}
                  {t('review.clientsReviewData', { returnObjects: true }).map(
                    (card: any, index: number) => (
                      <ClientsReviewCardContainer
                        key={card.id}
                        isActive={activeIndex === index}
                      >
                        <Image
                          loading='lazy'
                          src={card.image}
                          alt={card.name}
                          height={45}
                          width={45}
                        />
                        <ClientsReviewTextContainer>
                          <span>
                            {t(`review.clientsReviewData.${index}.review`)}
                          </span>
                          <ClientNameContainer>
                            <ClientName>
                              {t(`review.clientsReviewData.${index}.name`)}
                            </ClientName>
                            <ClientDesignation>
                              {t(
                                `review.clientsReviewData.${index}.designation`
                              )}
                            </ClientDesignation>
                          </ClientNameContainer>
                        </ClientsReviewTextContainer>
                      </ClientsReviewCardContainer>
                    )
                  )}
                </SimpleCarousel>
              </ClientsReviewCardWrapper>
            ) : (
              <SimpleCarousel
                isHorizontal={true}
                gap={21}
                autoPlay={false}
                minHeight="50px"
                ref={simpleCarouselRef}
                hideArrows={true}
                onActiveIndexUpdate={(index: number) => setActiveIndex(index)}
              >
                {/* @ts-ignore */}
                {t('review.clientsReviewData', { returnObjects: true }).map(
                  (card: any, index: number) => (
                    <ClientsReviewCardContainer
                      key={card.id}
                      isActive={activeIndex === index}
                    >
                      <Image
                        src={card.image}
                        alt={card.name}
                        height={45}
                        width={45}
                      />
                      <ClientsReviewTextContainer>
                        <span>{card.review}</span>
                        <ClientNameContainer>
                          <ClientName>{card.name}</ClientName>
                          <ClientDesignation>
                            {card.designation}
                          </ClientDesignation>
                        </ClientNameContainer>
                      </ClientsReviewTextContainer>
                    </ClientsReviewCardContainer>
                  )
                )}
              </SimpleCarousel>
            )}

            <CarouselButtonsContainer>
              <SocialButton
                ariaLabel='Previous'
                onClick={() => {
                  simpleCarouselRef.current &&
                    simpleCarouselRef.current.handlePrevEvent()
                }}
                color="white"
              >
                <LeftArrow
                  color="#27AF8F"
                  styles={{
                    transform: !isMobile ? 'rotate(90deg)' : 'rotate(0deg)',
                  }}
                />
              </SocialButton>
              <SocialButton
                ariaLabel='Next'
                onClick={() => {
                  simpleCarouselRef.current &&
                    simpleCarouselRef.current.handleNextEvent()
                }}
                color="white"
              >
                <RightArrow
                  color="#27AF8F"
                  styles={{
                    transform: !isMobile ? 'rotate(90deg)' : 'rotate(0deg)',
                  }}
                />
              </SocialButton>
            </CarouselButtonsContainer>
          </ClientsReviewContainer>
        </ClientsReviewWrapper>
      </>
    )
  }

export default ClientsReview
