import { SVGProps } from 'react'
import useSelectorTyped from '../../../../../store/useSelectorTyped'

const NestJS = (props: SVGProps<SVGSVGElement>) => {
  const theme = useSelectorTyped((state) => state?.fromClient?.theme)
  return (
    <svg
      width={25}
      height={25}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          d="M14.977 3.965c-.436-.687-.297-1.14-.056-1.607.216-.418.304-1.145-.458-1.717.701-.258 1.807.198 1.827 1.033-.407.56-1.518.783-1.313 2.291Zm5.033 15.427c-.34 1.103-.185 1.69-1.665 3.98 2.617-1.766 3.852-4.047 4.259-6.636.256 1.08.28 2.06.18 2.984 2.168-2.855 3.654-8.612-1.344-14.4-.2 2.559-2.675 4.573-5.528 3.783 4.892-.539 5.206-6.76 1.003-8.196.082 1.73-2.28 1.164-1.585 3.479-2.405-1.36-7.66-1.64-9.749.424C4.504 5.874 3.77 6.754 2.423 6.813c-1.11.048-2.578 1.85-.67 2.773.183.769.824 1.206 1.196 1.282-.093 1.368.198.67.625.074-.143 1.73.093 1.107.761.216 5.657-1.143 8.78 3.47 8.816 6.303-.025-.514-.847-1.112-1.281-.947-.294.622-.614 1.15-1.01 1.411a4.432 4.432 0 0 0-.061-1.238c-.13.499-.376 1.017-.768 1.56-.78.043-1.872-.537-1.554-1.498.448-.935-.43-1.13-1.138-.862-.532.201-.892.758-.94.931-.178.625-.12 2.02 1.094 2.946-.318.058-.645.11-.867.241 2.064 1.248 4.17.827 5.344.355 1.92-.77 3.246-2.905 3.453-4.552a5.936 5.936 0 0 1-2.563 6.271c.612.065 1.809-.35 3.033-.786-.925 1.165-2.032 2.27-3.844 3.138 3.57-.299 6.181-2.091 7.961-5.039"
          fill={theme === 1 ? "#A9B6BD" : "#FFFFFF"}
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill={theme === 1 ? "#A9B6BD" : "#FFFFFF"} transform="translate(.814 .5)" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default NestJS
