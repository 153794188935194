import { SVGProps } from "react"

const Telephone = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={19}
    height={19}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.25 3.685h3l1.5 3.75L6.875 8.56a8.25 8.25 0 0 0 3.75 3.75l1.125-1.875 3.75 1.5v3a1.5 1.5 0 0 1-1.5 1.5A12 12 0 0 1 2.75 5.185a1.5 1.5 0 0 1 1.5-1.5"
      stroke="#A9B6BD"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Telephone
