import { motion } from 'framer-motion'
import styled from 'styled-components'
import { variantContainer, variantitem } from '../../../utils/Animations'
import {
  A7ACenteredColumn,
  A7ACenteredRow,
  A7AColumn,
  A7AXSpaceBetweenRow,
  A7AYCenteredColumn,
} from '../../../utils/Container'
import { useMediaQuery } from '../../../utils/useMediaQuery'
import { GenericButton, SocialButton } from '../../common/button'
import LeftArrow from '../../common/svg/common/LeftArrow'
import RightArrow from '../../common/svg/common/RightArrow'
import SizedBox from '../../common/ui/SizedBox'
import TiltedBar from '../../common/ui/TiltedBar'
import HiringCard from './HiringCard'
import { SimpleCarousel } from 'react-whirlpool'
import 'react-whirlpool/dist/cjs/component/SimpleCarousel.css'
import { useEffect, useRef, useState } from 'react'
import { HiringModal } from './HiringModal'
import A7AModalWrapper from '../../common/modal/A7AModalWrapper'
import { useTranslation } from 'next-i18next'

const HiringWrapper = styled(A7ACenteredColumn).attrs({ as: 'section' })`
  padding-top: 50px;
  background-image: url('/images/HiringBackground.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: flex-start;
`

const HiringHeaderWrapper = styled(A7AYCenteredColumn)`
  width: 100%;
  padding: ${(props) => props.theme.constrainedMargin};
  max-width: ${(props) => props.theme.desktopWidth};
`

const HiringHeaderContainer = styled(A7AXSpaceBetweenRow)`
  width: 100%;
`

const HiringHeading = styled.h1`
  margin: 0px;
  font-weight: 500;
  color: #14171d;
  font-size: 24px;
  line-height: 32px;
  ${(props) => props.theme.mediaQuery.tablet} {
    font-size: 36px;
    line-height: 49px;
  }
`

const HiringDescription = styled.p`
  font-family: 'Lato';
  width: 100%;
  font-style: normal;
  margin: 0px;
  margin-top: 34px;
  font-weight: 300;
  font-size: 14px;

  line-height: 17px;
  ${(props) => props.theme.mediaQuery.tablet} {
    margin-top: 30.5px;
    font-size: 16px;
    max-width: ${(props) => props.theme.desktopWidth};
    line-height: 19px;
  }
  color: ${(props) => props.theme.colors.textColors[1]};
`

const HiringCardWrapper = styled(A7AColumn)`
  margin: ${(props) => props.theme.constrainedMargin};
  margin-top: 30px;
  justify-content: flex-start;
  gap: 20px;
  ${(props) => props.theme.mediaQuery.tablet} {
    max-width: calc(100% - 60px);
    margin-top: 60px;
    flex-direction: row;
  }
  ${(props) => props.theme.mediaQuery.desktop} {
    max-width: 1200px;
  }
`

export const Hiring = () => {
  const isMobile = useMediaQuery('(max-width: 767px)')
  const {t} = useTranslation('landing')

  const [activeIndex, setActiveIndex] = useState(0)
  const simpleCarouselRef = useRef<any>()
  const [hiringModalState, setHiringModal] = useState(false)
  const [activeModalIndex, setActiveModalIndex] = useState(0)

  const hiringData = [
    {
      id: 0,
      title: 'IOS DEVELOPER',
      time: '12 Oct 2022',
      location: 'Worldwide',
      jobType: 'Remote , Full time',
      jobDesignation: 'Mid level',
    },
    {
      id: 1,
      title: 'ANDROID DEVELOPER',
      time: '9 Oct 2022',
      location: 'Worldwide',
      jobType: 'Remote , Full time',
      jobDesignation: 'Senior level',
    },
    {
      id: 2,
      title: 'UI/UX DESIGNER',
      time: '6 Oct 2022',
      location: 'Worldwide',
      jobType: 'Remote , Full time',
      jobDesignation: 'Mid level',
    },
    {
      id: 3,
      title: 'UI/UX DESIGNER',
      time: '6 Oct 2022',
      location: 'Worldwide',
      jobType: 'Remote , Full time',
      jobDesignation: 'Mid level',
    },
  ]



  useEffect(() => {
    console.log(process.env.IS_HIRING)
  }, [hiringModalState])

  if(process.env.IS_HIRING == 'false') {
    return <></>
  }

  return (
    <>
      <A7AModalWrapper
        isOpen={hiringModalState}
        onClose={() => {
          setHiringModal(false)
        }}
        Modal={
          <HiringModal
            data={hiringData[activeModalIndex]}
            isOpened={hiringModalState}
            closeModal={() => setHiringModal(!hiringModalState)}
          />
        }
      />
      <HiringWrapper
        id="career"
        // ref={ref}
      >
        <HiringHeaderWrapper>
          <HiringHeaderContainer
            as={motion.div}
            variants={variantContainer}
            initial={'hidden'}
            whileInView={'show'}
            viewport={{ once: true }}
          >
            <A7ACenteredRow>
              <TiltedBar />
              <SizedBox height={0} width={20} />
              <HiringHeading as={motion.h1} variants={variantitem}>
                {t('hiring.0.hiringTitle')}
              </HiringHeading>
            </A7ACenteredRow>
            {!isMobile && (
              <>
                <A7ACenteredRow>
                  <SocialButton
                    ariaLabel='left arrow'
                    onClick={() => {
                      simpleCarouselRef.current &&
                        simpleCarouselRef.current.handlePrevEvent()
                    }}
                  >
                    <LeftArrow />
                  </SocialButton>
                  <SizedBox height={0} width={9} />
                  <SocialButton
                    ariaLabel='right arrow'
                    onClick={() => {
                      simpleCarouselRef.current &&
                        simpleCarouselRef.current.handleNextEvent()
                    }}
                  >
                    <RightArrow />
                  </SocialButton>
                </A7ACenteredRow>
              </>
            )}
          </HiringHeaderContainer>
          <HiringDescription as={motion.p} variants={variantitem}>
            {t('hiring.1.hiringDescription')}
          </HiringDescription>
        </HiringHeaderWrapper>
        <HiringCardWrapper>
          <SimpleCarousel
            isHorizontal={isMobile ? false : true}
            gap={20}
            autoPlay={false}
            minHeight="154px"
            ref={simpleCarouselRef}
            hideInitGap={false}
            hideArrows={true}
            onActiveIndexUpdate={(index: number) => setActiveIndex(index)}
          >
            {hiringData.map((job) => (
              <HiringCard
                jobDesignation={job.jobDesignation}
                jobType={job.jobType}
                location={job.location}
                isActive={job.id === activeIndex}
                time={job.time}
                title={job.title}
                key={job.id}
                id={job.id.toString()}
                onClick={() => {
                  {
                    setActiveModalIndex(job.id)
                    setHiringModal(!hiringModalState)
                  }
                }}
              />
            ))}
          </SimpleCarousel>
        </HiringCardWrapper>
        {isMobile && (
          <>
            <SizedBox height={30} width={0} />
            <GenericButton
              text={'View more'}
              height={45}
              width={180}
              isFullWidth={false}
              isDisabled={false}
              isProcessing={false}
              onClick={() => {}}
            >
              <SizedBox width={18} height={0} />
              <RightArrow />
            </GenericButton>
          </>
        )}
        <SizedBox width={0} height={isMobile ? 30 : 60} />
      </HiringWrapper>
    </>
  )
}
