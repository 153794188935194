import Figma from './Figma'
import ReactJS from './ReactJS'
import GraphQl from './GraphQl'
import Flutter from './Flutter'
import ReactNative from './ReactNative'
import VueJS from './VueJS'
import NextJS from './NextJS'
import NestJS from './NestJS'
import NuxtJS from './NuxtJS'
import Firebase from './Firebase'
import AndroidStudio from './AndroidStudio'
import PostGreSQL from './PostGreSQL'
import Javascript from './Javascript'
import AWS from './AWS'
import HTMl from './HTMLIcon'
import Python from './Python'
import Golang from './Golang'

export {
  ReactJS,
  Figma,
  GraphQl,
  Flutter,
  ReactNative,
  Golang,
  VueJS,
  NextJS,
  NestJS,
  NuxtJS,
  Firebase,
  AndroidStudio,
  PostGreSQL,
  Javascript,
  AWS,
  HTMl,
  Python,
}
