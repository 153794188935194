import { SVGProps } from "react";

const Globe = (props: SVGProps<SVGSVGElement>) => {
  return (
    <>
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M12.0005 21.7651C16.9711 21.7651 21.0005 17.7357 21.0005 12.7651C21.0005 7.79457 16.9711 3.76514 12.0005 3.76514C7.02993 3.76514 3.00049 7.79457 3.00049 12.7651C3.00049 17.7357 7.02993 21.7651 12.0005 21.7651Z"
          stroke="#A9B6BD"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.00049 4.76514V6.76514C8.00049 7.29557 8.2112 7.80428 8.58628 8.17935C8.96135 8.55442 9.47006 8.76514 10.0005 8.76514H11.0005C11.5309 8.76514 12.0396 8.97585 12.4147 9.35092C12.7898 9.726 13.0005 10.2347 13.0005 10.7651C13.0005 11.2956 13.2112 11.8043 13.5863 12.1793C13.9613 12.5544 14.4701 12.7651 15.0005 12.7651C15.5309 12.7651 16.0396 12.5544 16.4147 12.1793C16.7898 11.8043 17.0005 11.2956 17.0005 10.7651C17.0005 10.2347 17.2112 9.726 17.5863 9.35092C17.9613 8.97585 18.4701 8.76514 19.0005 8.76514H20.0005M20.0005 16.7651H17.0005C16.4701 16.7651 15.9613 16.9759 15.5863 17.3509C15.2112 17.726 15.0005 18.2347 15.0005 18.7651V20.7651M11.0005 20.7651V18.7651C11.0005 18.2347 10.7898 17.726 10.4147 17.3509C10.0396 16.9759 9.53092 16.7651 9.00049 16.7651C8.47006 16.7651 7.96135 16.5544 7.58627 16.1793C7.2112 15.8043 7.00049 15.2956 7.00049 14.7651C7.00049 14.2347 6.78977 13.726 6.4147 13.3509C6.03963 12.9759 5.53092 12.7651 5.00049 12.7651H3.00049"
          stroke="#A9B6BD"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default Globe;
