import { SVGProps } from "react";

const Clock = (props: SVGProps<SVGSVGElement>) => {
  return (
    <>
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M12.0005 22.7651C17.5233 22.7651 22.0005 18.288 22.0005 12.7651C22.0005 7.24229 17.5233 2.76514 12.0005 2.76514C6.47764 2.76514 2.00049 7.24229 2.00049 12.7651C2.00049 18.288 6.47764 22.7651 12.0005 22.7651Z"
          stroke="#A9B6BD"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.0005 16.7651L12.5865 14.3511C12.2114 13.9762 12.0006 13.4675 12.0005 12.9371V6.76514"
          stroke="#A9B6BD"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default Clock;
