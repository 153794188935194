import { SVGProps } from 'react'
import useSelectorTyped from '../../../../../store/useSelectorTyped'

const AWS = (props: SVGProps<SVGSVGElement>) => {
  const theme = useSelectorTyped((state) => state?.fromClient?.theme)

  return (
    <>
      <svg
        width={21}
        height={21}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M10.815 7.5h-7a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h3m4-2h7a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2h-3"
          stroke={theme === 1 ? "#A9B6BD" : "#FFFFFF"}
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.814 7.5v-4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h4a2 2 0 0 0 2-2v-4m-5-9v.01m2 11.99v.01"
          stroke={theme === 1 ? "#A9B6BD" : "#FFFFFF"}
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  )
}

export default AWS
