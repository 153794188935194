import { ChangeEvent, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { A7ARow, A7AYCenteredRow } from '../../../utils/Container'
import ModalCross from '../svg/common/ModalCross'
import {
  IconContainer,
  IInputProps,
  Input,
  InputContainer,
} from './A7AInputField'

const A7ATagInputContainer = styled(InputContainer)<{
  numberOfTags: number
}>`
  display: grid;
  grid-template-columns: ${(props) =>
    props.numberOfTags >= 4
      ? '30px 1fr 1fr'
      : props.numberOfTags <= 3
      ? '30px auto 1fr'
      : '30px 1fr'};
  gap: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.01);
  border: 1px solid ${(props) => props.theme.colors.borderColor2};
  overflow-x: auto;
  overflow-y: hidden;
  div:nth-child(1) {
    display: flex;
    align-items: center;
  }
`

const A7ATagInput = styled(Input)`
  width: 100%;
  min-width: 200px;
  background-color: transparent;
  border: none;
  padding: 0;
  &:focus {
    outline: none;
  }
`

const TagsWrapper = styled(A7ARow)`
  gap: 10px;
  width: 100%;
`

const TagsContainer = styled(A7AYCenteredRow)`
  border-radius: 10px;
  padding: 10px 8px 10px 10px;
  background-color: ${(props) => props.theme.colors.secondary};
  height: 30px;
  gap: 6px;
  span {
    font-family: 'Lato';
    font-size: 12px;
    color: #a8b6bd;
  }
`

const A7ATagsInputField = ({
  placeholder,
  type,
  id,
  onChange,
  value,
  icon,
  inputMode,
}: IInputProps) => {
  const [tags, setTags] = useState<string[]>([''])
  const [inputState, setInputState] = useState('')
  const [inputWidth, setInputWidth] = useState(0)
  const ref = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Tab') {
      if (inputState === '') return
      e.preventDefault()
      const res = [...tags, inputState]
      setTags(() => res)
      setInputState((_) => '')
    }
  }

  const handleTags = (e: ChangeEvent<HTMLInputElement>) => {
    setInputState(e.target.value)
    const onlyCommaRegex = new RegExp(/^[,]+$/)
    if (onlyCommaRegex.test(e.target.value)) {
      setInputState((_) => '')
      return
    }
    if (e.target.value.includes(',')) {
      const res = [...tags, e.target.value.split(',')[0]]
      setTags(() => res)
      setInputState((_) => '')
      onChange(e)
    }
    value = tags.join()
  }

  useEffect(() => {
    containerRef.current?.scrollTo({
      left: containerRef.current?.scrollWidth,
      behavior: 'smooth',
    })
  }, [tags])

  return (
    <>
      <A7ATagInputContainer ref={containerRef} numberOfTags={tags.length}>
        <div>
          <IconContainer>{icon}</IconContainer>
        </div>
        {tags.length > 1 && (
          <TagsWrapper ref={ref}>
            {tags.map(
              (i, j) =>
                j !== 0 && (
                  <TagsContainer key={j}>
                    <span>{i}</span>
                    <ModalCross
                      style={{ cursor: 'pointer' }}
                      height={14}
                      width={14}
                      onClick={() =>
                        setTags((prev) => {
                          const res = prev.filter((_, k) => k !== j)
                          return res
                        })
                      }
                    />
                  </TagsContainer>
                ),
            )}
          </TagsWrapper>
        )}
        <div>
          <A7ATagInput
            ref={inputRef}
            type={type}
            id={id}
            onKeyDown={(e) => handleKeyDown(e)}
            inputMode={inputMode}
            value={inputState}
            onChange={(e) => handleTags(e)}
            placeholder={placeholder}
          />
        </div>
      </A7ATagInputContainer>
    </>
  )
}

export default A7ATagsInputField
