import { SVGProps } from "react";
import useSelectorTyped from "../../../../../store/useSelectorTyped";

const Flutter = (props: SVGProps<SVGSVGElement>) => {
  const theme = useSelectorTyped(state => state?.fromClient?.theme);

  return (
    <>
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M7.00049 14.0825L4.00049 11.0825L12.0005 3.08252H18.0005L7.00049 14.0825ZM14.0005 21.0825L9.00049 16.0825L14.0005 11.0825H19.0005L14.0005 16.0825L19.0005 21.0825H14.0005Z"
          stroke={theme === 1 ? "#A9B6BD" : "#FFFFFF"}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default Flutter;
