import { useTranslation } from "next-i18next";
import styled from "styled-components";
import {
  A7ACenteredRow,
  A7AColumn,
  A7ARow,
  SquircleOutlined2,
} from "../../../utils/Container";
import { GenericButton } from "../../common/button";
import BriefCase from "../../common/svg/common/BriefCase";
import Clock from "../../common/svg/common/Clock";
import Globe from "../../common/svg/common/Globe";
import RightArrow from "../../common/svg/common/RightArrow";
import Signal from "../../common/svg/common/Signal";
import SizedBox from "../../common/ui/SizedBox";

const HiringCardWrapper = styled(A7AColumn)`
  padding: 20px;
  gap: 15px;
  min-height: 160px;
  ${SquircleOutlined2}

  border-radius: ${(props) => props.theme.borderRadius};
  background: #fff;
  ${(props) => props.theme.mediaQuery.tablet} {
    padding: 30px 40px;
    gap: 30px;
    min-width: 350px;
  }
  transition: all 0.3s ease;

  :hover {
    & > div > button {
      display: flex;
    }
    background: linear-gradient(
      var(--gradient-angle),
      hsla(0, 0%, 100%, 0.5),
      hsla(0, 0%, 100%, 0.1),
      #fff,
      hsla(0, 0%, 100%, 0.3)
    );
    animation: gradient-rotate 5s linear 0s infinite reverse;
    @property --gradient-angle {
      syntax: "<angle>";
      inherits: false;
      initial-value: 1turn;
    }
    @keyframes gradient-rotate {
      0% {
        --gradient-angle: 360deg;
      }
      100% {
        --gradient-angle: 0deg;
      }
    }
  }
`;

const HiringCardTitle = styled.h1<{ isActive: boolean }>`
  margin: 0px;
  font-family: "LEMON MILK";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  color: #14171d;
  ${(props) => props.theme.mediaQuery.tablet} {
    line-height: 32px;
    background: ${(props) =>
      props.isActive ? props.theme.colors.accent : "inital"};
    -webkit-background-clip: ${(props) => (props.isActive ? "text" : "inital")};
    -webkit-text-fill-color: ${(props) =>
      props.isActive ? "transparent" : "inital"};
    background-clip: text;
  }
`;

const HiringCardDetailsWrapper = styled(A7ARow)`
  column-gap: 12px;
  row-gap: 5px;
  flex-wrap: wrap;
  /* min-width: 230px; */
  ${(props) => props.theme.mediaQuery.tablet} {
    max-width: auto;
    gap: 11px;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const HiringCardDetailsContainer = styled(A7ACenteredRow)`
  gap: 5px;
  width: fit-content;
  ${(props) => props.theme.mediaQuery.tablet} {
    flex-direction: row;
  }
  span {
    font-family: "Lato";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    color: #14171d;

    ${(props) => props.theme.mediaQuery.tablet} {
      font-size: 16px;
      line-height: 19px;
    }
  }
`;

interface IHiringCardProps {
  id: string;
  title: string;
  time: string;
  location: string;
  jobType: string;
  jobDesignation: string;
  isActive: boolean;
  onClick: () => void;
}
const HiringCard = ({
  id,
  title,
  time,
  location,
  jobType,
  isActive,
  jobDesignation,
  onClick,
}: IHiringCardProps) => {
  const {t} = useTranslation("landing");
  return (
    <>
      <HiringCardWrapper key={id}>
        <HiringCardTitle isActive={isActive}>{title}</HiringCardTitle>
        <HiringCardDetailsWrapper>
          <HiringCardDetailsContainer>
            <Clock />
            <span>{time}</span>
          </HiringCardDetailsContainer>
          <HiringCardDetailsContainer>
            <Globe />
            <span>{location}</span>
          </HiringCardDetailsContainer>
          <HiringCardDetailsContainer>
            <BriefCase />
            <span>{jobType}</span>
          </HiringCardDetailsContainer>
          <HiringCardDetailsContainer>
            <Signal />
            <span>{jobDesignation}</span>
          </HiringCardDetailsContainer>
        </HiringCardDetailsWrapper>
        <GenericButton
          text={t('hiring.2.applyForPost')}
          height={50}
          width={0}
          isFullWidth={true}
          isDisabled={false}
          isProcessing={false}
          onClick={onClick}
        >
          <SizedBox width={18} height={0} />
          <RightArrow />
        </GenericButton>
      </HiringCardWrapper>
    </>
  );
};

export default HiringCard;
