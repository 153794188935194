import { IArrowSVG } from "./LeftArrow";

const RightArrow = ({ props, color, styles }: IArrowSVG) => (
  <svg
    width={9}
    height={14}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    style={{...styles}}
  >
    <path
      d="M1.423 13.636a1.22 1.22 0 0 1-.706-.217.992.992 0 0 1-.271-.287.82.82 0 0 1-.078-.722.914.914 0 0 1 .206-.325l4.939-5.072L.75 1.932a.91.91 0 0 1-.201-.328.818.818 0 0 1 .09-.722.998.998 0 0 1 .277-.284c.114-.086.247-.151.392-.191a1.276 1.276 0 0 1 .878.077c.133.064.248.151.34.256L7.85 6.417c.162.17.25.382.25.6 0 .22-.088.432-.25.602l-5.512 5.677c-.11.114-.251.205-.41.264-.16.06-.332.085-.505.076Z"
      fill={color ? color : "#fff"}
    />
  </svg>
);

export default RightArrow;
