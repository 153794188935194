import { useFormik } from "formik";
import { useState } from "react";
import { useTranslation } from "next-i18next";
import styled from "styled-components";
import { A7ACenteredColumn } from "../../../utils/Container";
import { GenericButton } from "../../common/button";
import BigTick from "../../common/svg/common/BigTick";
import Chat from "../../common/svg/common/Chat";
import Dollar from "../../common/svg/common/Dollar";
import Email from "../../common/svg/common/Email";
import RightArrow from "../../common/svg/common/RightArrow";
import SizedBox from "../../common/ui/SizedBox";
import axios from "axios";
const LetsTalkFormContainer = styled(A7ACenteredColumn)`
  width: 100%;
  gap: 15px;
`;

const LetsTalkTextArea = styled.textarea`
  width: 100%;
  height: 86px;
  background: rgba(255, 255, 255, 0.01);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  padding: 15px 15px 15px 41px;
  backdrop-filter: blur(8px);
  font-family: "Lato";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: #a9b6bd;
`;

const LetsTalkInputContainer = styled.div`
  position: relative;
  width: 100%;
`;

const LetsTalkInput = styled(LetsTalkTextArea)`
  width: 100%;
  height: 50px;
`;

const LetsTalkIcon = styled.div`
  position: absolute;
  z-index: 1;
  width: 18px;
  height: 18px;
  top: 50%;
  transform: translate(0%, -50%);
  left: 15px;
`;

const LetsTalkIconTextArea = styled(LetsTalkIcon)`
  transform: translate(0%, 0%);
  top: 15px;
`;

const LetsTalkHeading = styled.h1`
  margin: 0px;
  font-weight: 500;
  color: white;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  ${(props) => props.theme.mediaQuery.tablet} {
    font-size: 36px;
    line-height: 49px;
  }
`;

const LetsTalkForm = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const { t } = useTranslation("common");
  const formik = useFormik({
    initialValues: {
      tellUsMoreAboutYourProject: "",
      projectBudget: "",
      emailAddress: "",
    },
    onSubmit: async (values) => {
      if (validate()) {
        setIsProcessing(true);
        try {
          await axios.post("/api/contact", {
            email: formik.values.emailAddress,
            budget: formik.values.projectBudget,
            description: formik.values.tellUsMoreAboutYourProject,
          });
          setFormSubmitted(true);
        } catch (e) {}
        setIsProcessing(false);
      } else return;
    },
  });

  const validate = (): boolean => {
    if (
      !formik.values.emailAddress ||
      !formik.values.projectBudget ||
      !formik.values.tellUsMoreAboutYourProject ||
      !formik.values.emailAddress.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    )
      return false;
    else return true;
  };

  return (
    <>
      {formSubmitted ? (
        <>
          <A7ACenteredColumn>
            <BigTick />
            <SizedBox height={28} width={0} />
            <LetsTalkHeading>
              {t("footer.1.footerSubmittedForm")}
            </LetsTalkHeading>
          </A7ACenteredColumn>
        </>
      ) : (
        <LetsTalkFormContainer
          as={"form"}
          onSubmit={
            validate()
              ? formik.handleSubmit
              : (e) => {
                  e.preventDefault();
                }
          }
        >
          <LetsTalkInputContainer>
            <LetsTalkIconTextArea>
              <Chat />
            </LetsTalkIconTextArea>
            <LetsTalkTextArea
              data-gramm="false"
              data-gramm_editor="false"
              data-enable-grammarly="false"
              id="tellUsMoreAboutYourProject"
              placeholder={
                t("footer.3.footerFields.0.footerFieldOne") as string
              }
              inputMode="text"
              onChange={formik.handleChange}
              value={formik.values.tellUsMoreAboutYourProject}
            />
          </LetsTalkInputContainer>
          <LetsTalkInputContainer>
            <LetsTalkIcon>
              <Dollar />
            </LetsTalkIcon>
            <LetsTalkInput
              id="projectBudget"
              as="input"
              placeholder={
                t("footer.3.footerFields.1.footerFieldTwo") as string
              }
              inputMode="numeric"
              onChange={formik.handleChange}
              type="number"
              value={formik.values.projectBudget}
            />
          </LetsTalkInputContainer>
          <LetsTalkInputContainer>
            <LetsTalkIcon>
              <Email />
            </LetsTalkIcon>
            <LetsTalkInput
              id="emailAddress"
              as="input"
              placeholder={
                t("footer.3.footerFields.2.footerFieldThree") as string
              }
              inputMode="email"
              onChange={formik.handleChange}
              type="email"
              value={formik.values.emailAddress}
            />
          </LetsTalkInputContainer>
          <SizedBox width={30} height={0} />
          <GenericButton
            text={t("footer.3.footerFields.3.footerButton")}
            height={50}
            type="submit"
            width={196}
            isFullWidth={false}
            isDisabled={!validate()}
            isProcessing={isProcessing}
            onClick={() => {}}
          >
            <SizedBox width={18} height={0} />
            <RightArrow color={validate() ? "white" : "#848DA0"} />
          </GenericButton>
        </LetsTalkFormContainer>
      )}
    </>
  );
};

export default LetsTalkForm;
