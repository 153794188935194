import { SVGProps } from "react"

const Sun = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={14}
    height={14}
    fill="none"
    viewBox="0 0 16 16 "
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.934 10.588a2.41 2.41 0 1 0 0-4.82 2.41 2.41 0 0 0 0 4.82ZM4.5 11.612l-.421.422M2.513 8.178h.602-.602Zm5.422-5.421v.602-.602Zm4.819 5.421h.602-.602Zm-4.82 4.82v.602-.602ZM4.08 4.323l.422.422-.422-.422Zm7.711 0-.422.422.422-.422Zm-.422 7.29.422.421-.422-.422Z"
      stroke="#fff"
      strokeWidth={1.205}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Sun
