import { SVGProps } from "react"

const Dollar = (props: SVGProps<SVGSVGElement>) => {
  return (  
    <>
      <svg
        width={19}
        height={19}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M9.498 2.916a6.719 6.719 0 1 0 0 13.438 6.719 6.719 0 0 0 0-13.438Zm0 12.094a5.375 5.375 0 1 1 0-10.75 5.375 5.375 0 0 1 0 10.75Z"
          fill="#A9B6BD"
        />
        <path
          d="M9.498 8.963c-1.344 0-1.344-.423-1.344-.672 0-.248.47-.672 1.344-.672.874 0 .934.43.94.672h1.345a2.016 2.016 0 0 0-1.613-1.935v-.752H8.826v.732c-1.344.222-2.015 1.089-2.015 1.955 0 .753.349 2.016 2.687 2.016 1.344 0 1.344.457 1.344.672 0 .215-.417.672-1.344.672-1.236 0-1.344-.578-1.344-.672H6.811c0 .618.443 1.713 2.015 1.962v.725h1.344v-.725c1.344-.229 2.016-1.095 2.016-1.962 0-.753-.35-2.016-2.688-2.016Z"
          fill="#A9B6BD"
        />
      </svg>
    </>
  );
};

export default Dollar;
