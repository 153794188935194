import { SVGProps } from "react";
export interface ISocialIconProps {
  props?: SVGProps<SVGSVGElement>;
  fill?: string;
}
const Github = ({ props, fill }: ISocialIconProps) => (
  <svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.987.744a7.498 7.498 0 0 0-7.5 7.5 7.495 7.495 0 0 0 5.128 7.115c.375.066.516-.16.516-.356 0-.178-.01-.769-.01-1.397-1.884.347-2.371-.46-2.521-.881-.085-.216-.45-.881-.769-1.06-.262-.14-.637-.487-.01-.496.591-.01 1.013.543 1.154.768.675 1.135 1.753.816 2.184.62.066-.488.263-.816.478-1.004-1.668-.188-3.412-.834-3.412-3.703 0-.816.29-1.49.769-2.016-.075-.187-.338-.956.075-1.987 0 0 .628-.197 2.062.769a6.96 6.96 0 0 1 1.875-.254 6.96 6.96 0 0 1 1.875.254c1.434-.975 2.063-.77 2.063-.77.412 1.032.15 1.8.075 1.988.478.525.768 1.19.768 2.016 0 2.878-1.753 3.516-3.422 3.703.272.234.507.684.507 1.387 0 1.004-.01 1.81-.01 2.063 0 .197.14.431.516.356a7.513 7.513 0 0 0 5.11-7.115c0-4.144-3.357-7.5-7.5-7.5Z"
      fill={fill ? fill : "#fff"}
    />
  </svg>
);

export default Github;
