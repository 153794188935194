import * as React from "react"
import { SVGProps } from "react"
import useSelectorTyped from "../../../../../store/useSelectorTyped"

const SvgComponent = (props: SVGProps<SVGSVGElement>) => {
  const theme = useSelectorTyped(state => state?.fromClient?.theme);
  return (
    <svg
      width={22}
      height={20}
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.357 7C2.72 7.68 1 8.845 1 10.175 1 12.282 5.405 14 10.85 14c.74 0 1.26-.039 1.95-.097"
        stroke={theme === 1 ? "#A9B6BD" : "#FFFFFF"}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.836 13.9c-.413-.596-.806-1.133-1.18-1.8-2.75-4.9-3.488-9.77-1.63-10.873C7.176.53 9.073 1.48 11 3.481"
        stroke={theme === 1 ? "#A9B6BD" : "#FFFFFF"}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.429 13.387c-.702 2.688-.56 4.716.56 5.395 1.783 1.08 5.387-1.958 8.043-6.804.36-.67.683-1.329.968-1.978"
        stroke={theme === 1 ? "#A9B6BD" : "#FFFFFF"}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 16.52c1.928 2 3.817 2.95 4.978 2.253 1.85-1.102 1.121-5.972-1.633-10.873-.384-.677-.777-1.204-1.18-1.8"
        stroke={theme === 1 ? "#A9B6BD" : "#FFFFFF"}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.66 13C19.27 12.313 21 11.15 21 9.824 21 7.714 16.59 6 11.153 6c-.747 0-1.266.029-1.955.087"
        stroke={theme === 1 ? "#A9B6BD" : "#FFFFFF"}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 10c.285-.66.607-1.308.968-1.978 2.647-4.844 6.253-7.89 8.046-6.8 1.11.678 1.262 2.705.56 5.392m-5.314 3.401h-.01c-.01.13-.12.24-.26.24a.263.263 0 0 1-.25-.26c0-.14.11-.25.24-.25h-.01c.13-.01.25.11.25.24"
        stroke={theme === 1 ? "#A9B6BD" : "#FFFFFF"}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SvgComponent
