import { Fragment, useCallback, useEffect, useRef, useState } from 'react'
import { SimpleCarousel } from 'react-whirlpool'
import 'react-whirlpool/dist/cjs/component/SimpleCarousel.css'
import styled from 'styled-components'
import { A7ACenteredRow } from '../../../utils/Container'
import { useMediaQuery } from '../../../utils/useMediaQuery'
import { SocialButton } from '../../common/button'
import LeftArrow from '../../common/svg/common/LeftArrow'
import RightArrow from '../../common/svg/common/RightArrow'
import {
  AIMLCoding,
  AIMLCodingWeb,
  ARVRSolutions,
  ARVRSolutionsWeb,
  Automation,
  AutomationWeb,
  BackendDevelopment,
  BackendDevelopmentWeb,
  BlockChainDevelopment,
  BlockChainDevelopmentWeb,
  CustomSoftwareDevelopment,
  CustomSoftwareDevelopmentWeb,
  DesktopApps,
  DesktopAppsWeb,
  DevOps,
  DevOpsWeb,
  MobileAppsDevelopment,
  MobileAppsDevelopmentWeb,
  WebAppsDevelopment,
  WebAppsDevelopmentWeb,
} from '../../common/svg/landing/what-we-offer'
import SectionWrapper from '../../common/ui/SectionWrapper'
import SizedBox from '../../common/ui/SizedBox'
import WhatWeOfferCard from './WhatWeOfferCard'
import { useTranslation } from 'next-i18next'

const CarouselListContainer = styled.div`
  background-color: ${(props) => props.theme.colors.background};
  margin: ${(props) => props.theme.constrainedMargin};
  ${(props) => props.theme.mediaQuery.tablet} {
    margin: 0px 0px 0px 0px;
    width: 100vw;
    gap: 40px;
  } ;
`

export interface ISectionProps {
  onView: (e: number) => void
}
const WhatWeOffer = () => {
  const isMobile = useMediaQuery('(max-width: 766px)')
  const [activeIndex, setActiveIndex] = useState(0)
  const simpleCarouselRef = useRef<any>()
  const { t } = useTranslation('landing')

  const carouselList = [
    {
      id: 0,
      title: 'Mobile apps development',
      type: 'mobile',
      image: isMobile ? (
        <MobileAppsDevelopment />
      ) : (
        <MobileAppsDevelopmentWeb />
      ),
      description:
        'We offer top-notch mobile app development services to help businesses of all sizes develop high-quality and user-friendly mobile apps.',
    },
    {
      id: 1,
      title: 'Web apps development',
      type: 'web-apps',
      image: isMobile ? <WebAppsDevelopment /> : <WebAppsDevelopmentWeb />,
      description:
        "With years of experience and a passion for creating beautiful, functional websites, we're ready to help bring your vision to life and make a lasting impression to the world.",
    },
    {
      id: 2,
      title: 'Desktop apps',
      type: 'desktop',
      image: isMobile ? <DesktopApps /> : <DesktopAppsWeb />,
      description:
        'We provide expert desktop app development services to help businesses launch successful apps. We have the expertise to create custom solutions for any need.',
    },
    {
      id: 3,
      title: 'AR/VR Solutions',
      type: 'ar-vr',
      image: isMobile ? <ARVRSolutions /> : <ARVRSolutionsWeb />,
      description:
        'With our comprehensive AR and VR services, you can count on us to help you launch successful and immersive experiences that capture the attention and imagination of your target audience.',
    },
    {
      id: 4,
      title: 'AI/ML Coding',
      type: 'ai-ml',
      image: isMobile ? <AIMLCoding /> : <AIMLCodingWeb />,
      description:
        'Whether you are looking to automate routine tasks, optimize your workflow, or gain valuable insights from your data, we can provide the expert support and guidance you need.',
    },
    {
      id: 5,
      title: 'Automation',
      type: 'automation',
      image: isMobile ? <Automation /> : <AutomationWeb />,
      description:
        'Our team uses the latest technologies, such as machine learning and puppeteer, to create custom solutions that can automate a wide range of tasks.',
    },
    {
      id: 6,
      title: 'Backend development',
      type: 'backend',
      image: isMobile ? <BackendDevelopment /> : <BackendDevelopmentWeb />,
      description:
        'Whether you need a simple backend or a complex system for a large-scale application, we have the expertise to help you launch a successful and reliable web app.',
    },
    {
      id: 7,
      title: 'Blockchain development',
      type: 'blockchain',
      image: isMobile ? (
        <BlockChainDevelopment />
      ) : (
        <BlockChainDevelopmentWeb />
      ),
      description:
        'We can help you launch a successful and secure blockchain-based application that drives business value and sets your organization apart from the competition',
    },
    {
      id: 8,
      title: 'Dev-Ops',
      type: 'dev-ops',
      image: isMobile ? <DevOps /> : <DevOpsWeb />,
      description:
        'We provide devops services to help businesses and organizations of all sizes improve the efficiency and reliability of their software development and deployment processes.',
    },
    {
      id: 9,
      title: 'Custom software development',
      type: 'custom-software',
      image: isMobile ? (
        <CustomSoftwareDevelopment />
      ) : (
        <CustomSoftwareDevelopmentWeb />
      ),
      description:
        'We provide custom software development with low level programming languages like embedded C, elixir, perl which are fault tolerant and highly scalable.',
    },
  ]
  // const [ref, inView] = useInView({
  //   rootMargin: "0px 2000px 500px 0px",
  //   threshold: 1,
  // });

  // useEffect(() => {
  //   if (inView === true) {
  //     onView(1);
  //     console.log("What we offer in view");
  //   }
  // }, [inView, onView]);
  return (
    <>
      <SectionWrapper
        id="labs"
        carouselButtons={false}
        heading={t('whatWeOffer.0.whatWeOfferTitle')}
        description={t('whatWeOffer.1.whatWeOfferDescription') as string}
      >
        <CarouselListContainer>
          <>
            <SizedBox height={isMobile ? 30 : 0} width={isMobile ? 0 : 90} />
            <SimpleCarousel
              isHorizontal={isMobile ? false : true}
              gap={isMobile ? 0 : 50}
              minHeight={'650px'}
              hideInitGap={false}
              autoPlay={false}
              ref={simpleCarouselRef}
              hideArrows={true}
              onActiveIndexUpdate={(index: number) => setActiveIndex(index)}
            >
              {carouselList.map((card, j) => (
                <Fragment key={card.id}>
                  <WhatWeOfferCard
                    isActive={card.id === activeIndex}
                    image={card.image}
                    description={t(
                      `whatWeOffer.2.whatWeOfferCards.${j}.description`
                    )}
                    title={t(`whatWeOffer.2.whatWeOfferCards.${j}.title`)}
                    type={card.type}
                  />
                  {j < carouselList.length - 1 && isMobile && (
                    <SizedBox height={isMobile ? 20 : 0} width={0} />
                  )}
                </Fragment>
              ))}
            </SimpleCarousel>
            {!isMobile && <>
            <SizedBox height={30} width={0} />
            <A7ACenteredRow>
              <SocialButton
                ariaLabel="Previous"
                onClick={() => {
                  simpleCarouselRef.current &&
                    simpleCarouselRef.current.handlePrevEvent()
                }}
              >
                <LeftArrow />
              </SocialButton>
              <SizedBox height={0} width={9} />
              <SocialButton
                ariaLabel="Next"
                onClick={() => {
                  simpleCarouselRef.current &&
                    simpleCarouselRef.current.handleNextEvent()
                }}
              >
                <RightArrow />
              </SocialButton>
              </A7ACenteredRow>
              </>}
          </>
        </CarouselListContainer>
      </SectionWrapper>
    </>
  )
}

export default WhatWeOffer
