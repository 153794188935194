import { TailSpin } from "react-loader-spinner";
import React from "react";
import styled, { css } from "styled-components";
import HighlightShine from "../transitions/HighlightShine";

export const GenericButtonWrapper = styled.button<{
  height: number;
  width?: number;
  $isFullWidth: boolean;
  $isDisabled: boolean;
  fontSize?: number;
  type?: "button" | "submit" | "reset" | undefined;
  backgroundcolor?: string;
  textcolor?: string;
  padding?: string;
}>`
  position: relative;
  border-radius: 30px;
  padding: ${(props) => (props.padding ? props.padding : "0")};
  background-image: ${(props) =>
    props.backgroundcolor ? "transparent" : props.theme.colors.accent};
  background-color: ${(props) =>
    props.backgroundcolor ? props.backgroundcolor : props.theme.colors.accent};
  height: ${(props) => props.height}px;
  width: ${(props) =>
    props.$isFullWidth
      ? "100%"
      : props.width
      ? `${props.width}px`
      : "fit-content"};
  display: flex;
  border: transparent;
  align-items: center;
  justify-content: center;
  cursor: ${(props) => (props.$isDisabled ? "not-allowed" : "pointer")};
  color: ${(props) =>
    props.$isDisabled
      ? props.theme.colors.textColors[1]
      : props.textcolor
      ? props.textcolor
      : "#fff"};
  span {
    font-family: "Lemon Milk";
    font-weight: 500;
    font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : "12px")};
  }
  &:focus {
    outline: 5px solid
      ${(props) =>
        props.backgroundcolor
          ? `${props.backgroundcolor}80`
          : `${props.theme.colors.secondary}80`};
  }

  ${(props) =>
    props.$isDisabled &&
    css`
      background-color: ${(props) => props.theme.colors.secondary};
      background-image: none;
      /* background-color: ${(props) => props.theme.colors.accent}; */
      span {
        color: ${(props) => props.theme.colors.textColors[2]};
      }
    `}

  &:hover {
    transition: scale 0.2s ease;
    transform: scale(1.05);
  }
`;

export interface GenericButtonWrapperProps {
  text: string;
  height: number;
  width?: number;
  isFullWidth: boolean;
  isDisabled: boolean;
  isProcessing: boolean;
  textColor?: string;
  fontSize?: number;
  backgroundcolor?: string;
  padding?: string;
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
  type?: "button" | "submit" | "reset" | undefined;
  showCross?: boolean;
  children?: JSX.Element | JSX.Element[];
}
const GenericButton = React.forwardRef<
  HTMLButtonElement,
  GenericButtonWrapperProps
>(
  (
    {
      text,
      height,
      width,
      isFullWidth,
      isDisabled,
      isProcessing,
      onClick,
      padding,
      backgroundcolor,
      fontSize,
      type,
      textColor,
      children,
    },
    ref
  ) => {
    return (
      <GenericButtonWrapper
        backgroundcolor={backgroundcolor}
        height={height}
        fontSize={fontSize}
        type={type}
        textcolor={textColor}
        width={width}
        padding={padding}
        $isFullWidth={isFullWidth}
        $isDisabled={isDisabled}
        onClick={onClick}
      >
        {!isDisabled && <HighlightShine />}
        {isProcessing ? (
          <TailSpin color="#ffffff" height={30} width={30} />
        ) : (
          <span>{text}</span>
        )}

        {!isProcessing && children}
      </GenericButtonWrapper>
    );
  }
);

GenericButton.displayName = "GenericButton";

export default GenericButton;
