import styled from "styled-components";

export const Bar = styled.div`
  margin-left: 15px;
  background-image: ${(props) => props.theme.colors.accent};
  width: 14px;
  height: 54px;
  transform: skewX(-30deg);
`;
const TiltedBar = () => {
  return (
    <div>
      <Bar />
    </div>
  );
};

export default TiltedBar;
