import styled from "styled-components";
import Chat from "../svg/common/Chat";
import {
  IconContainer,
  IInputProps,
  InputContainer,
} from "./A7AInputField";

const TextAreaInput = styled.textarea`
  padding: 13px 15px 13px 41px;
  width: 100%;
  background: rgba(255, 255, 255, 0.01);
  border: 1px solid ${(props) => props.theme.colors.borderColor2};
  color: ${(props) => props.theme.colors.textColors[0]};
  ::placeholder {
    font-family: "Lato", sans-serif;
  }
  border-radius: 10px;
`;

interface ITextAreaProps extends Omit<IInputProps, "onChange"> {
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const A7ATextArea = ({
  placeholder,
  id,
  onChange,
  value,
  inputMode,
}: ITextAreaProps) => {
  return (
    <>
      <InputContainer>
        <IconContainer style={{ top: 14, height: 50 }}>
          <Chat />
        </IconContainer>
        <TextAreaInput
          rows={4}
          id={id}
          inputMode={inputMode}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
        />
      </InputContainer>
    </>
  );
};

export default A7ATextArea;
