import Image from "next/image";
import { useRef, useState } from "react";
import styled from "styled-components";
import { A7ACenteredRow, SquircleOutlined3 } from "../../../utils/Container";

const TechStackWrapper = styled(A7ACenteredRow)<{
  wordWidth: number;
}>`
  ${SquircleOutlined3};
  gap: 0px;
  padding: 10px 15px;
  width: fit-content;
  transition: 0.7s all;
  display: grid;
  grid-template-columns: 40px minmax(0px, 1fr);
  align-items: center;
  ${(props) => props.theme.mediaQuery.tablet} {
    gap: 0px;
  }
  cursor: pointer;

  p {
    float: right !important;
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    opacity: 0;
    word-break: keep-all;
    white-space: nowrap;
    width: 0px;
    color: ${(props) => props.theme.colors.secondary2};
    transition: width 0.5s ease; // (text being removed)
  }
  &:hover {
    gap: 5px;
    ${(props) => props.theme.mediaQuery.tablet} {
      gap: 8px;
    }
    p {
      width: ${(props) => props.wordWidth}px;
      opacity: 1;
      transition: 0.8s opacity, 0.5s width;
    }
  }
`;
interface ITechStackButtonProps {
  icon: JSX.Element | string | any;
  name: string;
  tkey: number;
}

const TechStackButton = ({ icon, name, tkey }: ITechStackButtonProps) => {
  const [hoverState, setHoverState] = useState(false);
  const techStackButtonRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <TechStackWrapper
        wordWidth={name.length <= 5 ? name.length * 8 : name.length * 6}
        aria-hidden={tkey > 99 ? "true" : "false"}
        ref={techStackButtonRef}
        onMouseOver={() => {
          setHoverState(true);
        }}
        onMouseLeave={() => {
          setHoverState(false);
        }}
      >
        <A7ACenteredRow><Image src={icon} width={40} height={40} alt=""/></A7ACenteredRow>
        <div>
          <p>{name}</p>
        </div>
      </TechStackWrapper>
    </>
  );
};

export default TechStackButton;
