import { useTranslation } from "next-i18next";
import SectionWrapper from '../../common/ui/SectionWrapper'
import Marquee from './Marquee'

const NUXTTechStack = () => {
  const { t } = useTranslation("landing");
  return (
    <>
      <SectionWrapper
        id="tech-stack"
        heading={t('techStack.0.techStackTitle')}
        description={t('techStack.1.techStackDescription') as string}
      >
        <Marquee />
      </SectionWrapper>
    </>
  )
}

export default NUXTTechStack
