import { useEffect, useState } from 'react'
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar'
import styled from 'styled-components'
import useSelectorTyped from '../../../../store/useSelectorTyped'
import { A7ACenteredColumn } from '../../../../utils/Container'
import { useMediaQuery } from '../../../../utils/useMediaQuery'
import { ToolTip } from '../../../common/ui/ToolTip'

const ProjectCircleContainer = styled(A7ACenteredColumn)`
  width: 100%;
  margin-top: 8px;
  gap: 4px;
  ${(props) => props.theme.mediaQuery.tablet} {
    margin: 0px;
    height: 99%;
    gap: 8px;
    flex-direction: row;
    align-items: center;
  }
  p {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: ${(props) => props.theme.colors.textColors[0]};
    ${(props) => props.theme.mediaQuery.tablet} {
      font-size: 14px;
      line-height: 19px;
    }
  }

  & > span {
    position: relative;
    width: 35px;
    height: 35px;
    @media (max-width: 340px) {
      width: 30px;
      height: 30px;
    }
    ${(props) => props.theme.mediaQuery.tablet} {
      width: 19px;
      height: 19px;
    }
    position: relative;
    :hover {
      .tooltip {
        visibility: visible;
      }
    }

    & > span {
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      top: 10px;
      left: 0;
      right: 0;
      text-align: center;
      font-family: 'LEMON MILK';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #5bbf96;

      @media (max-width: 340px) {
        font-size: 10px;
        top: 6px;
      }
    }
  }
`
const ProjectCircleGrade = styled.div`
  font-family: 'LEMON MILK';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: ${(props) => props.theme.colors.accent2};
  display: none;
  ${(props) => props.theme.mediaQuery.tablet} {
    display: block;
  }
`

const Divider = styled.div`
  height: 44px;
  margin: 0px 5px;
  width: 1px;
  border-right: 1px solid ${(props) => props.theme.colors.borderColor};
  ${(props) => props.theme.mediaQuery.tablet} {
    margin: 0px;
    height: 24px;
  }
`

interface ICircularProgressBarProps {
  grade?: string
  value?: number
  name?: string
  type: number
  index: number
  isLast?: boolean
}
const CircularProgressBar = ({
  grade,
  value,
  name,
  type,
  index,
  isLast,
}: ICircularProgressBarProps) => {
  const theme = useSelectorTyped((state) => state?.fromClient?.theme)
  const isTablet = useMediaQuery('(max-width: 767px)')
  const [valueEnd, setValueEnd] = useState(0)

  const sleep = (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms))

  useEffect(() => {
    value &&
      (async () => {
        setValueEnd(0)
        await sleep(500)
        setValueEnd(value)
      })()
  }, [type])

  return (
    <>
      <ProjectCircleContainer>
        <span>
          <ToolTip top={-205} left={-65}>{value}</ToolTip>
          <CircularProgressbar
            strokeWidth={isTablet ? 10 : 6}
            styles={buildStyles({
              pathColor: `#5BBF96`,
              textColor: `#5BBF96`,
              textSize: 35,
              pathTransitionDuration: 0.5,
              trailColor: theme === 0 ? '#1F2530' : '#d3dde3',
            })}
            value={valueEnd}
          />
          {value && isTablet && <span>{value.toString()}</span>}
        </span>
        <ProjectCircleGrade>{grade}</ProjectCircleGrade>
        <p>{name}</p>
      </ProjectCircleContainer>
      {!isLast && <Divider />}
    </>
  )
}

export default CircularProgressBar
