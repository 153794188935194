import { SVGProps } from "react";
import useSelectorTyped from "../../../../store/useSelectorTyped";

const Mouse = (props: SVGProps<SVGSVGElement>) => {
  const theme = useSelectorTyped((store) => store?.fromClient?.theme);
  return (
    <svg
      width={22}
      height={31}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11 6a1.5 1.5 0 0 0-1.5 1.5v3a1.5 1.5 0 0 0 3 0v-3A1.5 1.5 0 0 0 11 6Zm0-6A10.5 10.5 0 0 0 .5 10.5v9.001a10.5 10.5 0 1 0 21 0v-9A10.5 10.5 0 0 0 11 0Zm7.5 19.501a7.5 7.5 0 1 1-15 0v-9a7.5 7.5 0 1 1 15 0v9Z"
        fill={theme === 0 ? "#fff" : "#737373"}
      />
    </svg>
  );
};

export default Mouse;
