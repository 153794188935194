import styled, { css } from 'styled-components'

export const A7ARow = styled.div`
  display: flex;
  flex-direction: row;
`

export const A7AXCenteredRow = styled(A7ARow)`
  justify-content: center;
`

export const A7AYCenteredRow = styled(A7ARow)`
  align-items: center;
`

export const A7ACenteredRow = styled(A7ARow)`
  justify-content: center;
  align-items: center;
`

export const A7AColumn = styled.div`
  display: flex;
  flex-direction: column;
`

export const A7AXCenteredColumn = styled(A7AColumn)`
  justify-content: center;
`

export const A7AYCenteredColumn = styled(A7AColumn)`
  align-items: center;
`

export const A7ACenteredColumn = styled(A7AColumn)`
  justify-content: center;
  align-items: center;
`

export const A7AXSpaceBetweenRow = styled(A7ARow)`
  justify-content: space-between;
`

export const A7AYSpaceBetweenRow = styled(A7AColumn)`
  align-items: center;
`

export const SquircleOutlined = css`
  background: paint(squircle);
  --squircle-radius: ${(props) => props.theme.borderRadius};
  --squircle-color: ${(props) => props.theme.colors.borderColor2};
  --squircle-fill: ${(props) => props.theme.colors.borderColor2};
  --squircle-outline: 1px;
  --squircle-smooth: 1;

  @supports (-webkit-backdrop-filter: blur(1px)) {
    border: 1px solid ${(props) => props.theme.colors.borderColor2};
  }
  @-moz-document url-prefix() {
    border: 1px solid ${(props) => props.theme.colors.borderColor2};
  }
`

export const SquircleOutlined2 = css`
  background: paint(squircle);
  --squircle-radius: ${(props) => props.theme.borderRadius};
  --squircle-color: ${(props) => props.theme.colors.borderColor};
  --squircle-fill: ${(props) => props.theme.colors.borderColor2};
  --squircle-outline: 1px;
  --squircle-smooth: 1;
  @supports (-webkit-backdrop-filter: blur(1px)) {
    border-radius: ${(props) => props.theme.borderRadius};
  }
  border-radius: ${(props) => props.theme.borderRadius};
  @-moz-document url-prefix() {
    border-radius: ${(props) => props.theme.borderRadius};
  }
`

export const SquircleOutlined3 = css`
  background: paint(squircle);
  --squircle-radius: ${(props) => props.theme.borderRadius};
  --squircle-color: ${(props) => props.theme.colors.borderColor};
  --squircle-fill: ${(props) => props.theme.colors.borderColor};
  --squircle-outline: 1px;
  --squircle-smooth: 0.3;
  @supports (-webkit-backdrop-filter: blur(1px)) {
    border: 1px solid ${(props) => props.theme.colors.borderColor};
    border-radius: ${(props) => props.theme.borderRadius};
  }
  @-moz-document url-prefix() {
    border: 1px solid ${(props) => props.theme.colors.borderColor};
    border-radius: ${(props) => props.theme.borderRadius};
  }
`

export const A7ATag = styled.div`
  height: 20px;
  border-radius: 20px;
  background-color: 'rgba(82, 192, 151, 0.2)';
  padding: 0px 8px 0 9px;
  align-items: center;
  justify-content: center;
  margin-top: -1px;
`

export const A7ATagText = styled.p`
  color: #52c097;
  font-size: 10px;
  font-family: sfpro;
`

export const A7ASolidTag = styled.div`
  height: 20px;
  border-radius: 20px;
  background-color: 'rgba(82, 192, 151, 1)';
  padding: 0px 8px 0 9px;
  align-items: center;
  justify-content: center;
  margin-top: -1px;
`

export const A7ASolidTagText = styled.p`
  color: #fff;
  font-size: 10px;
  font-family: sfpro;
`

// BORDER OUTLINE

//  background: paint(squircle);
//   --squircle-fill: rgba(255, 255, 255, 0.2);
//   --squircle-radius: ${(props) => props.theme.borderRadius};
//   --squircle-outline: 1px;

// BORDER FILL

// mask: paint(squircle);
// background: blue;
// --squircle-radius: 20px;
// --squircle-smooth: 1;

export const SectionHeroWrapper = styled(A7AColumn).attrs({ as: 'section' })<{
  isDarkTheme: number
  backgroundImage?: string
}>`
  /* background-image: url(${(props) =>
    props.isDarkTheme
      ? '/images/HeroLightBackground.webp'
      : '/images/ServicesBackgroundDark.png'}); */
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(${(props) =>
    props.backgroundImage
      ? props.backgroundImage
      : '/images/ServicesBackgroundDark.png'});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0px 0px 20px 20px;
  min-height: 330px;
  ${(props) => props.theme.mediaQuery.tablet} {
    height: 463px;
  }
`
