import { SVGProps } from 'react'
import useSelectorTyped from '../../../../../store/useSelectorTyped'

const Firebase = (props: SVGProps<SVGSVGElement>) => {
  const theme = useSelectorTyped((state) => state?.fromClient?.theme)
  return (
    <svg
      width={18}
      height={21}
      viewBox="0 0 18 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.295 3.146a.484.484 0 0 1 .552-.407l.008.001c.148.023.288.121.36.254.018.036.04.07.063.103l.962 1.366-3.13 5.965 1.182-7.266.003-.016Zm.792 7.818 5.048-4.932-.812-1.54a.543.543 0 0 0-.21-.21.47.47 0 0 0-.586.112 1.004 1.004 0 0 1-.046.102l-3.394 6.468Zm6.516-6.436a2.488 2.488 0 0 1 3.6-.094c.373.374.607.847.69 1.356v.002l1.5 9.107v.003a2.478 2.478 0 0 1-1.317 2.602l-6.049 2.89a2.489 2.489 0 0 1-2.376-.006L1.659 17.48a2.493 2.493 0 0 1-1.3-2.599v-.001L2.316 2.848A2.484 2.484 0 0 1 5.16.764a2.505 2.505 0 0 1 1.783 1.225l.497.705a2.47 2.47 0 0 1 2.617-.174l.002.001c.433.233.794.594 1.027 1.026l.004.008.513.973Zm1.511 1.389c.054-.053.1-.11.14-.17a.488.488 0 0 1 .534.102.465.465 0 0 1 .13.262v.003l1.5 9.107v.001a.478.478 0 0 1-.23.49l-6.044 2.887a1.008 1.008 0 0 0-.046.024.49.49 0 0 1-.482.013.97.97 0 0 0-.064-.034l-5.627-2.73 10.19-9.955Z"
        fill={theme === 1 ? '#A9B6BD' : '#FFFFFF'}
      />
    </svg>
  )
}

export default Firebase
