import Link from "next/link";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { A7ACenteredColumn } from "../../../utils/Container";

interface IA7APageNavigator {
  items: any[];
  activeIndexState: number;
}

export const NavbarItemContainer = styled(A7ACenteredColumn)<{
  isActive: boolean;
}>`
  cursor: pointer;
  position: relative;
  p {
    // margin-bottom: 10px;
    font-weight: normal;
    font-style: normal;
    font-size: 12px;
    line-height: 16px;
    font-family: "LEMON MILK";
    background: ${(props) =>
      props.isActive
        ? props.theme.colors.accent
        : props.theme.colors.textColors[0]};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    white-space: nowrap;
  }
`;

export const NavbarActiveIndicator = styled.div<{
  isActive: boolean;
  width: number;
  x: number;
  y: number;
}>`
  border-image: linear-gradient(90deg, #26a7cf 0%, #27af8f 100%) 1;
  border-radius: 8px;
  cursor: pointer;
  position: absolute;
  height: 4px;
  top: ${(props) => props.y + 36}px;
  width: ${(props) => props.width}px;
  left: ${(props) => props.x}px;
  bottom: ${(props) => props.y}px;
  visibility: ${(props) => (!props.isActive ? "hidden" : "visible")};
  background: ${(props) => props.theme.colors.accent};
  justify-self: center;
  transition: left 0.8s, width 2s;
  z-index: 2;
`;

const A7APageNavigatorWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 80px;
  justify-content: flex-end;
  grid-gap: 25px;
  @media only screen and (max-width: 767px) {
    display: none;
  }
  @media only screen and (max-width: 860px) {
    grid-gap: 12px;
  }
`;

const A7APageNavigator = React.forwardRef(
  ({ items, activeIndexState }: IA7APageNavigator, ref: any): JSX.Element => {
    const refs = useRef<any>([]);
    const [widthState, setWidthState] = useState(0);
    const [xState, setXState] = useState(0);
    const [yState, setYState] = useState(0);

    const handleUpdatePosition = () => {
      setWidthState(
        refs.current[activeIndexState - 1]?.getBoundingClientRect()?.width || 0
      );
      setXState(
        refs.current[activeIndexState - 1]?.getBoundingClientRect()?.left -
          ref?.current?.getBoundingClientRect().x || 0
      );
      setYState(
        refs.current[activeIndexState - 1]?.getBoundingClientRect()?.height || 0
      );
    };

    useEffect(() => {
      let resizeEvent: any;
      handleUpdateNavIndicatorPositionWithDelay(400);
      resizeEvent = window.addEventListener("resize", (v) => {
        handleUpdateNavIndicatorPositionWithDelay(400);
      });
      return () => {
        window.removeEventListener("resize", resizeEvent);
      };
    }, [activeIndexState]);

    const handleUpdateNavIndicatorPositionWithDelay = (ms: number) => {
      setTimeout(() => {
        handleUpdatePosition();
      }, ms);
    };

    return (
      <A7APageNavigatorWrapper>
        <NavbarActiveIndicator
          isActive={activeIndexState == 0 ? false : true}
          width={widthState}
          x={xState}
          y={yState}
        />
        {items.map((item, index) => (
          <Link
            scroll={item?.scrollToTop}
            legacyBehavior
            href={item?.link || ""}
            key={index}
          >
            <a ref={(r) => refs.current.push(r)}>
              <NavbarItemContainer isActive={activeIndexState === index + 1}>
                <p>{item?.name}</p>
              </NavbarItemContainer>
            </a>
          </Link>
        ))}
      </A7APageNavigatorWrapper>
    );
  }
);

A7APageNavigator.displayName = "A7APageNavigator";

export default A7APageNavigator;
