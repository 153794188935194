import { SVGProps } from 'react'
import useSelectorTyped from '../../../../../store/useSelectorTyped'

const NuxtJS = (props: SVGProps<SVGSVGElement>) => {
  const theme = useSelectorTyped((state) => state?.fromClient?.theme)
  return (
    <svg
      width={21}
      height={15}
      viewBox="0 0 21 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m10.96 4.285-1.3-2.09a1.046 1.046 0 0 0-1.786.017l-5.91 9.908a1.046 1.046 0 0 0 .897 1.582h3.913m12.083 0c.743 0 1.201-.843.82-1.505l-4.044-7.013a.936.936 0 0 0-1.638 0l-4.043 7.013c-.382.662.076 1.505.82 1.505h8.085Z"
        stroke={theme === 1 ? "#A9B6BD" : "#FFFFFF"}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default NuxtJS
