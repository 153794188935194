import Image from "next/image";
import Link from "next/link";
import styled from "styled-components";
import {
  A7ACenteredRow,
  A7AColumn,
  A7ARow,
  SquircleOutlined3,
} from "../../../utils/Container";
import Share from "../../common/svg/common/Share";
import SizedBox from "../../common/ui/SizedBox";
import { toast } from "react-toastify";

const ArticleCardContainer = styled(A7AColumn)<{ isActive: boolean }>`
  justify-content: flex-start;
  cursor: pointer;
  width: 100%;
  min-width: 250px;
  border-radius: ${(props) => props.theme.borderRadius};
  ${(props) => props.theme.mediaQuery.tablet} {
    width: 350px;
  }
`;

const ArticleCardImage = styled.div`
  width: 100%;
  height: 201px;
  position: relative;
  img {
    /* max-width: 201px; */
    height: 201px;
    object-fit: cover;
    border-radius: ${(props) => props.theme.borderRadius};
  }
`;

const ArticleCardTitle = styled.h2<{ isActive: boolean }>`
  margin: 10px 0px 0px 0px;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  min-height: 40px;
  color: ${(props) => props.theme.colors.textColors[0]};
  ${(props) => props.theme.mediaQuery.tablet} {
    font-size: 16px;
    line-height: 22px;
  }

  background: ${(props) =>
    props.isActive ? props.theme.colors.accent : "inital"};
  -webkit-background-clip: ${(props) => (props.isActive ? "text" : "inital")};
  -webkit-text-fill-color: ${(props) =>
    props.isActive ? "transparent" : "inital"};
  background-clip: text;
`;

const ArticleCardDescription = styled.p`
  margin: 10px 0px 0px 0px;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: ${(props) => props.theme.colors.textColors[0]};

  overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 3;
           line-clamp: 3; 
   -webkit-box-orient: vertical;

  ${(props) => props.theme.mediaQuery.tablet} {
    font-size: 16px;
    line-height: 19px;
  }
`;

export const ArticleCardTags = styled.div`
  ${SquircleOutlined3}
  color: ${(props) => props.theme.colors.secondary2};
  padding: 0 12px 0 8px;
  font-weight: 400;
  height: 30px;
  font-size: 8px;
  line-height: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  grid-gap: 0 8px;
  img {
    height: 18px !important;
    width: 18px !important;
    position: initial !important;
  }
  ${(props) => props.theme.mediaQuery.tablet} {
    font-size: 10px;
    line-height: 12px;
  }
`;

const ShareIconContainer = styled(A7ACenteredRow)`
  position: absolute;
  top: 18px;
  right: 19px;
`;
interface IArticleCardProps {
  id: number;
  image: string;
  title: string;
  description: string;
  tags: string[];
  isActive: boolean;
  share?: boolean;
  noRedirect?: boolean;
}
const ArticleCard = ({
  id,
  image,
  title,
  description,
  tags,
  isActive,
  share,
  noRedirect,
}: IArticleCardProps) => {
  const handleShare = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const data = {
      title: title,
      url: `https://a7alabs.com/article/${id}`,
    };
    if (navigator?.canShare && navigator.canShare(data)) {
      navigator
        .share(data)
        .then(() => {})
        .catch((e) => {});
    } else {
      navigator.clipboard.writeText(`https://a7alabs.com/article/${id}`);
      toast.success("Link copied to clipboard!");
    }
  };
  return (
    <>
      {noRedirect ? (
        <div>
          <ArticleCardContainer
            key={id}
            isActive={isActive}
            style={{ cursor: "initial" }}
          >
            <ArticleCardImage>
              <Image src={image} alt={title} fill loading="lazy" />
              {share && (
                <ShareIconContainer onClick={handleShare}>
                  <Share />
                </ShareIconContainer>
              )}
            </ArticleCardImage>
            <ArticleCardTitle isActive={isActive}>{title}</ArticleCardTitle>
            <ArticleCardDescription>{description}</ArticleCardDescription>
            <SizedBox height={20} width={0} />
            <A7ARow style={{ gap: 10, flexWrap: "wrap" }}>
              {tags.map((tag: any, j) => (
                <ArticleCardTags key={j}>
                  <Image src={tag.icon} alt={title} fill />
                  {tag.name}
                </ArticleCardTags>
              ))}
            </A7ARow>
          </ArticleCardContainer>
        </div>
      ) : (
        <div>
          <Link href={`/article/${id}`} legacyBehavior>
            <ArticleCardContainer key={id} isActive={isActive}>
              <ArticleCardImage>
                <Image src={image} alt={title} fill />
                {share && (
                  <ShareIconContainer onClick={handleShare}>
                    <Share />
                  </ShareIconContainer>
                )}
              </ArticleCardImage>
              <ArticleCardTitle isActive={isActive}>{title}</ArticleCardTitle>
              <ArticleCardDescription>{description}</ArticleCardDescription>
              <SizedBox height={20} width={0} />
              <A7ARow style={{ gap: 10, flexWrap: "wrap" }}>
                {tags.map((tag: any, j) => (
                  <ArticleCardTags key={j}>
                    <Image src={tag.icon} alt={title} fill />
                    {tag.name}
                  </ArticleCardTags>
                ))}
              </A7ARow>
            </ArticleCardContainer>
          </Link>
        </div>
      )}
    </>
  );
};

export default ArticleCard;
