import { SVGProps } from 'react'
import useSelectorTyped from '../../../../../store/useSelectorTyped'

const AndroidStudio = (props: SVGProps<SVGSVGElement>) => {
  const theme = useSelectorTyped((state) => state?.fromClient?.theme)

  return (
    <>
      <svg
        width={18}
        height={21}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M8.064.762h2v2h.5a1.5 1.5 0 0 1 1.5 1.5v3.5l-.44.44 1.64 2.84c1.11-1.09 1.8-2.6 1.8-4.28h2c0 2.42-1.07 4.59-2.77 6.06l3.14 5.44.13 2.22-1.87-1.22-3.07-5.33c-1.06.53-2.28.83-3.56.83-1.28 0-2.5-.3-3.56-.83l-3.07 5.33-1.87 1.22.13-2.22 5.81-10.06-.44-.44v-3.5a1.5 1.5 0 0 1 1.5-1.5h.5v-2Zm-1.56 11.43c.78.37 1.65.57 2.56.57.91 0 1.78-.2 2.56-.57l-1.46-2.53h-.01c-.62.6-1.56.6-2.18 0h-.01l-1.46 2.53m2.56-7.43a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z"
          fill={theme === 1 ? '#A9B6BD' : '#FFFFFF'}
        />
      </svg>
    </>
  )
}

export default AndroidStudio
