import AndroidStudioGradientIcon from "./AndroidStudioGradientIcon";
import ApolloGradientIcon from "./ApolloGradientIcon";
import AmazonWebServicesGradientIcon from "./AmazonWebServicesGradientIcon";
import DockerGradientIcon from "./DockerGradientIcon";
import FigmaGradientIcon from "./FigmaGradientIcon";
import FirebaseGradientIcon from "./FirebaseGradientIcon";
import FlaskGradientIcon from "./FlaskGradientIcon";
import FlutterGradientIcon from "./FlutterGradientIcon";
import GoogleCloudPlatformGradientIcon from "./GoogleCloudPlatformGradientIcon";
import GolangGradientIcon from "./GolangGradientIcon";
import GraphQlGradientIcon from "./GraphQlGradientIcon";
import Html5GradientIcon from "./Html5GradientIcon";
import JavascriptGradientIcon from "./JavascriptGradientIcon";
import KubernetesGradientIcon from "./KubernetesGradientIcon";
import KafkaGradientIcon from "./KafkaGradientIcon";
import MySqlGradientIcon from "./MySqlGradientIcon";
import NestGradientIcon from "./NestGradientIcon";
import NextJSGradientIcon from "./NextJSGradientIcon";
import NUXTGradientIcon from "./NUXTGradientIcon";
import PostGresGradientIcon from "./PostGresGradientIcon";
import PythonGradientIcon from "./PythonGradientIcon";
import ReactGradientIcon from "./ReactGradientIcon";
import ReactNativeGradientIcon from "./ReactNativeGradientIcon";
import SASSGradientIcon from "./SASSGradientIcon";
import VercelGradientIcon from "./VercelGradientIcon";
import VueGradientIcon from "./VueGradientIcon";


import AndroidStudioBaseGradient from "./AndroidStudioBaseGradient.svg";
import ApolloBaseGradient from "./ApolloBaseGradient.svg";
import AmazonWebServicesBaseGradient from "./AmazonWebServicesBaseGradient.svg";
import DockerBaseGradient from "./DockerBaseGradient.svg";
import FigmaBaseGradient from "./FigmaBaseGradient.svg";
import FirebaseBaseGradient from "./FirebaseBaseGradient.svg";
import FlaskBaseGradient from "./FlaskBaseGradient.svg";
import FlutterBaseGradient from "./FlutterBaseGradient.svg";
import GoogleCloudPlatformBaseGradient from "./GoogleCloudPlatformBaseGradient.svg";
import GolangBaseGradient from "./GolangBaseGradient.svg";
import GraphQlBaseGradient from "./GraphQlBaseGradient.svg";
import Html5BaseGradient from "./Html5BaseGradient.svg";
import JavascriptBaseGradient from "./JavascriptBaseGradient.svg";
import KubernetesBaseGradient from "./KubernetesBaseGradient.svg";
import KafkaBaseGradient from "./KafkaBaseGradient.svg";
import MySqlBaseGradient from "./MySqlBaseGradient.svg";
import NestBaseGradient from "./NestBaseGradient.svg";
import NextJSBaseGradient from "./NextJSBaseGradient.svg";
import NUXTBaseGradient from "./NUXTBaseGradient.svg";
import PostGresBaseGradient from "./PostGresBaseGradient.svg";
import PythonBaseGradient from "./PythonBaseGradient.svg";
import ReactBaseGradient from "./ReactBaseGradient.svg";
import ReactNativeBaseGradient from "./ReactNativeBaseGradient.svg";
import SASSBaseGradient from "./SASSBaseGradient.svg";
import VercelBaseGradient from "./VercelBaseGradient.svg";
import VueBaseGradient from "./VueBaseGradient.svg";




export {
  AmazonWebServicesGradientIcon,
  FigmaGradientIcon,
  FirebaseGradientIcon,
  GoogleCloudPlatformGradientIcon,
  JavascriptGradientIcon,
  KafkaGradientIcon,
  NUXTGradientIcon,
  PostGresGradientIcon,
  ReactGradientIcon,
  SASSGradientIcon,
  GraphQlGradientIcon,
  KubernetesGradientIcon,
  VercelGradientIcon,
  FlutterGradientIcon,
  ApolloGradientIcon,
  AndroidStudioGradientIcon,
  ReactNativeGradientIcon,
  NextJSGradientIcon,
  VueGradientIcon,
  FlaskGradientIcon,
  MySqlGradientIcon,
  DockerGradientIcon,
  Html5GradientIcon,
  NestGradientIcon,
  GolangGradientIcon,
  PythonGradientIcon,


  AmazonWebServicesBaseGradient,
  FigmaBaseGradient,
  FirebaseBaseGradient,
  GoogleCloudPlatformBaseGradient,
  JavascriptBaseGradient,
  KafkaBaseGradient,
  NUXTBaseGradient,
  PostGresBaseGradient,
  ReactBaseGradient,
  SASSBaseGradient,
  GraphQlBaseGradient,
  KubernetesBaseGradient,
  VercelBaseGradient,
  FlutterBaseGradient,
  ApolloBaseGradient,
  AndroidStudioBaseGradient,
  ReactNativeBaseGradient,
  NextJSBaseGradient,
  VueBaseGradient,
  FlaskBaseGradient,
  MySqlBaseGradient,
  DockerBaseGradient,
  Html5BaseGradient,
  NestBaseGradient,
  GolangBaseGradient,
  PythonBaseGradient,
};
