import { ISocialIconProps } from "./Github"
const Discord = ({ props, fill }: ISocialIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={12}
    fill="none"
    {...props}
  >
    <path
      fill="url(#a)"
      stroke="url(#b)"
      d="M5.3.619 5.263.55l-.076.013a10.502 10.502 0 0 0-2.67.822l-.027.012-.018.026C.784 3.914.326 6.35.555 8.75l.004.05.04.028.002.001a10.876 10.876 0 0 0 3.266 1.636h.001l.095.03.576-1.406.164-.4.419.104a7.99 7.99 0 0 0 3.755 0l.42-.104.163.4.572 1.405.095-.029h.001a10.82 10.82 0 0 0 3.267-1.636l.002-.001.039-.029.005-.047c.28-2.78-.445-5.196-1.905-7.327L5.3.619Zm0 0c.054.1.107.2.158.302l.155.31.344-.036a10.183 10.183 0 0 1 2.092 0l.347.036.153-.313a5.297 5.297 0 0 1 .162-.304l.037-.063.073.013c.923.156 1.82.432 2.67.822l.028.013m-6.22-.78 6.22.78m0 0 .017.025-.017-.025ZM3.694 8.212c.124.072.255.142.383.206l.417.207-.177.43-.302.738-.182.444-.452-.164a10.605 10.605 0 0 1-2.42-1.256l-.196-.136-.017-.238c-.159-2.215.295-4.456 1.806-6.75l.081-.123.135-.06A10.29 10.29 0 0 1 4.767.866l.385-.082.17.355c.042.088.084.178.119.261l.032.077.084-.012a9.99 9.99 0 0 1 2.896 0l.082.012.032-.076c.036-.084.076-.173.117-.26l.17-.358.387.083c.691.149 1.36.367 1.998.647l.134.059.082.122c1.318 1.982 1.989 4.209 1.795 6.751l-.018.237-.195.135c-.75.522-1.564.945-2.423 1.256l-.452.165-.181-.446-.3-.736-.174-.429.414-.207c.13-.065.26-.135.387-.208.143-.082.284-.17.412-.258l-.156-.157a6.271 6.271 0 0 1-.366.227 5.235 5.235 0 0 1-.878.416c-1.45.48-3.19.48-4.64 0a5.259 5.259 0 0 1-.877-.415l-.11.188Zm0 0a6.278 6.278 0 0 1-.414-.26l.414.26Zm1.736-2.85v.01c0 .555-.39.913-.771.913-.375 0-.77-.36-.77-.914 0-.556.384-.912.77-.912.388 0 .781.365.771.903Zm4.692 0v.01c0 .557-.386.913-.771.913-.375 0-.772-.36-.772-.914 0-.556.385-.912.772-.912s.78.365.771.904Z"
    />
    <defs>
      <linearGradient
        id="a"
        x1={0}
        x2={14}
        y1={11.106}
        y2={11.106}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#26A7CF" />
        <stop offset={1} stopColor="#27AF8F" />
      </linearGradient>
      <linearGradient
        id="b"
        x1={0}
        x2={14}
        y1={11.106}
        y2={11.106}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#26A7CF" />
        <stop offset={1} stopColor="#27AF8F" />
      </linearGradient>
    </defs>
  </svg>
)
export default Discord
