import { SVGProps } from "react";

const Chat = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={19}
    height={19}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.5 16.386h-6a4.505 4.505 0 0 1-4.5-4.5v-9a.75.75 0 0 1 .75-.75h9.75c2.482 0 4.5 2.018 4.5 4.5v5.25c0 2.481-2.018 4.5-4.5 4.5Zm3-9.75c0-1.655-1.345-3-3-3h-9v8.25c0 1.654 1.346 3 3 3h6c1.655 0 3-1.346 3-3v-5.25Z"
      fill="#A9B6BD"
    />
    <path
      d="M7.625 10.386a1.125 1.125 0 1 0 0-2.25 1.125 1.125 0 0 0 0 2.25ZM11.375 10.386a1.125 1.125 0 1 0 0-2.25 1.125 1.125 0 0 0 0 2.25Z"
      fill="#A9B6BD"
    />
  </svg>
);

export default Chat;
