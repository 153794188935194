import { SVGProps } from "react";
import useSelectorTyped from "../../../../../store/useSelectorTyped";

const GraphQl = (props: SVGProps<SVGSVGElement>) => {
  const theme = useSelectorTyped(state => state?.fromClient?.theme);
  return (
    <>
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M5.30849 7.34763L10.6935 4.31863M13.3085 4.31763L18.6925 7.34763M20.0005 9.58263V14.5826M18.6935 16.8186L13.3085 19.8476M10.6925 19.8476L5.30849 16.8176M4.00049 14.5826V9.58263M12.7725 4.86863L18.8935 15.0706M18.5005 16.0826H5.50049M5.10749 15.0706L11.2295 4.86963"
          stroke={theme === 1 ? "#A9B6BD" : "#FFFFFF"}
            strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.0005 5.08252C12.8289 5.08252 13.5005 4.41095 13.5005 3.58252C13.5005 2.75409 12.8289 2.08252 12.0005 2.08252C11.1721 2.08252 10.5005 2.75409 10.5005 3.58252C10.5005 4.41095 11.1721 5.08252 12.0005 5.08252Z"
          stroke={theme === 1 ? "#A9B6BD" : "#FFFFFF"}
            strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.0005 22.0825C12.8289 22.0825 13.5005 21.4109 13.5005 20.5825C13.5005 19.7541 12.8289 19.0825 12.0005 19.0825C11.1721 19.0825 10.5005 19.7541 10.5005 20.5825C10.5005 21.4109 11.1721 22.0825 12.0005 22.0825Z"
          stroke={theme === 1 ? "#A9B6BD" : "#FFFFFF"}
            strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.00049 9.58252C4.82892 9.58252 5.50049 8.91095 5.50049 8.08252C5.50049 7.25409 4.82892 6.58252 4.00049 6.58252C3.17206 6.58252 2.50049 7.25409 2.50049 8.08252C2.50049 8.91095 3.17206 9.58252 4.00049 9.58252Z"
          stroke={theme === 1 ? "#A9B6BD" : "#FFFFFF"}
            strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.00049 17.5825C4.82892 17.5825 5.50049 16.9109 5.50049 16.0825C5.50049 15.2541 4.82892 14.5825 4.00049 14.5825C3.17206 14.5825 2.50049 15.2541 2.50049 16.0825C2.50049 16.9109 3.17206 17.5825 4.00049 17.5825Z"
          stroke={theme === 1 ? "#A9B6BD" : "#FFFFFF"}
            strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.0005 17.5825C20.8289 17.5825 21.5005 16.9109 21.5005 16.0825C21.5005 15.2541 20.8289 14.5825 20.0005 14.5825C19.1721 14.5825 18.5005 15.2541 18.5005 16.0825C18.5005 16.9109 19.1721 17.5825 20.0005 17.5825Z"
          stroke={theme === 1 ? "#A9B6BD" : "#FFFFFF"}
            strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.0005 9.58252C20.8289 9.58252 21.5005 8.91095 21.5005 8.08252C21.5005 7.25409 20.8289 6.58252 20.0005 6.58252C19.1721 6.58252 18.5005 7.25409 18.5005 8.08252C18.5005 8.91095 19.1721 9.58252 20.0005 9.58252Z"
          stroke={theme === 1 ? "#A9B6BD" : "#FFFFFF"}
            strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default GraphQl;
