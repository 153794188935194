import { motion } from 'framer-motion'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { variantContainer, variantitem } from '../../../utils/Animations'
import {
  A7ACenteredColumn,
  A7ACenteredRow,
  A7AXSpaceBetweenRow,
  SquircleOutlined2,
} from '../../../utils/Container'
import { GenericButton } from '../../common/button'
import A7ACategoryNavigator from '../../common/navbar/A7ACategoryNavigator'
import RightArrow from '../../common/svg/common/RightArrow'
import SizedBox from '../../common/ui/SizedBox'
import TiltedBar from '../../common/ui/TiltedBar'
import ProjectCard from './ProjectCard'
import { useRouter } from 'next/router'
export const ProjectsWrapper = styled(A7ACenteredColumn).attrs({
  as: 'section',
})<{ services?: boolean }>`
  justify-content: flex-start;
  padding: 30px;
  max-width: 1440px;
  width: 100%;
  gap: 30px;
  margin: 0px auto;
  ${SquircleOutlined2}
  background: ${(props) => props.theme.colors.primary};
  ${(props) => props.theme.mediaQuery.tablet} {
    padding: 60px;
    gap: 60px;
    margin: 0 auto;
  }

  ${(props) =>
    props.services &&
    css`
      background: transparent;
      max-width: ${(props) => props.theme.desktopWidth};
      ${(props) => props.theme.mediaQuery.tablet} {
        padding: 60px 30px;
      }
      ${(props) => props.theme.mediaQuery.desktop} {
        padding: 60px 0px 0px 0px;
      }
    `}

  a {
    background: linear-gradient(90deg, #26a7cf 0%, #27af8f 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-decoration: underline;
  }
`

const ProjectsHeaderWrapper = styled.div`
  position: relative;
  width: 100%;
  display: grid;
  grid-template-areas:
    'title title title'
    'desc desc desc'
    'categoryNavbar categoryNavbar categoryNavbar';
  ${(props) => props.theme.mediaQuery.tablet} {
    grid-template-areas:
      'title categoryNavbar categoryNavbar categoryNavbar'
      'desc desc desc desc';
  }
`

const ProjectsHeaderContainer = styled(A7AXSpaceBetweenRow)`
  width: 100%;
  flex-wrap: wrap;
`

const ProjectsHeading = styled.h1`
  font-weight: 500;
  color: ${(props) => props.theme.colors.textColors[0]};
  font-size: 24px;
  line-height: 32px;
  margin: 0px;
  ${(props) => props.theme.mediaQuery.tablet} {
    font-size: 36px;
    line-height: 49px;
  }
`

const ProjectsDescription = styled(motion.p)`
  grid-area: desc;
  display: grid;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  margin: 28px 0px 0px 0px;
  ${(props) => props.theme.mediaQuery.tablet} {
    font-size: 16px;
    line-height: 19px;
  }
  color: ${(props) => props.theme.colors.textColors[1]};
`

const CategoryNavbar = styled(A7ACenteredRow)`
  grid-area: categoryNavbar;
  gap: 15px;
  text-align: center;
  justify-content: space-evenly;
  width: auto;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  gap: 15px;
  margin-top: 30px;
  display: block;
  @media (min-width: 500px) {
    display: flex;
  }
  ${(props) => props.theme.mediaQuery.tablet} {
    margin-top: 0px;
    margin-left: 20px;
    width: 380px;
    justify-self: flex-end;
    display: flex;
    gap: 25px;
  }
`

interface projectsData {
  type: number[]
  title: string
  labs: string[]
  description: string
  hasAnalysis?: boolean
  image: string
  stats?: statsProps[] | null
  techStack?: techStackProps[] | null
  url?: string | null
  caseStudyURL?: string | null
  projectStatus?: string
}
interface statsProps {
  name: string
  value: number
  grade: string
}
interface techStackProps {
  name: string
  icon: string
}

interface IProjectsProps {
  services?: boolean
  projectsSectionHeading?: string
  servicesDescription?: string
  projectsData: projectsData[]
  // typeofproject?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  typeofproject?: string
  // 0 = all , 1 = web , 2 = mobile , 3 = ar , 4 = open source
}
const Projects = ({
  services,
  typeofproject,
  servicesDescription,
  projectsData,
  projectsSectionHeading,
}: // { onView }: ISectionProps
IProjectsProps) => {
  const wrapperRef = useRef<any>()
  const sortBarState = [
    { id: 0, name: 'ALL' },
    { id: 1, name: 'WEB' },
    { id: 2, name: 'MOBILE' },
    { id: 3, name: 'AI/ML' },
    { id: 4, name: 'AR' },
    { id: 5, name: 'OPEN SOURCE' },
  ]

  //TODO MAKE THIS CONCRETE

  const [activeIndexState, setActiveIndexState] = useState(0)
  const [loadMoreProjects, setLoadMoreProjects] = useState(false)
  const [filteredProjectsData, setFilteredProjectsData] = useState(projectsData)
  const { t } = useTranslation('landing')
  const [hoverActive, setHoverActive] = useState('')
  const router = useRouter();
  useEffect(() => {
    // console.log('typeofproject', typeofproject)
    //? Landing page
    if (typeofproject === undefined) {
      const res =
        activeIndexState === 0
          ? projectsData
          : projectsData?.filter((i) => i.type.includes(activeIndexState))
      setFilteredProjectsData(res)
      //? For services page
    } else {
      const res = filteredProjectsData.filter((i) => i.labs.includes(router.asPath.split("/")[2]))
      setFilteredProjectsData(res)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndexState])

  return (
    <>
      <ProjectsWrapper services={services} id="projects">
        <ProjectsHeaderWrapper
          ref={wrapperRef}
          as={motion.div}
          variants={variantContainer}
          initial={'hidden'}
          whileInView={'show'}
          viewport={{ once: true }}
        >
          <ProjectsHeaderContainer>
            <A7ACenteredRow style={{ gridArea: 'title' }}>
              <TiltedBar />
              <SizedBox height={0} width={20} />
              <ProjectsHeading as={motion.h1} variants={variantitem}>
                {projectsSectionHeading ? (
                  projectsSectionHeading
                ) : (
                  <>{t('projects.projectsTitle')}</>
                )}
              </ProjectsHeading>
            </A7ACenteredRow>
          </ProjectsHeaderContainer>
          {!services ? (
            <CategoryNavbar>
              <A7ACategoryNavigator
                items={sortBarState}
                ref={wrapperRef}
                activeIndexState={activeIndexState}
                onChangeActive={(index: number) => setActiveIndexState(index)}
              />
            </CategoryNavbar>
          ) : null}
          <ProjectsDescription variants={variantitem}>
            {services
              ? servicesDescription
              : (t('projects.projectsDescription') as string)}
          </ProjectsDescription>
        </ProjectsHeaderWrapper>
        <>
          {filteredProjectsData
            ?.slice(0, loadMoreProjects ? filteredProjectsData.length : 3)
            .map((data, counter) => (
              <ProjectCard
                key={counter}
                type={data.type ? data.type : [0]}
                title={data.title}
                hasAnalysis={data.hasAnalysis}
                description={data.description}
                image={data.image}
                stats={data.stats!}
                url={data.url!}
                projectStatus={data.projectStatus}
                caseStudyURL={data.caseStudyURL!}
                techStack={data.techStack!}
                hoverActive={hoverActive}
                setHoverActive={(e) => setHoverActive(e)}
                counter={counter}
                activeIndexState={activeIndexState}
              />
            ))}
          {/* {loadMoreProjects === false && (
            <GenericButton
              text={t('projects.projectsButton')}
              height={50}
              width={231}
              isFullWidth={false}
              isDisabled={false}
              isProcessing={false}
              onClick={() => {
                setLoadMoreProjects(true)
              }}
            >
              <SizedBox width={18} height={0} />
              <RightArrow />
            </GenericButton>
          )} */}
        </>
        {filteredProjectsData.length > 3 && loadMoreProjects === false ? (
          <GenericButton
            text={t('projects.projectsButton')}
            height={50}
            width={231}
            isFullWidth={false}
            isDisabled={false}
            isProcessing={false}
            onClick={() => {
              setLoadMoreProjects(true)
            }}
          >
            <SizedBox width={18} height={0} />
            <RightArrow />
          </GenericButton>
        ) : null}
      </ProjectsWrapper>
    </>
  )
}

export default Projects
