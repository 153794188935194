import styled from 'styled-components'
import { useState } from 'react'
import {
  // AndroidStudioGradientIcon,
  // ApolloGradientIcon,
  // AmazonWebServicesGradientIcon,
  // DockerGradientIcon,
  // FigmaGradientIcon,
  // FirebaseGradientIcon,
  // FlaskGradientIcon,
  // FlutterGradientIcon,
  // GoogleCloudPlatformGradientIcon,
  // GolangGradientIcon,
  // GraphQlGradientIcon,
  // Html5GradientIcon,
  // JavascriptGradientIcon,
  // KubernetesGradientIcon,
  // KafkaGradientIcon,
  // MySqlGradientIcon,
  // NestGradientIcon,
  // NUXTGradientIcon,
  // PostGresGradientIcon,
  // PythonGradientIcon,
  // ReactGradientIcon,
  // ReactNativeGradientIcon,
  // SASSGradientIcon,
  // VercelGradientIcon,
  // VueGradientIcon,
  AmazonWebServicesBaseGradient,
  FigmaBaseGradient,
  FirebaseBaseGradient,
  GoogleCloudPlatformBaseGradient,
  JavascriptBaseGradient,
  KafkaBaseGradient,
  NUXTBaseGradient,
  PostGresBaseGradient,
  ReactBaseGradient,
  SASSBaseGradient,
  GraphQlBaseGradient,
  KubernetesBaseGradient,
  VercelBaseGradient,
  FlutterBaseGradient,
  ApolloBaseGradient,
  AndroidStudioBaseGradient,
  ReactNativeBaseGradient,
  NextJSBaseGradient,
  VueBaseGradient,
  FlaskBaseGradient,
  MySqlBaseGradient,
  DockerBaseGradient,
  Html5BaseGradient,
  NestBaseGradient,
  GolangBaseGradient,
  PythonBaseGradient,
} from '../../common/svg/landing/tech-stack-svg'
import SizedBox from '../../common/ui/SizedBox'
import TechStackButton from './TechStackButton'

const TechStackWrapper = styled.section`
  /* width: 100%; */
  margin-top: 30px;
  max-width: 100%;
  width: ${(props) => props.theme.desktopWidth};
  & > div {
    position: relative;
  }
  ${(props) => props.theme.mediaQuery.tablet} {
    margin-top: 60px;
  }
`

const MarqueeWrapper = styled.div`
  --gap: 10px;
  position: relative;
  display: flex;
  overflow: hidden;
  user-select: none;
  gap: var(--gap);
`

const MarqueeContainer = styled.div<{ reverse?: boolean; pause: boolean }>`
  flex-shrink: 0;
  display: flex;
  justify-content: space-around;
  gap: var(--gap);
  min-width: 100%;
  animation: scroll 45s linear infinite;
  animation-direction: ${(props) => (props.reverse ? 'reverse' : 'inital')};

  animation-play-state: ${(props) => (props.pause ? 'paused' : 'resume')};
  span {
    @keyframes scroll {
      from {
        transform: translateX(0);
      }
      to {
        transform: translateX(calc(-100% - var(--gap)));
      }
    }
  }
`

const MarqueeGradientBox = styled.div<{
  type: 'left' | 'right'
}>`
  z-index: 1;
  position: absolute;
  left: ${(props) => (props.type === 'left' ? '0' : 'unset')};
  right: ${(props) => (props.type === 'right' ? '0' : 'unset')};
  top: 0;
  // background: linear-gradient(270deg, #080309 22.97%, rgba(8, 3, 9, 0) 100%);
  background: ${(props) =>
    props.type == 'right'
      ? `linear-gradient(270deg, ${props.theme.colors.background}dd 40.97%, rgba(8, 3, 9, 0) 100%)`
      : `linear-gradient(90deg, ${props.theme.colors.background}dd 40.97%, rgba(8, 3, 9, 0) 100%)`};
  height: 100%;
  width: 30px;
`

const Marquee = () => {
  const mobileTechStack = [
    {
      icon: AndroidStudioBaseGradient ,
      name: 'Android Studio',
    },
    {
      icon: FlutterBaseGradient ,
      name: 'Flutter',
    },
    {
      icon: ReactNativeBaseGradient ,
      name: 'React Native',
    },
    {
      icon: ApolloBaseGradient ,
      name: 'Apollo',
    },
    {
      icon: GraphQlBaseGradient ,
      name: 'Apollo',
    },
    {
      icon: NestBaseGradient ,
      name: 'Nest JS',
    },
    {
      icon: MySqlBaseGradient ,
      name: 'MySql',
    },
    {
      icon: PostGresBaseGradient ,
      name: 'PostGres',
    },
    {
      icon: FlutterBaseGradient ,
      name: 'Flutter',
    },
    {
      icon: ReactNativeBaseGradient ,
      name: 'React Native',
    },
    {
      icon: ApolloBaseGradient ,
      name: 'Apollo',
    },
  ]
  const webTechStack = [
    {
      icon: ApolloBaseGradient ,
      name: 'Apollo',
    },
    {
      icon: FlaskBaseGradient ,
      name: 'Flask',
    },
    {
      icon: GraphQlBaseGradient ,
      name: 'GraphQl',
    },
    {
      icon: Html5BaseGradient ,
      name: 'HTML5',
    },
    {
      icon: JavascriptBaseGradient ,
      name: 'Javascript',
    },
    {
      icon: MySqlBaseGradient ,
      name: 'MySQl',
    },
    {
      icon: NestBaseGradient ,
      name: 'Nest JS',
    },
    {
      icon: NUXTBaseGradient ,
      name: 'NUXT JS',
    },
    {
      icon: PostGresBaseGradient ,
      name: 'PostGres',
    },
    {
      icon: ReactBaseGradient ,
      name: 'React JS',
    },
    {
      icon: SASSBaseGradient ,
      name: 'SASS',
    },
    {
      icon: VueBaseGradient ,
      name: 'VUE JS',
    },
  ]
  const miscellaneousTechStack = [
    {
      icon: AmazonWebServicesBaseGradient ,
      name: 'Amazon Web Services',
    },
    {
      icon: DockerBaseGradient ,
      name: 'Docker',
    },
    {
      icon: FigmaBaseGradient ,
      name: 'Figma',
    },
    {
      icon: FirebaseBaseGradient ,
      name: 'Firebase',
    },
    {
      icon: GoogleCloudPlatformBaseGradient ,
      name: 'Google Cloud Platform',
    },
    {
      icon: GolangBaseGradient ,
      name: 'GO Lang',
    },
    {
      icon: KubernetesBaseGradient ,
      name: 'Kubernetes',
    },
    {
      icon: KafkaBaseGradient ,
      name: 'Apache Kafka',
    },
    {
      icon: PythonBaseGradient ,
      name: 'Python',
    },
    {
      icon: VercelBaseGradient ,
      name: 'Vercel',
    },
    {
      icon: GoogleCloudPlatformBaseGradient ,
      name: 'Google Cloud Platform',
    },
    {
      icon: GolangBaseGradient ,
      name: 'GO Lang',
    },
  ]
  const [whatToPause, setWhatToPause] = useState(-1)
  return (
    <>
      <TechStackWrapper>
        <div>
          <MarqueeGradientBox type="left" />
          <MarqueeGradientBox type="right" />

          <MarqueeWrapper
            onMouseOver={() => setWhatToPause(0)}
            onMouseLeave={() => setWhatToPause(-1)}
          >
            <MarqueeContainer pause={whatToPause === 0}>
              {mobileTechStack.map((i, j) => (
                <TechStackButton icon={i.icon} name={i.name} key={j} tkey={j} />
              ))}
            </MarqueeContainer>
            <MarqueeContainer pause={whatToPause === 0}>
              {mobileTechStack.map((i, j) => (
                <TechStackButton
                  icon={i.icon}
                  name={i.name}
                  key={j}
                  tkey={j + 100}
                />
              ))}
            </MarqueeContainer>
          </MarqueeWrapper>
          <SizedBox height={20} width={0} />
          <MarqueeWrapper
            onMouseOver={() => setWhatToPause(1)}
            onMouseLeave={() => setWhatToPause(-1)}
          >
            <MarqueeContainer reverse={true} pause={whatToPause === 1}>
              {webTechStack.map((i, j) => (
                <TechStackButton icon={i.icon} name={i.name} key={j} tkey={j} />
              ))}
            </MarqueeContainer>
            <MarqueeContainer reverse={true} pause={whatToPause === 1}>
              {webTechStack.map((i, j) => (
                <TechStackButton
                  icon={i.icon}
                  name={i.name}
                  key={j}
                  tkey={j + 100}
                />
              ))}
            </MarqueeContainer>
          </MarqueeWrapper>
          <SizedBox height={20} width={0} />
          <MarqueeWrapper
            onMouseOver={() => setWhatToPause(2)}
            onMouseLeave={() => setWhatToPause(-1)}
          >
            <MarqueeContainer pause={whatToPause === 2}>
              {miscellaneousTechStack.map((i, j) => (
                <TechStackButton icon={i.icon} name={i.name} key={j} tkey={j} />
              ))}
            </MarqueeContainer>
            <MarqueeContainer pause={whatToPause === 2}>
              {miscellaneousTechStack.map((i, j) => (
                <TechStackButton
                  icon={i.icon}
                  name={i.name}
                  key={j}
                  tkey={j + 100}
                />
              ))}
            </MarqueeContainer>
          </MarqueeWrapper>
        </div>
      </TechStackWrapper>
    </>
  )
}

export default Marquee
